import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { Type } from 'react-bootstrap-table2-editor'
import { Spinner } from 'react-bootstrap'
import { dateSort, labelDureeJMA } from "../../utils/utils";
import DiscussionModal from '../Discussion/DiscussionModal';
import DocumentList from '../Document/DocumentList';
import ReceptionWatch from './ReceptionWatch';
import { Col, Row } from "react-bootstrap"


const apiRoot = process.env.REACT_APP_API_ROOT

const ReceptionListTable = ({ client, lotId }) => {
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([]);
	const [watchModalShow, setWatchModalShow] = useState(false);
	const [selectedID, setSelectedID] = useState(0);
	const [duration, setDuration] = useState(30);
	const [documentList, setDocumentList] = useState(false);
	const [idElementRattache, setIdElementRattache] = useState(0);
	const [titreElement, setTitreElement] = useState("");
	const [discussionVisible, setDiscussionVisible] = useState(false);

	const handleDocumentList = (idReception, bordereau, reception_Le) => {
		if (typeof idReception === 'number') {
			setIdElementRattache(idReception);
			setTitreElement('Réception ' + bordereau + ' reçu le ' + reception_Le);
		}
		setDocumentList(true);
	};
	const ouvrirDiscussion = (idReception, bordereau, reception_Le) => {
		if (typeof idReception === 'number') {
			setIdElementRattache(idReception);
			setTitreElement('Réception ' + bordereau + ' reçu le ' + reception_Le)
		}
		setDiscussionVisible(true);
	};

	useEffect(() => {
		localStorage.setItem("page", "reception")
		logecomApi.get(apiRoot + 'clients').then((res) => {
			setClients(res.data)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, []);

	useEffect(() => {
		if (!client || parseInt(client) === 0) {
			return;
		}
		setLoading(true)
		if (lotId > 0) {
			logecomApi.get(apiRoot + 'lots/' + lotId + '/receipts').then((res) => {
				setData(res.data)
				setLoading(false)
			}).catch((error) => {
				setLoading(false)
				console.log(JSON.stringify(error))
			})

		} else {
			logecomApi.get(apiRoot + 'clients/' + client + '/receipts?duree=' + duration).then((res) => {
				setData(res.data)
				setLoading(false)
			}).catch((error) => {
				setLoading(false)
				console.log(JSON.stringify(error))
			})
		}
	}, [client, duration, lotId]
	);

	const openWatchModal = (row) => {
		setSelectedID(row.Id_Reception)
		setWatchModalShow(true)
	}

	const isIE10Mode = document['documentMode'] === 10

	const controlsFormatter = (_, row) => {
		let coulBtnDiscussion = '#fff';
		let titleDiscussion = 'Discussion fermée';
		if (row.nbMessagesNonLu > 0) {
			coulBtnDiscussion = 'red';
			titleDiscussion = 'Discussion avec des messages non lus';
		} else if (row.nbDiscussionOuverte > 0) {
			coulBtnDiscussion = 'green';
			titleDiscussion = 'Discussion ouverte';
		}
		return (
			<div className="btn-group">
				<button type="button" className={row.PbQte ? "btncommande btn-sm btn-danger" : "btncommande btn-sm btn-primary"} data-toggle="modal" data-target="#modals-default" style={{ marginRight: '5px' }} onClick={() => openWatchModal(row)}>
					{row.PbQte === 1 ?
						<i className="ion ion-md-warning d-block"></i>
						: <i className="ion ion-md-eye d-block"></i>}
				</button>
				{row.docExiste === 1 ?
					<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => handleDocumentList(row.Id_Reception, row.Bordereau, row.Reception_Le)}>
						<i className="ion ion-md-document d-block"></i>
					</button> : null}
				{row.nbMessages > 0 ?
					<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px', color: coulBtnDiscussion }} title={titleDiscussion} onClick={() => ouvrirDiscussion(row.Id_Reception, row.Bordereau, row.Reception_Le)}>
						<i className="ion ion-md-chatbubbles d-block"></i>
					</button> : null}
			</div>
		)
	}


	const headerWidth = (width) => () => {
		return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
	}

	// La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
	const columns =
		[["", "", 55],
		["ID", "Id_Reception", 0], ["Bordereau", "Bordereau", 180], ["Transporteur", "Nom_Transporteur", 180],
		["Fournisseur", "LibelleFournisseur", 180], ["Réception le", "Reception_Le", 160],
		["Statut", "Lib_Statut", 190]]
			.map(elt => {
				let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), filter: textFilter({ placeholder: elt[0] }), sort: true }
				if (result.text === "") {
					result.isDummyField = true
					result.CSVExport = false
					result.formatter = controlsFormatter
					result.sort = false
					result.filter = undefined
				}
				if (result.text === "Réception le") {
					result.sortFunc = dateSort
				}
				if (result.text === "ID") {
					result.hidden = true
				}
				if (result.text === "Statut") {
					result.filter = selectFilter({
						options: {
							'Livraison conforme': 'Livraison conforme',
							'Livraison non conforme': 'Livraison non conforme',
						},
						className: 'custom-select',
						defaultValue: 'Tous les statuts',
						placeholder: 'Tous les statuts'
					})
					result.editor = {
						type: Type.SELECT,
						options: [
							{ value: 'Livraison conforme', label: 'Livraison conforme' },
							{ value: 'Livraison non conforme', label: 'Livraison non conforme' },
						]
					}
				}
				return result
			})

	// Change le CSS d'une ligne en fonction de l'index
	const rowStyle = (row, rowIndex) => {
		if (data[rowIndex].Qte_EnStock < data[rowIndex].Seuil_Alerte) {
			if (rowIndex % 2 === 0) {
				return { backgroundColor: '#f44336' };
			}
			else {
				return { backgroundColor: '#d42316' };
			}
		}
		if (rowIndex % 2 === 1) {
			return { backgroundColor: '#dddddd' };
		}
	};

	if (clients.length > 0) {
		return (
			<>
				{loading ?
					<div style={{ textAlign: 'center' }}>
						<Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</div>
					:
					<div>

						<div>
							{isIE10Mode && <div className="alert alert-danger">
								<strong>react-bootstrap-table2</strong> doesn't support Internet Explorer 10
							</div>}

							{!isIE10Mode &&

								<ToolkitProvider
									keyField="Id_Reception"
									data={data}
									columns={columns}
									bootstrap4
									search
									columnToggle

								>
									{props => (
										<div>
											<Row className="align-items-center" style={{ marginBottom: '10px' }}>
												{lotId < 1 &&
													<Col>
														<span className="titre-section font-weight-bold py-3 mb-4">Réceptions</span>
													</Col>
												}
												{lotId < 1 &&
													<Col md="auto">
														<div className="header-controles">

															<div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
																{[7, 14, 30, 60, 90, 365].map((n) => { return duration === n | duration === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)} </label> }
																)}
															</div>
														</div>
													</Col>
												}
											</Row>
											<BootstrapTable
												{...props.baseProps}
												rowStyle={rowStyle}
												wrapperClasses="table-responsive"
												pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
												filter={filterFactory()}
											/>
										</div>
									)}
								</ToolkitProvider>
							}
						</div></div>
				}
				<ReceptionWatch
					isModalVisible={watchModalShow}
					fermer={() => { setWatchModalShow(false) }}
					receiptId={selectedID}
				/>
				<DocumentList
					isModalVisible={documentList}
					elementRattache='Receipt'
					idElementRattache={idElementRattache}
					client={client}
					titre={titreElement}
					handleDocumentList={() => setDocumentList(!documentList)}
				/>
				<DiscussionModal
					isModalVisible={discussionVisible}
					elementRattache='Receipt'
					idElementRattache={idElementRattache}
					client={client}
					titre={titreElement}
					fermerDiscussion={() => setDiscussionVisible(false)}
				/>
			</>
		);
	} else {
		return null
	}

}

export default ReceptionListTable;