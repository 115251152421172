import React, { } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"

const Alerte = ({ isModalVisible, handleAlerte, message }) => {
    return (
        <Modal show={isModalVisible} backdrop="static" onHide={handleAlerte} centered size="xm">
            <Modal.Body>
                <Row>&nbsp;</Row>
                <Row>
                    <Col sm={10}>
                        {message}
                    </Col>
                    <Col sm={2}>

                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col sm={10}>

                    </Col>
                    <Col sm={2}>
                        <Button onClick={handleAlerte}>OK</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );

}

export default Alerte;