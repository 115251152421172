import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import OrderWatch from './OrderWatch';
import { dateSort } from "../../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const OrdersForAddress = ({ isModalVisible, fermer, adresse, client }) => {
    const [data, setData] = useState([]);
    const [orderId, setOrderId] = useState(0);
    const [watchModalShow, setWatchModalShow] = useState(false);
    useEffect(() => {
        if (adresse && adresse.IDCARNET_ADRESSE) {
            logecomApi.get(apiRoot + 'addresses/' + adresse.IDCARNET_ADRESSE + '/OrdersForAddress').then((res) => {
                setData(res.data)
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        }
    }, [adresse, client]);
    const openWatchModal = (row) => {
        setOrderId(row.IDCOMMANDE)
        setWatchModalShow(true)
    }
    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const titre = () => {
        var _titre = '';
        if (adresse && adresse.NomPrenom) {
            _titre += adresse.NomPrenom + ' - ';
        }
        if (adresse && adresse.Societe) {
            _titre += adresse.Societe;
        }
        return _titre;
    }
    const controlsFormatter = (_, row) => {
        return (
            <div class="btn-group">
                <button type="button" title="Détails" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openWatchModal(row)}>
                    <i className="ion ion-md-eye d-block"></i>
                </button>
            </div>
        )
    }
    const columns =
        [
            ["", "watch", 1, false],
            ["IDCOMMANDE", "IDCOMMANDE", 80],
            ["Numéro de commande", "NumCommandeClient", 100],
            ["Créée le", "DateCommande", 90],
            ["Statut", "Statut", 60, false],
            ["Nom Livraison", "CDE_NomLivraison", 80],
            ["Date expédition", "DateExpedition", 90],
            ["Code Postal", "CDE_CodePostalLivraison", 70],
            ["Ville", "CDE_VilleLivraison", 80],
            ["Pays", "CDE_CodePaysLivraison", 60]]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                if (['IDCOMMANDE'].includes(result.dataField)) {
                    result.hidden = true;
                } else if (['DateCommande'].includes(result.dataField)) {
                    result.sortFunc = dateSort;
                } else if (result.dataField === "watch") {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = controlsFormatter
                    result.sort = false
                    result.filter = undefined
                } else if (result.dataField === "NumCommandeClient") {
                    result.formatter = (cell, row) => {
                        return (row.NumCommandeClient + "-" + row.IndiceCommande.toString())
                    };
                } else if (result.dataField === "Statut") {
                    var statuts = [
                        { value: 1, label: 'En Attente' },
                        { value: 2, label: 'En Cours' },
                        { value: 3, label: 'Problème' },
                        { value: 4, label: 'Soldée Picking' },
                        { value: 5, label: 'Soldée' },
                        { value: 6, label: 'Annulée' },
                        { value: 7, label: 'Retour' },
                    ].filter(statut => data.find(commande => commande.Statut === statut.value));
                    result.formatter = cell => statuts.find(statut => statut.value === cell).label;
                    result.filter = selectFilter({
                        optionsXX: statuts.map(statut => {
                            var obj = {};
                            obj[statut.value] = statut.label;
                            return obj;
                        }),
                        options: function () {
                            var obj = {};
                            statuts.forEach(statut => {
                                obj[statut.value] = statut.label;
                            })
                            return obj;
                        },
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })
                }
                return result
            })


    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Commandes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Commandes concernant :&nbsp;
                                </strong>
                                {titre()}
                            </Col>
                        </Row>
                    </div>

                    <ToolkitProvider
                        keyField="IDCOMMANDE"
                        data={data}
                        columns={columns}
                        bootstrap4
                        search
                        columnToggle
                    >
                        {props => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    rowStyle={rowStyle}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                                    filter={filterFactory()}

                                />
                            </div>
                        )}
                    </ToolkitProvider>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <OrderWatch
                isModalVisible={watchModalShow}
                fermer={() => { setWatchModalShow(false) }}
                orderId={orderId}
            />
        </div>
    );

}

export default OrdersForAddress;