import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap"
import DocumentList from '../Document/DocumentList';
import DiscussionFiche from './DiscussionFiche';

const DiscussionFicheModal = ({ isModalVisible, handleDiscussion, elementRattache, idElementRattache, idDiscussion, client, titre }) => {
    const [documentList, setDocumentList] = useState(false);
    return (
        <div>
            <DocumentList
                isModalVisible={documentList}
                elementRattache={elementRattache}
                idElementRattache={idElementRattache}
                client={client}
                titre={titre}
                handleDocumentList={() => setDocumentList(false)}
            />
            <Modal show={isModalVisible} backdrop="static" onHide={handleDiscussion} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Discussion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DiscussionFiche
                        idDiscussion={idDiscussion}
                        titre={titre}
                    >

                    </DiscussionFiche>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={() => { setDocumentList(true) }}>Documents</Button>
                    <Button variant="default" className="blue" onClick={() => { handleDiscussion() }}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default DiscussionFicheModal;