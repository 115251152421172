import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from "react-bootstrap"
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import logecomApi from "../../utils/axiosInstance";
import { downloadCSV, patchFilterFactory } from "../../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const TarifConsommablesTable = ({ client }) => {
    const [tarifs, setTarifs] = useState([]);
    const [exportData, setExportData] = useState([]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    const refresh = () => {
        if (!client || parseInt(client) === 0) {
            return;
        }
        logecomApi.get(apiRoot + 'clients/' + client + '/tarifconsommables').then((res) => {
            setTarifs(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }

    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })

    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    const controlsFormatter = (cell, row, rowIndex, nomColonne) => {
        let val = cell;
        if (['PrixVente', "PrixPicking", "PrixTotal"].includes(nomColonne)) {
            val = val.toFixed(2);
            val = val.replace(".", ",") + ' €';
        }
        return (
            val
        )
    }
    const columns =
        [
            ["IDARTICLE", "IDARTICLE", 180, false, false],
            ["SKU", "CodeSKU", 140, true, true],
            ["Libellé", "LibelleArticle1", 160, true, true],
            ["Famille", "LibelleFamille", 130, true, true],
            ["Tarif Spécifique", "TarifSpecifique", 100, true, true],
            ["Prix Vente", "PrixVente", 60, true, true],
            ["Prix Picking", "PrixPicking", 60, true, true],
            ["Prix Total", "PrixTotal", 60, true, true],
        ]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], visible: elt[3], exporter: elt[4], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;
                if (result.visible === false) {
                    result.hidden = true;
                } else if (['PrixVente', "PrixPicking", "PrixTotal"].includes(result.dataField)) {
                    result.style = { textAlign: 'right' };
                    result.formatter = controlsFormatter;
                } else if (result.dataField === 'TarifSpecifique') {
                    var typeSpecifique = [
                        { value: true, label: 'Oui' },
                        { value: false, label: '' },
                    ];
                    result.formatter = cell => typeSpecifique.find(type => type.value === cell).label;
                    result.filter = selectFilter({
                        options: {
                            true: 'Oui',
                            false: 'Non',
                        },
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })
                    let hidden = true;
                    tarifs.forEach(tarif => { if (tarif.TarifSpecifique === true) { hidden = false } });
                    result.hidden = hidden;
                } else if (result.dataField === 'LibelleFamille') {
                    var options = {};
                    tarifs.forEach(tarif => { if (tarif.LibelleFamille) options[tarif.LibelleFamille] = tarif.LibelleFamille });
                    result.filter = selectFilter({
                        options: options
                        ,
                        className: 'custom-select',
                        placeholder: 'Toutes'
                    })
                }
                return result
            })

    return (
        <>
            <ToolkitProvider
                keyField="IDARTICLE"
                data={tarifs}
                columns={columns}
                bootstrap4
                search
                columnToggle
            >
                {props => (
                    <div>
                        {<Row className="align-items-center" style={{ marginBottom: '20px' }}>
                            <Col><span className="titre-section font-weight-bold">Tarification consommables</span>
                            </Col>
                            <Col md="auto">
                                <div className="header-controles">
                                    <Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'TarifConsommables') }}>Export CSV</Button>
                                </div>
                            </Col>
                        </Row>}
                        <BootstrapTable
                            {...props.baseProps}
                            rowStyle={rowStyle}
                            wrapperClasses="table-responsive"
                            pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                            filter={myTableFilterFactory()}
                        />
                    </div>
                )}
            </ToolkitProvider>
        </>
    )
}
export default TarifConsommablesTable;