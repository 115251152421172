import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import Select from 'react-select'
import Alerte from '../Alerte';
import { Spinner } from 'react-bootstrap'
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT;
const tailleMax = parseInt(process.env.REACT_APP_TAILLEUPLOADMAX);
const extension_interdite = process.env.REACT_APP_EXTENSION_INTERDITE.split(' ');

const DocumentAdd = ({ isModalVisible, handleDocumentAdd, elementRattache, idElementRattache, titre, natureOptionsAdd }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [libelle, setLibelle] = useState("");
    const [messageErreurAjout, setMessageErreurAjout] = useState(null);
    const [nature, setNature] = useState(0);
    const [formValid, setFormValid] = useState(false);
    const [textError, setTextError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const close = () => {
        setTextError(null);
        setNature(0);
        setLibelle("");
        setSelectedFile(null);
        handleDocumentAdd();
    }

    useEffect(() => {
        let valid = parseInt(nature) !== 0 && libelle !== '' && selectedFile && textError === null;
        setFormValid(valid);
    }, [libelle, nature, selectedFile, textError]);

    useEffect(() => {
        if (natureOptionsAdd.length === 1) {
            setNature(parseInt(natureOptionsAdd[0].Code_Statut));
        }
    }, [natureOptionsAdd]);

    const onUploadProgress = event => {
        setProgress(Math.round((100 * event.loaded) / event.total));
    };

    const defaultValue = () => {
        if (natureOptionsAdd.length === 1) {
            return natureOptionsAdd[0];
        } else {
            return null;
        }
    };

    async function ajouterDocument() {
        if (!nature || !libelle || parseInt(nature) === 0 || libelle === '') {
            setMessageErreurAjout("Merci de renseigner un titre et un type de document");
            return;
        }
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        const data = {
            Nature_Doc: parseInt(nature),
            Titre_Doc: libelle,
            Nom_Fichier: selectedFile.name,
            Donnees_Fichier: await toBase64(selectedFile)
        }

        var elementName = { "Order": "orders", "Item": "items", "Pre-Receipt": "pre_receipts", "Receipt": "receipts", "Invoice": "invoices", "Sav": "sav" };
        setProgress(0);
        setUploading(true);
        logecomApi.post(apiRoot + elementName[elementRattache] + '/' + idElementRattache + '/documents', data, { onUploadProgress }).then(() => {
            setUploading(false);
            setProgress(0);
            close();
        }).catch((error) => {
            setUploading(false);
            setProgress(0);
            let msgErreur = error.response.data.fault !== undefined ? error.response.data.fault.detail : error.message;
            setMessageErreurAjout("Une erreur est survenue lors de l'ajout du document. " + msgErreur);
        })
    }

    const onFileChange = event => {
        var file = event.target.files[0];
        if (file) {
            if (file.size > tailleMax * 1048576) {
                setTextError('La taille du fichier dépasse les ' + tailleMax + 'Mo autorisés.');
            } else if (extension_interdite.includes(file.name.split('.').pop().toLowerCase())) {
                setTextError('Ce fichier est refusé car il possède une extension potentiellement dangereuse.');
            } else {
                setTextError(null);
            }
        } else {
            setTextError(null);
        }
        setSelectedFile(file);
    };

    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={close} idElementRattache={idElementRattache} centered size="xm">
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploading ?
                        <div style={{ textAlign: 'center' }}><Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
                            <span className="sr-only">Loading...</span>
                        </Spinner></div> : null}
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Élément :&nbsp;
                                </strong>
                                {titre}
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row>
                            <Col sm={3}>
                                <Form.Label >
                                    <strong>Fichier :&nbsp; </strong>
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Form.Control type="file" onChange={onFileChange} id="fichier" label="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Form.Label >
                                    <strong>Titre :&nbsp; </strong>
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Form.Control type="text" maxLength="80" id="libelle" value={libelle} onChange={e => setLibelle(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={3}>
                                <strong>Nature :&nbsp; </strong>
                            </Col>
                            <Col sm={9}>
                                <Select
                                    getOptionLabel={option => option.Lib_Statut}
                                    getOptionValue={option => option.Code_Statut}
                                    options={natureOptionsAdd}
                                    defaultValue={defaultValue()}
                                    onChange={e => setNature(parseInt(e.Code_Statut))}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>
                        {uploading && (
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info progress-bar-striped"
                                    role="progressbar"
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progress + "%" }}
                                >
                                    {progress}%
                                </div>
                            </div>
                        )}
                        {textError && <Row className="mb-2">
                            <Col sm={12}>
                                <strong style={{ color: 'red' }}>{textError}</strong>
                            </Col>

                        </Row>}
                        {!textError && <Row className="mb-2">
                            <Col sm={12}>
                                La taille des fichiers est limitée à {tailleMax}Mo
                            </Col>

                        </Row>}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={!formValid || uploading} variant="default" className="blue" onClick={ajouterDocument}>Ajouter</Button>
                    <Button disabled={uploading} variant="default" className="blue" onClick={close}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <Alerte
                isModalVisible={messageErreurAjout}
                handleAlerte={() => setMessageErreurAjout(null)}
                message={messageErreurAjout}
            >
            </Alerte>

        </div >
    );
}

export default DocumentAdd;
