import { Form, Col, Row } from "react-bootstrap"

const LitigeTransporteur = ({ sav }) => {
    return (
        <>
            <div className="section-form">
                <Row className="mb-2">
                    <Col sm={12}>
                        <Form.Label>
                            <strong>Litige transporteur</strong>
                        </Form.Label>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Statut :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        {sav.LitigeLibelléStatut}
                    </Col>
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Objet :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        {sav.LitigeObjetDuLitige}
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Date ouverture :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        {sav.LitigeDateOuverture}
                    </Col>
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Transporteur :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        {sav.LitigeNomTransporteur}
                    </Col>
                </Row>


                <Row className="mb-2">
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Date remb. :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        {sav.LitigeDateRemboursement}
                    </Col>
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Mt remb. :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        {sav.LitigeMtRemboursementClient}
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col sm={2}>
                        <Form.Label>
                            <strong>Commentaire :</strong>
                        </Form.Label>
                    </Col>
                    <Col sm={10}>
                        {sav.LitigeCommentaire}
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default LitigeTransporteur;
