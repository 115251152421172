
import React, { } from 'react';
import { Form, Col, Row } from "react-bootstrap"
import ExpeditionsCommande from './ExpeditionsCommande';

const OrderWatchHeader = ({ order, orderId, modeModif }) => {
    return (
        <>
            <div className="section-form">
                <Row>
                    <Col sm={6}>
                        <strong>
                            N° de commande :&nbsp;
                        </strong>
                        {order.NumCommandeClient}
                        <strong>&nbsp;indice&nbsp;:&nbsp;</strong>{order.IndiceCommande}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Type :&nbsp;
                        </strong>
                        {order.TypeCommande}
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <strong>
                            Date commande :&nbsp;
                        </strong>
                        {order.DateCommande}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Statut :&nbsp;
                        </strong>&nbsp;
                        {order.StatutCommande}
                    </Col>
                </Row>
                {!modeModif && <Row>
                    <Col sm={6}>
                        <strong>
                            Traiter en urgence :&nbsp;
                        </strong>
                        {order.TraiterEnUrgence ? 'Oui' : 'Non'}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            TAG :&nbsp;
                        </strong>
                        {order.TAG}
                    </Col>
                </Row>}
                <Row>
                    <Col sm={6}>
                        <strong>
                            Société :&nbsp;
                        </strong>
                        {order.Societe}
                    </Col>

                    <Col sm={6}>
                        <strong>
                            Nom :&nbsp;
                        </strong>
                        {order.NomLivraison}
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <strong>
                            Adresse :&nbsp;
                        </strong>
                        {order.AdresseLivraison}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Complément adresse :&nbsp;
                        </strong>
                        {order.ComplAdresseLivraison}
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <strong>
                            Code postal :&nbsp;
                        </strong>
                        {order.CodePostalLivraison}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Ville :&nbsp;
                        </strong>
                        {order.VilleLivraison}
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <strong>
                            Pays :&nbsp;
                        </strong>
                        {order.PaysLivraison}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Code Pays :&nbsp;
                        </strong>
                        {order.CodePaysLivraison}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Téléphone :&nbsp;
                        </strong>
                        {order.Telephone}
                    </Col>
                    <Col sm={6}>
                        <strong>
                            Adresse mail :&nbsp;
                        </strong>
                        {order.Email}
                    </Col>
                </Row>

            </div>
            <div className="section-form">
                <Row>
                    <Col>
                        <Row>
                            <Col sm={6}>
                                <strong>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Informations saisies par le client :&nbsp;
                                </strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <strong>
                                    Transporteur :&nbsp;
                                </strong>
                                {order.CDE_Transporteur}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <strong>
                                    Option transport :&nbsp;
                                </strong>
                                {order.CDE_OptionTransport}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <strong>
                                    Date limite d'envoi :&nbsp;
                                </strong>
                                {order.DateMaxiEnvoi}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <ExpeditionsCommande IDCOMMANDE={orderId}></ExpeditionsCommande>
                    </Col>
                </Row>
            </div>
            <div className="section-form">
                <Row>
                    <Col sm={12} className="mb-2">
                        <strong>
                            Commentaire: &nbsp;
                        </strong>
                        <Form.Control readOnly as="textarea" rows="2" maxLength="4000" defaultValue={order.Commentaire} />
                    </Col>
                </Row>

            </div>
        </>
    )
}
export default OrderWatchHeader; 