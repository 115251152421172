import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Alerte from './Alerte';
import { Modal, Col, Row, Button } from "react-bootstrap"
const apiRoot = process.env.REACT_APP_API_ROOT

const Login = () => {

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [loginEmail, setLoginEmail] = useState("");
    const [remember, setRemember] = useState(false)
    const [mdpOublie, setMdpOublie] = useState(false)
    const [messageErreur, setMessageErreur] = useState(null);
    const [quitter, setQuitter] = useState(false);
    const isEmptyOrNotDefined = res => res === undefined || res === "";

    useEffect(() => {
        const default_login = localStorage.getItem("default_login")
        default_login && setUser(default_login)
    }, [])

    const processResponse = (res) => {
        if (res.status === 200) {
            res.json().then(res => {
                const resProperties = ["token", "refresh_token", "nom_utilisateur", "utilisateur_gd"]
                let exit = false
                let errorStr = ""
                resProperties.forEach(prop => {
                    if (isEmptyOrNotDefined(res[prop])) {
                        errorStr += "La propriété " + prop + " est absente de la réponse du serveur."
                        exit = true
                    }
                })
                if (exit) {
                    alert("Erreur à l'authentification. " + errorStr)
                    return;
                }
                if (parseInt(res.utilisateur_gd) === 0) {
                    ["client_name", "id_client"].forEach(prop => {
                        if (isEmptyOrNotDefined(res[prop])) {
                            errorStr += "La propriété " + prop + " est absente de la réponse du serveur."
                            exit = true
                        }
                    })
                    localStorage.setItem("assurance_client", res.assurance_client);
                } else {
                    localStorage.setItem("assurance_client", 1); // pLOGeCOM_Constants.cAssuranceArticleSaisieClient
                }

                localStorage.setItem("token", res.token);
                localStorage.setItem("refresh_token", res.refresh_token);
                localStorage.setItem("user", res.nom_utilisateur);
                localStorage.setItem("user_gd", res.utilisateur_gd);
                localStorage.setItem("client_name", res.nom_client);
                localStorage.setItem("id_client", res.id_client);
                localStorage.setItem("changement_mdp", res.changement_mdp);
                localStorage.setItem('mdpEnvoye', 0);
                localStorage.setItem('timer', 0);
                if (remember) {
                    localStorage.setItem("default_login", user);
                }

                //TODO lien externe
                /*if(localStorage.getItem("Url_Redirection") !== ""){
                    const url = localStorage.getItem("Url_Redirection");
                    localStorage.removeItem("Url_Redirection");
                    window.location = url;
                }else{*/
                window.location = "/";
                //}
            })
        }
        else {
            res.json().then(res => {
                if (isEmptyOrNotDefined(res.fault)) {
                    alert("Erreur d'authentification. La propriété fault est absente de la réponse du serveur.")
                    return;
                }
                if (isEmptyOrNotDefined(res.fault.detail)) {
                    alert("Erreur d'authentification. La propriété detail est absente de la réponse du serveur.")
                    return;
                }
                alert(res.fault.detail)
            })
        }
    }

    const sendCredentials = () => {
        const encoded = btoa(user + ":" + pass);
        const options = {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Basic " + encoded,
            }),
            mode: 'cors',
            cache: 'default'
        };
        const myRequest = new Request(apiRoot + 'login', options);
        fetch(myRequest, options)
            .then(response => { processResponse(response) })

    }

    const resetMdp = () => {
        const encoded = btoa(loginEmail);
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };
        const myRequest = new Request(apiRoot + 'resetpassword/1/' + encoded, options);
        fetch(myRequest, options)
            .then((response) => {
                localStorage.setItem('mdpEnvoye', 0);
                if (response.ok) {
                    setMessageErreur("Vérifiez votre boîte de réception pour les instructions de réinitialisation de mot de passe.");
                    setQuitter(true);
                } else {
                    setMessageErreur("Une erreur est survenue. Vérifiez votre identifiant ou email saisi.");
                }
            })

    }

    return (
        <React.Fragment>
            <Helmet><title>LOGeCOM - Connexion</title></Helmet>

            <div className="containerLogin" >
                <div className="authentication-wrapper authentication-1 px-4">
                    <div className="authentication-inner py-5">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="w-100 position-relative">
                                    <img src="assets/img/logo-connexion.png" alt="Logecom" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <form className="my-5" onSubmit={(event) => event.preventDefault()}>
                            {parseInt(localStorage.getItem('mdpEnvoye')) === 1 && <div className="form-group">
                                <label className="form-label">Un mot de passe provisoire vient d'être généré. Vérifiez votre boîte de réception pour les instructions.</label>
                            </div>}
                            <div className="form-group">
                                <label className="form-label">Identifiant</label>
                                <input type="text" value={user} className="form-control" onChange={(event) => { setUser(event.target.value) }} />
                            </div>
                            <div className="form-group">
                                <label className="form-label d-flex justify-content-between align-items-end">
                                    <div>Mot de passe</div>
                                </label>
                                <input type="password" className="form-control" onChange={(event) => { setPass(event.target.value) }} />
                            </div>
                            <div className="d-flex justify-content-between align-items-center m-0">
                                <label className="custom-control custom-checkbox m-0">
                                    <input type="checkbox" className="custom-control-input" onChange={(event) => { setRemember(event.target.checked) }} />
                                    <span className="custom-control-label">Se souvenir de moi</span>
                                </label>
                                <button type="submit" className="btn btn-primary" onClick={sendCredentials}>Connexion</button>

                            </div>
                            <div className="form-group"></div>
                            <div className="form-group">
                                <span style={{ cursor: 'pointer', color: 'blue' }} className='sub' onClick={() => setMdpOublie(true)}>Mot de passe oublié ?</span>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="footerLogin">
                    <img style={{ width: '55px' }} src="assets/img/logo_Frelance.png" alt="Logecom" className="img-fluid" />
                </div>
            </div >

            <div>
                <Modal show={mdpOublie} backdrop="static" onHide={() => { setMdpOublie(false); }} centered size="xs">
                    <Modal.Header closeButton>
                        <Modal.Title>Mot de passe oublié ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="section-form">
                            <Row className="mb-2">
                                <Col sm={12}>
                                    Indiquez votre identifiant ou votre adresse email ; un email vous sera envoyé afin de procéder à la réinitialisation de votre mot de passe.
                                </Col>
                            </Row>
                            <Row className="mb-2"></Row>
                            <Row className="mb-2">
                                <Col sm={3}></Col>
                                <Col sm={6}>
                                    <input type="text"
                                        value={loginEmail}
                                        style={{ marginBottom: '5px' }}
                                        placeholder="Nom utilisateur ou email"
                                        className="form-control"
                                        onChange={(event) => { setLoginEmail(event.target.value) }}
                                    />
                                </Col>
                            </Row>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={loginEmail === ''} variant="default" className="blue" onClick={resetMdp}>Valider</Button>
                        <Button variant="default" className="blue" onClick={() => { setMdpOublie(false); }}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            </div >
            <Alerte
                isModalVisible={messageErreur}
                handleAlerte={() => { setMessageErreur(null); if (quitter) { window.location = "/"; } }}
                message={messageErreur}
            />

        </React.Fragment >
    );

}

export default Login;