import React, { useEffect, useState, useRef } from 'react';
import { Modal, Col, Row, Button, Spinner } from "react-bootstrap"
import Alerte from '../Alerte';
import logecomApi from "../../utils/axiosInstance";
import { labelDureeJMA, dateIso, generateGuid } from '../../utils/utils';
const apiRoot = process.env.REACT_APP_API_ROOT;

const ExportDetailleOrder = ({ idBoutique, visible, fermer }) => {

    const [message, setMessage] = useState(null);
    const [duration, setDuration] = useState(7);
    const [type, setType] = useState("Toutes");
    const [loading, setLoading] = useState(false)
    const anneeCourante = (new Date().getFullYear()).toString();
    const anneeMoinsUn = (new Date().getFullYear() - 1).toString();
    const [guid, setGuid] = useState('');
    const close = () => {
        setLoading(false);
        fermer()
    }
    const guidRef = useRef();
    guidRef.current = guid;

    useEffect(() => {
        setLoading(false);
        setGuid('');
    }, [visible]);


    const getExportDetailleCommandes = (idBoutique, duration, type, guid) => {
        return logecomApi({
            url: apiRoot + 'orders/' + idBoutique + '/export?duree=' + duration + "&typecommande=" + type,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            return {
                data: response.data,
                guid: guid
            }
        }).catch((error) => {
            console.log(JSON.stringify(error));
            return null;
        });
    }

    const exporter = async () => {
        setLoading(true);
        const guid = generateGuid();
        setGuid(guid);
        return await getExportDetailleCommandes(idBoutique, duration, type, guid).then((response) => {
            if (response.guid !== guidRef.current) {
                // On a annulé la demande
                return;
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let _date = dateIso(new Date());
            link.setAttribute('download', 'Liste-détaillée-commmandes-' + _date + ".xlsx");
            document.body.appendChild(link);
            link.click();
            close();
        }).catch((error) => {
            setLoading(false)
            throw new Error(error);
        })
    }

    return (
        <div>
            <Modal show={visible} backdrop="static" onHide={fermer} centered size="lg">
                <Modal.Header>
                    <Modal.Title>Export détaillé de la liste des commandes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading &&
                        <div style={{ textAlign: 'center' }}><Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
                            <span className="sr-only">Traitement en cours...</span>
                        </Spinner></div>}
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={3}>
                                <strong>Période :</strong>
                            </Col>
                            <Col sm={9}>
                                <div style={{ marginLeft: '5px' }} className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
                                    {[7, 14, 30, 60, 90, 365, anneeCourante, anneeMoinsUn].map((n) => { return duration === n | duration === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {[anneeCourante, anneeMoinsUn].includes(n) ? n : labelDureeJMA(n)}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {[anneeCourante, anneeMoinsUn].includes(n) ? n : labelDureeJMA(n)} </label> }
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={3}>
                                <strong>Type de commande :</strong>
                            </Col>
                            <Col sm={9}>
                                <div style={{ marginLeft: '5px' }} className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setType(event.target.value) }}	>
                                    {["Toutes", "B2B", "B2C"].map((n) => { return type === n | type === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {n}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {n} </label> }
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={12}>
                                L'export va fournir un fichier Excel contenant les commandes ainsi que le détail de chaque ligne de commande (article commandé, quantité, etc.). En fonction des options choisies, le temps de traitement peut être assez long. Merci dans ce cas de patienter ou de diminuer la période concernée ou le type.
                            </Col>
                        </Row>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="default" className="blue" onClick={() => { exporter() }}>Exporter</Button>
                    <Button variant="default" className="blue" onClick={close}>{loading ? "Annuler" : 'Fermer'}</Button>
                </Modal.Footer>
            </Modal>
            <Alerte
                isModalVisible={message}
                handleAlerte={() => setMessage(null)}
                message={message}
            >
            </Alerte>
        </div >
    );
}

export default ExportDetailleOrder;