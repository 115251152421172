import React, { useEffect, useState } from 'react';
import { Col, Row, FormLabel } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT;

const ExpeditionsCommande = ({ IDCOMMANDE }) => {
    const [indice, setIndice] = useState(0);
    const [data, setData] = useState([]);
    const [expedition, setExpedition] = useState({ NomTransporteur: '', OptionTransport: '', TrackingURL: '', NumTracking: '' });

    useEffect(() => {
        if (IDCOMMANDE > 0) {
            logecomApi.get(apiRoot + '/orders/' + IDCOMMANDE + '/expeditions').then((res) => {
                setData(res.data);
            }).catch((error) => {
                throw new Error(error);
            })
        } else {
            setData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IDCOMMANDE]);

    useEffect(() => {
        if (data.length > 0) {
            setIndice(0);
            setExpedition(data[0]);
        } else {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const expeditionPrecedente = () => {
        let ind = indice;
        if (ind > 0) {
            ind--;
            setIndice(ind);
            setExpedition(data[ind]);
        }
    }
    const expeditionSuivante = () => {
        let ind = indice;
        if (ind + 1 < data.length) {
            ind++;
            setIndice(ind);
            setExpedition(data[ind]);
        }
    }
    return (
        <>
            <div>
                <Row>
                    <Col sm={8}>
                        <strong>
                            &nbsp;&nbsp;&nbsp;&nbsp;Informations d'expédition :&nbsp;
                        </strong>
                    </Col>
                    {data.length > 1 &&
                        <Col sm={4}>
                            <Row>
                                <Col sm={3} ><button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '0px' }} title='Expédition précédente' onClick={() => expeditionPrecedente()}>
                                    <i className="ion ion-md-arrow-back d-block"></i>                            </button>
                                </Col>
                                <Col sm={3}><FormLabel style={{ marginRight: '0px', width: "30px", textAlign: "center" }}>{indice + 1 + ' / ' + data.length}</FormLabel></Col>
                                <Col sm={3} ><button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '0px' }} title='Expédition suivante' onClick={() => expeditionSuivante()}>
                                    <i className="ion ion-md-arrow-forward d-block"></i>                            </button>
                                </Col>
                            </Row>
                        </Col>}
                </Row>
                {(1 != 0) &&
                    <>
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Transporteur :&nbsp;
                                </strong>
                                {expedition.NomTransporteur}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Mode d'envoi :&nbsp;
                                </strong>
                                {expedition.OptionTransport}
                            </Col>
                        </Row>
                        {expedition.LivraisonEnPointDeRetrait &&
                            <Row>
                                <Col sm={12}>
                                    <strong>
                                        Point retrait :&nbsp;<br />
                                    </strong>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{expedition.NomLivraison}<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{expedition.AdresseLivraison}<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{expedition.CodePostalLivraison}&nbsp;{expedition.VilleLivraison}
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col sm={8}>
                                <strong>
                                    N° Suivi : &nbsp;
                                </strong>
                                {expedition.TrackingURL && expedition.TrackingURL.startsWith('http') ?
                                    <a target="_blank" rel="noreferrer" href={expedition.TrackingURL}>{expedition.NumTracking}</a> :
                                    expedition.NumTracking
                                }
                            </Col>
                            {expedition.EtiquetteDeRetour &&
                                <Col sm={4} style={{ color: 'red' }}>
                                    <strong>
                                        Etiquette retour
                                    </strong>
                                </Col>

                            }

                        </Row>
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Date étiquette :&nbsp;
                                </strong>
                                {expedition.DateCreationEtiquette}
                            </Col>

                        </Row>
                    </>
                }
            </div>
        </>
    );
}

export default ExpeditionsCommande;
