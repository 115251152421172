import React from 'react';
import { Helmet } from 'react-helmet';
import AdressesListTable from './AdressesListTable';

const AdressesPage = (props) => {
    const client = props.client;
    return (
        <React.Fragment>
            <Helmet><title>LOGeCOM - Adresses</title>

            </Helmet>
            <div className="layout-wrapper layout-1 layout-without-sidenav">
                <div className="layout-inner">

                    <div className="layout-container">
                        <div className="layout-content">

                            <div className="container flex-grow-1 mycontainer-p-y unbox">
                                <div style={{ marginBottom: '40px' }}>
                                    <AdressesListTable client={client} idBoutique={props.idBoutique} natureAdresse={0} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default AdressesPage;