import { Modal, Button } from "react-bootstrap"

const YesNo = (props) => {
    const yes = () => {
        props.callbackfunction(props.object);
        props.hide(false);
    }
    const no = () => {
        if (typeof props.cancelfunction === "function") {
            props.cancelfunction(props.object);
        }
        props.hide(false)
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={no}
                backdrop="static"
                keyboard={false}
                centered
                xxxsize="xm"
            >
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={yes}>
                        Oui
                    </Button>
                    <Button variant="secondary" onClick={no}>
                        Non
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default YesNo;