import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import OrderWatch from './OrderWatch';
import { dateSort } from "../../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const OrdersToSendByItem = ({ isModalVisible, fermer, article, client }) => {
    const [data, setData] = useState([]);
    const [orderId, setOrderId] = useState(0);
    const [watchModalShow, setWatchModalShow] = useState(false);
    useEffect(() => {
        if (article && article.Id_Article) {
            logecomApi.get(apiRoot + 'items/' + client + '/' + article.Id_Article + '/OrdersToSend').then((res) => {
                setData(res.data)
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        }
    }, [article, article.Id_Article, client]);
    const openWatchModal = (row) => {
        setOrderId(row.IDCOMMANDE)
        setWatchModalShow(true)
    }
    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const controlsFormatter = (_, row) => {
        return (
            <div class="btn-group">
                <button type="button" title="Détails" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openWatchModal(row)}>
                    <i className="ion ion-md-eye d-block"></i>
                </button>
            </div>
        )
    }
    const columns =
        [
            ["", "watch", 1, false],
            ["IDCOMMANDE", "IDCOMMANDE", 80],
            ["Numéro de commande", "NumCommandeClient", 80],
            ["Créée le", "DateCommande", 80],
            ["Nom Livraison", "CDE_NomLivraison", 80],
            ["Code Postal", "CDE_CodePostalLivraison", 60],
            ["Ville", "CDE_VilleLivraison", 80],
            ["Pays", "CDE_CodePaysLivraison", 30],
            ["Date limite d'envoi", "DateMaxiEnvoi", 80],
            ["Quantité", "Quantite", 50]
        ]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                if (['IDCOMMANDE'].includes(result.dataField)) {
                    result.hidden = true;
                } else if (['DateCommande'].includes(result.dataField)) {
                    result.sortFunc = dateSort;
                } else if (result.dataField === "watch") {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = controlsFormatter
                    result.sort = false
                    result.filter = undefined
                } else if (result.dataField === "NumCommandeClient") {
                    result.formatter = (cell, row) => {
                        return (row.NumCommandeClient + "-" + row.IndiceCommande.toString())
                    };
                }
                return result
            })


    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Commandes à expédier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Commandes à expédier concernant :&nbsp;
                                </strong>
                                {article.SKU} - {article.Lib_Article1}
                            </Col>
                        </Row>
                    </div>

                    <ToolkitProvider
                        keyField="IDCOMMANDE"
                        data={data}
                        columns={columns}
                        bootstrap4
                        search
                        columnToggle
                    >
                        {props => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    rowStyle={rowStyle}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                                    filter={filterFactory()}

                                />
                            </div>
                        )}
                    </ToolkitProvider>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <OrderWatch
                isModalVisible={watchModalShow}
                fermer={() => { setWatchModalShow(false) }}
                orderId={orderId}
            />
        </div>
    );

}

export default OrdersToSendByItem;