import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import DiscussionModal from '../Discussion/DiscussionModal';
import DocumentList from '../Document/DocumentList';
import SavModal from '../Sav/SavModal';
import { getPdfCoutCommande } from "../../utils/utils";
import OrderWatchHeader from './OrderWatchHeader';
import { Icon } from '@iconify/react';
const apiRoot = process.env.REACT_APP_API_ROOT

const OrderWatch = ({ isModalVisible, fermer, orderId, tagsOrder }) => {
    const [discussionVisible, setDiscussionVisible] = useState(false);
    const [ouvrirDocuments, setOuvrirDocuments] = useState(false);
    const [orderLines, setOrderLines] = useState([]);
    const [order, setOrder] = useState({});
    const [savVisible, setSavVisible] = useState(false);
    useEffect(() => {
        if (!orderId) {
            return;
        }
        logecomApi.get(apiRoot + 'orders/' + orderId).then((res) => {
            let data = res.data[0];
            data.TAG = tagsOrder[data.IDTAG_COMMANDE].TAG;
            setOrder(data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

        logecomApi.get(apiRoot + 'orders/' + orderId + '/lines').then((res) => {
            let data = res.data
            data = data.map(elt => {
                elt.InfoPersonnalisation1 = infoPerso(elt, 1)
                elt.InfoPersonnalisation2 = infoPerso(elt, 2)
                elt.InfoPersonnalisation3 = infoPerso(elt, 3)
                return elt
            })
            setOrderLines(checkValidity(data))
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }, [orderId]);

    const infoPerso = (line, indice) => {
        let libelle = '';
        let cle = 'InfoPersonnalisation' + indice;
        ['1', '2', '3'].forEach(x => {
            let valeur = line[cle + '_' + x] + ' ';
            let valeurSuivant = parseInt(x) < 3 ? line[cle + '_' + (parseInt(x) + 1)] + ' ' : '';
            let valeurPrecedent = parseInt(x) > 1 ? line[cle + '_' + (parseInt(x) - 1)] + ' ' : '';
            if (!valeur.startsWith('http')) {
                if (valeur.trim() !== '' && (valeurSuivant.trim() !== '' || valeurPrecedent.trim() !== '')) {
                    libelle += '<strong>Info ' + x + ' :</strong>';
                }
                libelle += valeur;
            }
        });
        libelle = libelle.trim().replaceAll(' ', '\u00a0');
        return libelle;
    }

    const checkValidity = res => {
        return res === undefined || res === "" || res === {} ? [] : res
    }
    const ouvrirDiscussion = () => {
        setDiscussionVisible(true);
    };
    const rowStyle = (row, rowIndex) => {

        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    const lineControlsSKUFormatter = (_, line) =>
        <div>
            <span style={{ color: [0, 2].includes(line.ArticleStatut) ? 'orange' : line.RuptureStock ? 'red' : 'black' }}>{line.SKU}</span>
        </div>;

    const persoFormatter = (cell) => {
        const cells = [];
        let text = '';
        let nsauter = 0;
        for (let index = 0; index < cell.length; index++) {
            const debutStrong = (index === cell.indexOf('<strong>', index));
            const finStrong = (index === cell.indexOf('</strong>', index));
            const caract = cell[index];
            if (nsauter > 0) {
                nsauter--;
                continue;
            }
            if (index === cell.indexOf('<strong>', index)) {
                if (text !== '') {
                    cells.push(<div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>);
                    text = '';
                }
                nsauter = 7;
            } else if (index === cell.indexOf('</strong>', index)) {
                cells.push(<div style={{ whiteSpace: 'pre-wrap' }}><strong>{text}</strong></div>);
                text = '';
                nsauter = 8;
            } else {
                text += caract;
            }
        }
        //return <div style={{ whiteSpace: 'pre-wrap' }}>{cell}</div>
        cells.push(<div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>);
        return cells;
    }

    const headerFormatterSup = (column, colIndex, { sortElement, filterElement }) => {
        let num
        if (column.text === 'SKU') {
            num = '1';
        } else if (column.text === 'Qté toutes Cdes') {
            num = '2';
        }
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                    {column.text}<sup>{num}</sup> {sortElement}
                </span>
                {filterElement}
            </div>
        );
    };
    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    // La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
    const lineColumns =
        [
            ["ID", "IdLigneCommande", 0], ["", "TypeArticle", 14], ["SKU", "SKU", 80], ["Qté", "Quantite", 40], ["Libellé 1", "LibelleArticle1", 120], ["Libellé 2", "LibelleArticle2", 120],
            ["Nom personnalisation 1", "NomTypePersonnalisation1", 115], ["Info personnalisation 1", "InfoPersonnalisation1", 115],
            ["Nom personnalisation 2", "NomTypePersonnalisation2", 115], ["Info personnalisation 2", "InfoPersonnalisation2", 115],
            ["Nom personnalisation 3", "NomTypePersonnalisation3", 115], ["Info personnalisation 3", "InfoPersonnalisation3", 115],
            ["Lot (Qté)", "lotsUtilises", 60], ["Lot souhaité", "NumeroDeLot", 110, true],
            ["Qté toutes Cdes", "QteTotalEnCommande", 60], ["Qté stock physique", "QteConformeEnStock", 60]]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                let perso1 = false
                let perso2 = false
                let perso3 = false
                if (orderLines && orderLines.length > 0) {
                    orderLines.forEach(element => {
                        perso1 = perso1 === true || element.InfoPersonnalisation1 !== '';
                        perso2 = perso2 === true || element.InfoPersonnalisation2 !== '';
                        perso3 = perso3 === true || element.InfoPersonnalisation3 !== '';
                    });
                }
                if (result.dataField === "IdLigneCommande") {
                    result.hidden = true
                } else if (result.dataField === "SKU") {
                    result.headerFormatter = headerFormatterSup;
                    result.formatter = lineControlsSKUFormatter
                } else if (result.dataField === "QteTotalEnCommande") {
                    result.headerFormatter = headerFormatterSup;
                    result.align = 'center';
                } else if (result.dataField === "Quantite") {
                    result.align = 'center';
                } else if (result.dataField === "QteConformeEnStock") {
                    result.align = 'center';
                } else if (['NomTypePersonnalisation1', 'InfoPersonnalisation1'].includes(result.dataField)) {
                    result.hidden = !perso1
                    result.formatter = persoFormatter;
                } else if (['NomTypePersonnalisation2', 'InfoPersonnalisation2'].includes(result.dataField)) {
                    result.hidden = !perso2
                    result.formatter = persoFormatter;
                } else if (['NomTypePersonnalisation3', 'InfoPersonnalisation3'].includes(result.dataField)) {
                    result.hidden = !perso3
                    result.formatter = persoFormatter;
                } else if (result.dataField === "TypeArticle") {
                    result.isDummyField = true;
                    result.filter = undefined;
                    result.sort = false;
                    result.formatter = (cell, row, rowIndex, nomColonne) => {
                        if (row.TypeArticle === 'Kit') {
                            return <label><i className="ion ion-md-star d-block"></i></label>
                        } else if (row.TypeArticle === 'Simple' && row.IdKIT) {
                            return <label><Icon icon="ion:ellipse-outline" style={{ width: '10px', height: '10px', marginLeft: '10px' }} /></label >
                        } else if (row.TypeArticle === 'Simple') {
                            return <label><Icon icon="ion:ellipse" style={{ width: '10px', height: '10px' }} /></label >
                        }
                    }
                } else if (['lotsUtilises'].includes(result.dataField)) {
                    result.hidden = !['Soldée Picking', 'Soldée'].includes(order.StatutCommande)
                    result.formatter = (cell, row, rowIndex, nomColonne) => {
                        return <text data-toggle="tooltip" title={row.BulleLot} style={{ whiteSpace: 'pre-line' }}>{cell}</text>
                    }
                }
                return result
            })
    return (
        <>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Fiche commande <br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrderWatchHeader
                        order={order}
                        orderId={orderId}
                        modeModif={false}
                    />

                    <ToolkitProvider
                        keyField="IdLigneCommande"
                        data={orderLines}
                        columns={lineColumns}
                        bootstrap4
                        search
                        columnToggle
                    >
                        {props => (
                            <div>


                                <BootstrapTable
                                    {...props.baseProps}
                                    rowStyle={rowStyle}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                                    filter={filterFactory()}

                                />
                            </div>
                        )}
                    </ToolkitProvider>

                    <div className="section-form">
                        <Row style={{ alignItems: 'flex-start' }}>
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <label><strong>Légende</strong></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ alignItems: 'center', height: '20px' }}>
                                            <label><Icon icon="ion:star" style={{ width: '10px', height: '10px', marginRight: '10px' }} /></label><span>Kit</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ alignItems: 'center', height: '20px', marginLeft: '5px' }}>
                                            <label><Icon icon="ion:ellipse-outline" style={{ width: '10px', height: '10px', marginRight: '10px' }} /></label><span>Composant du kit</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ alignItems: 'center', height: '20px' }}>
                                            <label><Icon icon="ion:ellipse" style={{ width: '10px', height: '10px', marginRight: '10px' }} /></label><span>Article simple</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <sup>1</sup> Les SKU <strong style={{ color: 'orange' }} >en orange</strong> indiquent les articles illégaux ou inactifs.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <sup>2</sup> Les SKU <strong style={{ color: '#d03333' }} >en rouge</strong> indiquent les articles en rupture de stock.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <sup>3</sup> Total des quantités des commandes en attente et en cours de préparation.
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {order.StatutCommande === 'Soldée' && <Button variant="default" className="blue" onClick={() => { getPdfCoutCommande(order.IDCOMMANDE, order.NumCommandeClient, order.DateCommande) }}>Coût</Button>}
                    {order.StatutCommande === 'Soldée' && <Button variant="default" className="blue" onClick={() => { setSavVisible(true) }}>SAV</Button>}
                    <Button variant="default" className="blue" onClick={ouvrirDiscussion}>Discussions</Button>
                    <Button variant="default" className="blue" onClick={() => { setOuvrirDocuments(true) }}>Documents</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <DiscussionModal
                isModalVisible={discussionVisible}
                elementRattache='Order'
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                fermerDiscussion={() => setDiscussionVisible(false)}
            />
            <DocumentList
                isModalVisible={ouvrirDocuments}
                elementRattache='Order'
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                handleDocumentList={() => setOuvrirDocuments(false)}
            />
            <SavModal
                isModalVisible={savVisible}
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                fermerSav={() => setSavVisible(false)}
            />

        </>
    )
}

export default OrderWatch; 