import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Alert, Form } from "react-bootstrap"
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT

const DiscussionFiche = ({ idDiscussion, titre }) => {
    const [discussion, setDiscussion] = useState([]);
    const [messages, setMessages] = useState([]);
    const [nouveauMessage, setNouveauMessage] = useState('');

    useEffect(() => {
        getMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idDiscussion]);
    useEffect(() => {
        goBottom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    const addMessage = () => {
        if (nouveauMessage === '') {
            return;
        }
        let _nouveauMessage = nouveauMessage;
        setNouveauMessage('');
        const data = {
            idDiscussion: idDiscussion,
            Corps: _nouveauMessage
        }
        logecomApi.post(apiRoot + 'discussionsline', data, {}).then(() => {
            setNouveauMessage('');
            getMessages();
        }).catch((error) => {
            console.log(error);
        })
    }
    function getMessages() {
        if (!idDiscussion) {
            return;
        }
        logecomApi.get(apiRoot + 'discussions/' + idDiscussion).then((res) => {
            setDiscussion(res.data[0])
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
        logecomApi.get(apiRoot + 'discussions/' + idDiscussion + '/lines').then((res) => {
            setMessages(res.data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }
    const goBottom = () => {
        if (messages.length > 0) {
            const lastItem = messages[messages.length - 1]
            var element = document.getElementById(lastItem.IDLIGNE_DISCUSSION);
            if (element) {
                element.scrollIntoView({ behavior: "instant", block: "nearest", inline: "nearest" });
            }
        }
    }

    const controlsFormatter = (_, row) => {
        let float = 'right';
        let color = 'danger';
        if (row.CompteInterne) {
            float = 'left';
            color = 'primary';
        }
        let luPar = 'Lu par ' + row.LuPar + ' le ' + row.LuParLeDestinataireLe;
        return <div>
            <div id={row.IDLIGNE_DISCUSSION} style={{ width: 'auto', float: float, maxWidth: '80%' }}>
                <table>
                    <tbody>
                        <tr>{(row.LuParLeDestinataireLe === '' && row.CompteInterne) ? <td style={{ borderTop: 'none' }}><img src="assets/img/nouveau.png" width="32" height="27" alt="Logecom" className="img-fluid" /></td> : <td style={{ borderTop: 'none' }}>&nbsp;</td>}
                            <td style={{ borderTop: 'none' }}><span>{row.CreePar} le {row.CreeLe}</span></td>
                        </tr>
                        <tr>
                            {row.LuParLeDestinataireLe !== '' ? <td style={{ borderTop: 'none', verticalAlign: 'middle' }} title={luPar}><img src="assets/img/CocheLu.png" width="16" height="16" alt="Logecom" className="img-fluid" /></td> : <td style={{ borderTop: 'none' }}></td>}
                            <td style={{ borderTop: 'none' }}><Alert style={{ whiteSpace: 'pre-line', fontWeight: row.LuParLeDestinataireLe === '' ? 'bold' : 'normal' }} variant={color} >{row.Corps}</Alert ></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
    };
    const headerWidth = (width) => () => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const maxHeight2 = () => {
        var maxHeight = 0;
        if (document.getElementById('DiscussionListTable')) {
            maxHeight = document.getElementById('DiscussionListTable').offsetHeight > 500 ? document.getElementById('DiscussionListTable').offsetHeight - 200 : '350px';
        } else if (document.getElementById('SavListTable')) {
            maxHeight = document.getElementById('SavListTable').offsetHeight > 500 ? document.getElementById('SavListTable').offsetHeight - 200 : '350px';
        }
        return maxHeight;
    }
    const columns =
        [
            ["id", "id", 50], ["message", "message", 50]]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), sort: true }
                if (['id'].includes(result.dataField)) {
                    result.hidden = true;
                    result.headerAttrs = {
                        hidden: true
                    };
                } else if (result.dataField === 'message') {
                    result.formatter = controlsFormatter;
                    result.sort = false;
                    result.filter = undefined;
                    result.headerAttrs = {
                        hidden: true
                    };
                }

                return result
            })


    const rowStyle = () => {
        return { borderStyle: 'hidden' };
    };
    return (
        <div>
            <div className="section-form">
                <Row>
                    <Col sm={12}>
                        <strong>
                            Concernant :&nbsp;
                        </strong>
                        {titre}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <strong>
                            Sujet :&nbsp;
                        </strong>
                        {discussion.Sujet}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <strong>
                            Création :&nbsp;
                        </strong>
                        Le {discussion.CreeLe} par {discussion.CreePar}
                    </Col>
                </Row>
            </div>
            <ToolkitProvider
                keyField="IDLIGNE_DISCUSSION"
                data={messages}
                columns={columns}
                bootstrap4
                columnToggle
            >
                {props => (
                    <div style={{ height: '100%', maxHeight: maxHeight2(), overflow: 'auto', overflowX: 'hidden' }}>
                        <BootstrapTable
                            {...props.baseProps}
                            id="tableDiscussion"
                            rowStyle={rowStyle}
                            bordered={false}
                            wrapperClasses="table-responsive"
                        />
                    </div>
                )}
            </ToolkitProvider>
            {discussion.ClotureLe === '' && <Row className="mb-2">
                <Col sm={10}>
                    <Form.Control as="textarea" rows="3" placeholder="Tapez un message" maxLength="4000" value={nouveauMessage} onChange={e => setNouveauMessage(e.target.value)} />
                </Col>
                <Col sm={2}>
                    <Form.Label>
                        <Button disabled={nouveauMessage === ''} variant="default" className="blue" onClick={() => { addMessage() }}>Ajouter</Button>
                    </Form.Label>
                </Col>
            </Row>}
        </div>
    );

}

export default DiscussionFiche;