import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button, InputGroup } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import { downloadCSV, nullOrEmpty } from "../../utils/utils";
import DocumentList from '../Document/DocumentList';
import Select from 'react-select'
import YesNo from '../../utils/YesNo';
import Alerte from '../Alerte';
import { Spinner } from 'react-bootstrap'
import AdressesListModal from '../Adresses/AdressesListModal';
import CommandeLineEdit from './CommandeLineEdit';
import DiscussionModal from '../Discussion/DiscussionModal';
import SavModal from '../Sav/SavModal';
import { Icon } from '@iconify/react';
const apiRoot = process.env.REACT_APP_API_ROOT

const CommandeEdit = ({ fermer, client, orderId, idBoutique, personnalisations, tagsOrder }) => {
    const typeCommandeOptions = [{ label: "B2B", value: "B2B" }, { label: "B2C", value: "B2C" }];
    const ouiNonOptions = [{ label: "Oui", value: true }, { label: "Non", value: false }];
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [yesNoDeleteLine, setYesNoDeleteLine] = useState({ object: {}, activated: false });
    const [alerte, setAlerte] = useState(null);
    const [localisations, setLocalisations] = useState([])
    const [boutiques, setBoutiques] = useState([]);
    const [order, setOrder] = useState({});
    const [orderLines, setOrderLines] = useState([]);
    const [selectedLine, setSelectedLine] = useState(null)
    const [yesNoMajAdresse, setYesNoMajAdresse] = useState({ object: {}, activated: false });
    const [adresseVisible, setAdresseVisible] = useState(false);
    const [adresse, setAdresse] = useState({});
    const [adresseModifiee, setAdresseModifiee] = useState(false);
    const [idElementRattache, setIdElementRattache] = useState(0);
    const [titreElement, setTitreElement] = useState(false);
    const [documentList, setDocumentList] = useState(false);
    const [discussionVisible, setDiscussionVisible] = useState(false);
    const [savVisible, setSavVisible] = useState(false);
    const typeEtatModificationEnum = { NonModifie: 0, Modifie: 1, Demander: 2 };
    const [etatCommande, setEtatCommande] = useState(typeEtatModificationEnum.NonModifie);

    const valider = () => {
        if (actionWsEnCours) {
            return;
        }
        var dateMaxiEnvoi = "";
        if (order.DateMaxiEnvoi && order.DateMaxiEnvoi.length > 0) {
            var parts = order.DateMaxiEnvoi.split("/");
            var test = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
            if (isNaN(test) || order.DateMaxiEnvoi.length < 10) {
                setAlerte("La date limite d'envoi n'est pas valide");
                return;
            } else {
                dateMaxiEnvoi = parts[2] + parts[1] + parts[0];
            }
        }
        if (!order.NumCommandeClient || !order.CDE_NomLivraison || !order.CDE_AdresseLivraison || !order.CDE_CodePostalLivraison
            || !order.CDE_VilleLivraison || !order.CDE_CodePaysLivraison
            || !order.CDE_Telephone || !order.CDE_Email || !order.CDE_Transporteur) {
            setAlerte("Merci de renseigner les champs en rouge");
            return;
        }
        setActionWsEnCoursEnCours(true);
        const row = {
            Entete: {
                NumCommandeClient: order.NumCommandeClient, CDE_Societe: order.CDE_Societe, CDE_NomLivraison: order.CDE_NomLivraison,
                CDE_AdresseLivraison: order.CDE_AdresseLivraison, CDE_ComplAdresseLivraison: order.CDE_ComplAdresseLivraison,
                CDE_CodePostalLivraison: order.CDE_CodePostalLivraison, CDE_VilleLivraison: order.CDE_VilleLivraison,
                CDE_PaysLivraison: order.CDE_PaysLivraison, CDE_CodePaysLivraison: order.CDE_CodePaysLivraison,
                CDE_Telephone: order.CDE_Telephone, CDE_Email: order.CDE_Email, CDE_Transporteur: order.CDE_Transporteur,
                CDE_OptionTransport: order.CDE_OptionTransport, CDE_Commentaire: order.CDE_Commentaire,
                IDCLIENT: client, TypeCommande: order.TypeCommande, DateMaxiEnvoi: dateMaxiEnvoi,
                IDBOUTIQUE: (idBoutique ? idBoutique : order.IDBOUTIQUE),
                IDCARNET_ADRESSE: order.IDCARNET_ADRESSE,
                TraiterEnUrgence: order.TraiterEnUrgence,
                IDTAG_COMMANDE: order.IDTAG_COMMANDE
            },
            Lignes: orderLines.map(ligne => {
                return {
                    Quantite: ligne.Quantite, IDARTICLE: ligne.IDARTICLE, IDTYPE_PERSONNALISATION1: ligne.IDTYPE_PERSONNALISATION1,
                    IDTYPE_PERSONNALISATION2: ligne.IDTYPE_PERSONNALISATION2,
                    IDTYPE_PERSONNALISATION3: ligne.IDTYPE_PERSONNALISATION3,
                    InfoPersonnalisation1_1: ligne.InfoPersonnalisation1_1, InfoPersonnalisation1_2: ligne.InfoPersonnalisation1_2,
                    InfoPersonnalisation1_3: ligne.InfoPersonnalisation1_3,
                    InfoPersonnalisation2_1: ligne.InfoPersonnalisation2_1, InfoPersonnalisation2_2: ligne.InfoPersonnalisation2_2,
                    InfoPersonnalisation2_3: ligne.InfoPersonnalisation2_3,
                    InfoPersonnalisation3_1: ligne.InfoPersonnalisation3_1, InfoPersonnalisation3_2: ligne.InfoPersonnalisation3_2,
                    InfoPersonnalisation3_3: ligne.InfoPersonnalisation3_3,
                    IdKIT: ligne.IdKIT, IdLotSouhaite: ligne.IdLotSouhaite
                }
            })
        }
        if (orderId === 0) {
            logecomApi.post(apiRoot + 'orders', row).then((res) => {
                setActionWsEnCoursEnCours(false);
                setYesNoMajAdresse({ object: order, activated: adresseModifiee });
                fermer(true);
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                setAlerte(error.response.data.fault.detail)
            })
        } else {
            logecomApi.put(apiRoot + 'orders/' + orderId, row).then((res) => {
                setActionWsEnCoursEnCours(false);
                setYesNoMajAdresse({ object: order, activated: adresseModifiee });
                setEtatCommande(typeEtatModificationEnum.NonModifie);
                fermer(true);
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                setAlerte(error.response.data.fault.detail)
            })
        }
    }

    const _fermerSansEnregistrer = () => {
        if (etatCommande === typeEtatModificationEnum.NonModifie) {
            fermer(false);
        } else {
            setEtatCommande(typeEtatModificationEnum.Demander);
        }
    }

    const styleObligatoire = (value, libelle) => {
        if (!value || value === null || value.trim().length === 0)
            return (
                <strong style={{ color: 'red' }}>
                    <Form.Label>
                        {libelle}
                    </Form.Label>
                </strong>
            )
        else
            return (
                <Form.Label>
                    {libelle}
                </Form.Label>
            )
    }

    const majAdresse = (row) => {
        if (actionWsEnCours) {
            return;
        }
        setAdresseModifiee(true);

        if (adresse.IDCARNET_ADRESSE === 0) {
            logecomApi.post(apiRoot + 'addresses', adresse).then((res) => {
                const rowToSend = {
                    IDCOMMANDE: row.IDCOMMANDE,
                    IDCARNET_ADRESSE: res.data.IDCARNET_ADRESSE,
                }
                logecomApi.put(apiRoot + 'orders/' + rowToSend.IDCOMMANDE, rowToSend).then(() => {
                    setActionWsEnCoursEnCours(false);
                }).catch((error) => {
                    console.log(JSON.stringify(error))
                    setActionWsEnCoursEnCours(false);
                })
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })
        } else {
            logecomApi.put(apiRoot + 'addresses/' + adresse.IDCARNET_ADRESSE, adresse).then(() => {
                setActionWsEnCoursEnCours(false);
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })
        }
    }

    const handleDocumentList = (idcommande, numCommandeClient, dateCommande) => {
        if (typeof idcommande === 'number') {
            setIdElementRattache(idcommande);
            setTitreElement('Commande ' + numCommandeClient + ' du ' + dateCommande);
        }
        setDocumentList(true);
    };
    const chercherAdresse = () => {
        setAdresse({});
        setAdresseVisible(true);
    }

    useEffect(() => {
        if (adresse && adresse.IDCARNET_ADRESSE) {
            setOrder({
                ...order,
                IDCARNET_ADRESSE: adresse.IDCARNET_ADRESSE,
                CDE_Societe: adresse.Societe,
                CDE_NomLivraison: adresse.NomPrenom,
                CDE_AdresseLivraison: adresse.Adresse,
                CDE_ComplAdresseLivraison: adresse.ComplementAdresse,
                CDE_CodePostalLivraison: adresse.CodePostal,
                CDE_VilleLivraison: adresse.Ville,
                CDE_PaysLivraison: adresse.Pays,
                CDE_CodePaysLivraison: adresse.CodePays,
                CDE_Telephone: adresse.Telephone,
                CDE_Email: adresse.Email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adresse]);

    const deleteLine = (line) => {
        let ids = [line.IdLigneCommande];
        orderLines.forEach(_line => {
            if (_line.IdKIT === line.IDARTICLE) {
                ids.push(_line.IdLigneCommande);
            }
        });
        setOrderLines(orderLines.filter(elt => !ids.includes(elt.IdLigneCommande)));
    }

    const modifyOrder = (key, value) => {
        let tempOrder = JSON.parse(JSON.stringify(order))
        if (key === 'localisation') {
            const localisation = localisations.find(element => element.CodeIso2 === value);
            tempOrder['CDE_CodePaysLivraison'] = localisation.CodeIso2
            tempOrder['CDE_PaysLivraison'] = localisation.LibelleFr
        } else {
            tempOrder[key] = value
        }
        if ([
            'CDE_Societe',
            'CDE_NomLivraison',
            'CDE_AdresseLivraison',
            'CDE_ComplAdresseLivraison',
            'CDE_CodePostalLivraison',
            'CDE_VilleLivraison',
            'CDE_PaysLivraison',
            'CDE_CodePaysLivraison',
            'CDE_Telephone',
            'CDE_Email'
        ].includes(key) && tempOrder.AdresseSupprimee === 0) {
            var _adresse = {
                IDCARNET_ADRESSE: tempOrder.IDCARNET_ADRESSE,
                Adresse: tempOrder.CDE_AdresseLivraison,
                CodePays: tempOrder.CDE_CodePaysLivraison,
                CodePostal: tempOrder.CDE_CodePostalLivraison,
                ComplementAdresse: tempOrder.CDE_ComplAdresseLivraison,
                Email: tempOrder.CDE_Email,
                NomPrenom: tempOrder.CDE_NomLivraison,
                Pays: tempOrder.CDE_PaysLivraison,
                Societe: tempOrder.CDE_Societe,
                Telephone: tempOrder.CDE_Telephone,
                Ville: tempOrder.CDE_VilleLivraison,
                IDCLIENT: tempOrder.IDCLIENT,
                TypeGestion: tempOrder.TypeCommande === 'B2B' ? 1 : 2,
                NatureAdresse: 1,
            }
            setAdresse(_adresse);
            setAdresseModifiee(true);
        }
        setOrder(tempOrder);
        setEtatCommande(typeEtatModificationEnum.Modifie);
    }

    const infoPerso = (line, indice) => {
        let libelle = '';
        let cle = 'InfoPersonnalisation' + indice;
        ['1', '2', '3'].forEach(x => {
            if (line[cle + '_' + x] && !line[cle + '_' + x].startsWith('http')) {
                libelle += line[cle + '_' + x] + ' ';
            }
        });
        return libelle.trim().replace(/ /g, '\u00a0');
    }

    const headerFormatterSup = (column, colIndex, { sortElement, filterElement }) => {
        let num
        if (column.text === 'SKU') {
            num = '1';
        } else if (column.text === 'Qté toutes Cdes') {
            num = '2';
        }
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                    {column.text}<sup>{num}</sup> {sortElement}
                </span>
                {filterElement}
            </div>
        );
    };
    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const checkValidity = res => {
        return res === undefined || res === "" ? [] : res
    }
    const openEditLineModal = (line) => {
        if (line) {
            setSelectedLine(line);
        } else {
            setSelectedLine({
                Quantite: "0", IDARTICLE: -1, IdCommande: order.IDCOMMANDE,
                IDTYPE_PERSONNALISATION1: 0, CodePersonnalisation1: "", NomTypePersonnalisation1: "",
                IDTYPE_PERSONNALISATION2: 0, CodePersonnalisation2: "", NomTypePersonnalisation2: "",
                IDTYPE_PERSONNALISATION3: 0, CodePersonnalisation3: "", NomTypePersonnalisation3: "",
                InfoPersonnalisation1_1: "", InfoPersonnalisation1_2: "", InfoPersonnalisation1_3: "",
                InfoPersonnalisation2_1: "", InfoPersonnalisation2_2: "", InfoPersonnalisation2_3: "",
                InfoPersonnalisation3_1: "", InfoPersonnalisation3_2: "", InfoPersonnalisation3_3: "",
                GestionDesLots: false, IdLotSouhaite: 0, NumeroDeLot: ""
            })
        }

    }
    const lineControlsFormatter = (_, line) => {
        if (line.TypeArticle === 'Kit' || (line.TypeArticle === 'Simple' && (!line.IdKIT || line.IdKIT === 0))) {
            return (
                <div className="btn-group">
                    <button type="button" title="Modifier" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={() => openEditLineModal(line)}>
                        <i className="ion ion-md-create d-block"></i>
                    </button>
                    <button type="button" title="Supprimer" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={() => { setYesNoDeleteLine({ object: line, activated: true }) }}>
                        <i className="ion ion-md-trash d-block"></i>
                    </button>
                </div>
            )
        } else if (line.PersonnalisationPossible === 1 || line.GestionDesLots) {
            return (
                <div className="btn-group">
                    <button type="button" title="Personnaliser et/ou lot souhaité" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={() => openEditLineModal(line)}>
                        <i className="ion ion-md-create d-block"></i>
                    </button>
                </div>
            )
        }
    }
    const lineControlsSKUFormatter = (_, line) =>
        <div>
            <span style={{ color: [0, 2].includes(line.ArticleStatut) ? 'orange' : line.RuptureStock ? 'red' : 'black' }}>{line.SKU}</span>
        </div>;

    const editLineColumns = (colonneQteToutesCommande) => {
        let tab = [
            ["", "TypeArticle", 14, true], ["", "watch", 60, false],
            ["ID", "IdLigneCommande", 0, false], ["SKU", "SKU", 80, true], ["Qté", "Quantite", 50, true],
            ["Libellé 1", "LibelleArticle1", 120, true], ["Libellé 2", "LibelleArticle2", 120, true],
            ["Lot souhaité", "NumeroDeLot", 110, true],
            ["Nom personnalisation 1", "NomTypePersonnalisation1", 115, true], ["Info personnalisation 1", "InfoPersonnalisation1", 115, true],
            ["Nom personnalisation 2", "NomTypePersonnalisation2", 115, true], ["Info personnalisation 2", "InfoPersonnalisation2", 115, true],
            ["Nom personnalisation 3", "NomTypePersonnalisation3", 115, true], ["Info personnalisation 3", "InfoPersonnalisation3", 115, true]
        ];
        if (colonneQteToutesCommande) {
            tab.push(["Qté toutes Cdes", "QteTotalEnCommande", 80]);
            tab.push(["Qté stock physique", "QteConformeEnStock", 80]);
        }
        return tab.map(elt => {
            let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
            let perso1 = false
            let perso2 = false
            let perso3 = false
            let lines = orderLines;
            if (lines && lines.length > 0) {
                lines.forEach(element => {
                    perso1 = perso1 === true || element.IDTYPE_PERSONNALISATION1 !== 0;
                    perso2 = perso2 === true || element.IDTYPE_PERSONNALISATION2 !== 0;
                    perso3 = perso3 === true || element.IDTYPE_PERSONNALISATION3 !== 0;
                });
            }
            if (result.dataField === "watch") {
                result.isDummyField = true
                result.CSVExport = false
                result.formatter = lineControlsFormatter
                result.sort = false
                result.filter = undefined
            } else if (result.dataField === "IdLigneCommande") {
                result.hidden = true
            } else if (result.dataField === "SKU") {
                result.headerFormatter = headerFormatterSup;
                result.formatter = lineControlsSKUFormatter
            } else if (result.dataField === "QteTotalEnCommande") {
                result.headerFormatter = headerFormatterSup;
                result.align = 'center';
            } else if (result.dataField === "QteConformeEnStock") {
                result.align = 'center';
            } else if (result.dataField === "Quantite") {
                result.align = 'center';
            } else if (['NomTypePersonnalisation1', 'InfoPersonnalisation1'].includes(result.dataField)) {
                result.hidden = !perso1
                result.formatter = cell => cell.replace(/ /g, '\u00a0');
            } else if (['NomTypePersonnalisation2', 'InfoPersonnalisation2'].includes(result.dataField)) {
                result.hidden = !perso2
                result.formatter = cell => cell.replace(/ /g, '\u00a0');
            } else if (['NomTypePersonnalisation3', 'InfoPersonnalisation3'].includes(result.dataField)) {
                result.hidden = !perso3
                result.formatter = cell => cell.replace(/ /g, '\u00a0');
            } else if (result.dataField === "TypeArticle") {
                result.isDummyField = true;
                result.filter = undefined;
                result.sort = false;
                result.formatter = (cell, row, rowIndex, nomColonne) => {
                    if (row.TypeArticle === 'Kit') {
                        return <label><i className="ion ion-md-star d-block"></i></label>
                    } else if (row.TypeArticle === 'Simple' && row.IdKIT) {
                        return <label><Icon icon="ion:ellipse-outline" style={{ width: '10px', height: '10px', marginLeft: '10px' }} /></label >
                    } else if (row.TypeArticle === 'Simple') {
                        return <label><Icon icon="ion:ellipse" style={{ width: '10px', height: '10px' }} /></label >
                    }
                }
            }
            return result
        });
    };


    const genererNumCommande = () => {
        var d = new Date();
        var numCommande =
            d.getFullYear().toString() +
            (d.getMonth() + 1).toString().padStart(2, '0') +
            d.getDate().toString().padStart(2, '0') + "-" + d.getHours().toString().padStart(2, '0') +
            d.getMinutes().toString().padStart(2, '0') +
            d.getSeconds().toString().padStart(2, '0');
        modifyOrder("NumCommandeClient", numCommande);
    }
    useEffect(() => {
        if (!client || parseInt(client) === 0) {
            return;
        }

        logecomApi.get(apiRoot + 'clients/' + client + '/shops').then((res) => {
            setBoutiques(res.data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }, [client]
    );

    useEffect(() => {
        logecomApi.get(apiRoot + 'status/localisations').then((res) => {
            res.data.unshift({
                CodeIso2: '',
                IDLOCALISATION: 0,
                LibelleFr: '',
                LibelleGb: '',
                nbre: 0
            });
            setLocalisations(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }, []);

    useEffect(() => {
        setEtatCommande(typeEtatModificationEnum.NonModifie);
        if (orderId > 0) {
            logecomApi.get(apiRoot + 'orders/' + orderId).then((res) => {
                var order = res.data[0];
                setOrder(
                    {
                        IDCOMMANDE: order.IDCOMMANDE,
                        IDBOUTIQUE: order.IDBOUTIQUE,
                        NumCommandeClient: order.NumCommandeClient,
                        DateCommande: order.DateCommande,
                        CDE_Societe: order.Societe,
                        CDE_NomLivraison: order.NomLivraison,
                        CDE_AdresseLivraison: order.AdresseLivraison,
                        CDE_ComplAdresseLivraison: order.ComplAdresseLivraison,
                        CDE_CodePostalLivraison: order.CodePostalLivraison,
                        CDE_VilleLivraison: order.VilleLivraison,
                        CDE_PaysLivraison: order.PaysLivraison,
                        CDE_CodePaysLivraison: order.CodePaysLivraison,
                        CDE_Telephone: order.Telephone,
                        CDE_Email: order.Email,
                        CDE_Transporteur: order.CDE_Transporteur,
                        CDE_OptionTransport: order.CDE_OptionTransport,
                        CDE_Commentaire: order.Commentaire,
                        EXPE_NumTracking: order.EXPE_NumTracking,
                        IDCLIENT: order.IDCLIENT,
                        TypeCommande: order.TypeCommande,
                        DateMaxiEnvoi: order.DateMaxiEnvoi,
                        IDCARNET_ADRESSE: order.IDCARNET_ADRESSE,
                        AdresseSupprimee: order.AdresseSupprimee,
                        StatutCommande: order.StatutCommande,
                        IndiceCommande: order.IndiceCommande,
                        nbExpeditions: order.nbExpeditions,
                        IDEXPEDITION: order.IDEXPEDITION,
                        TraiterEnUrgencePossible: order.TraiterEnUrgencePossible,
                        TraiterEnUrgence: order.TraiterEnUrgence,
                        UrgenceNotifieeLe: order.UrgenceNotifieeLe,
                        IDTAG_COMMANDE: order.IDTAG_COMMANDE
                    }
                );
                logecomApi.get(apiRoot + 'orders/' + orderId + '/lines').then((res) => {
                    res.data.forEach(function (v) { v.IDARTICLE = v.IdArticle; delete v.IdArticle });
                    let data = res.data
                    data = data.map(elt => {
                        elt.InfoPersonnalisation1 = infoPerso(elt, 1)
                        elt.InfoPersonnalisation2 = infoPerso(elt, 2)
                        elt.InfoPersonnalisation3 = infoPerso(elt, 3)
                        return elt
                    })
                    setOrderLines(checkValidity(data))
                }).catch((error) => {
                    console.log(JSON.stringify(error))
                })
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })

        } else {
            if (orderId === 0 && idBoutique === 0) {
                setAlerte("Merci de sélectionner une boutique")
                _fermerSansEnregistrer();
            }
            let boutique = boutiques.find(boutique => boutique.IDBOUTIQUE === idBoutique);
            let NomBoutique = '';
            if (boutique) {
                NomBoutique = boutique.Nom;
            }
            setOrder({ NumCommandeClient: "", CDE_Societe: "", CDE_NomLivraison: "", CDE_AdresseLivraison: "", CDE_ComplAdresseLivraison: "", CDE_CodePostalLivraison: "", CDE_VilleLivraison: "", CDE_PaysLivraison: "France", CDE_CodePaysLivraison: "FR", CDE_Telephone: "", CDE_Email: "", CDE_Transporteur: "", CDE_OptionTransport: "", EXPE_NumTracking: "", CDE_Commentaire: "", IDCLIENT: client, TypeCommande: "B2B", NomBoutique: NomBoutique, IDCARNET_ADRESSE: 0, AdresseSupprimee: 0, TraiterEnUrgence: false })
            setOrderLines([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]
    );
    return (
        <>
            <Modal show={orderId !== null} backdrop="static" onHide={() => { _fermerSansEnregistrer() }} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Fiche commande <br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        {order.IDCOMMANDE && <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label >
                                    Date commande :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    {order.DateCommande}
                                </Form.Label>
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                <Form.Label>
                                    Statut :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    {order.StatutCommande}
                                </Form.Label>
                            </Col>
                        </Row>}
                        <Row className="mb-2">
                            <Col sm={2}>
                                {styleObligatoire(order.NumCommandeClient, 'N° de la commande :')}
                            </Col>
                            <Col sm={4}>
                                {order.IDCOMMANDE ?
                                    <>
                                        {order.NumCommandeClient}
                                        <strong>&nbsp;indice&nbsp;:&nbsp;</strong>{order.IndiceCommande}
                                    </>
                                    :
                                    <InputGroup>
                                        <Form.Control type="text" maxLength="20" style={{ marginRight: '5px' }} value={order.NumCommandeClient} onChange={e => modifyOrder("NumCommandeClient", e.target.value)} />
                                        {!order.IDCOMMANDE && <button readOnly={true} type="button" title='Générer un numéro de commande' className="btncommande btn-sm btn-primary" onClick={genererNumCommande}>
                                            <i className="ion ion-md-refresh d-block"></i>
                                        </button>}
                                    </InputGroup>
                                }
                            </Col>
                            {order.NomBoutique &&
                                <>
                                    <Col sm={2} style={{ textAlign: 'right' }}>
                                        {boutiques.length > 1 &&
                                            <Form.Label >
                                                Boutique :
                                            </Form.Label>}
                                    </Col>
                                    <Col sm={4}>
                                        {boutiques.length > 1 &&
                                            <Form.Label>
                                                {order.NomBoutique}
                                            </Form.Label>}
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label>
                                    Type :
                                </Form.Label>
                            </Col>
                            <Col sm={4} style={{ textAlign: 'right' }}>
                                <Select
                                    options={typeCommandeOptions}
                                    defaultValue={typeCommandeOptions[0]}
                                    onChange={e => modifyOrder("TypeCommande", e.value)}
                                    isSearchable={false}
                                />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                <Form.Label>
                                    Société :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <InputGroup>
                                    <Form.Control type="text" style={{ marginRight: '5px' }} maxLength="40" value={order.CDE_Societe} onChange={e => modifyOrder("CDE_Societe", e.target.value)} />
                                    <button type="button" title="Choisir dans le carnet d'adresse" className="btncommande btn-sm btn-primary" onClick={chercherAdresse}>
                                        <i className="ion ion-md-search d-block"></i>
                                    </button>
                                    <button type="button" title="Enregistrer l'adresse dans le carnet" className="btncommande btn-sm btn-primary" onClick={majAdresse}>
                                        <i className="ion ion-md-save d-block"></i>
                                    </button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                {styleObligatoire(order.CDE_NomLivraison, 'Nom :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="40" value={order.CDE_NomLivraison} onChange={e => modifyOrder("CDE_NomLivraison", e.target.value)} />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                {styleObligatoire(order.CDE_AdresseLivraison, 'Adresse :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="80" value={order.CDE_AdresseLivraison} onChange={e => modifyOrder("CDE_AdresseLivraison", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label>
                                    Complément adresse :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="80" value={order.CDE_ComplAdresseLivraison} onChange={e => modifyOrder("CDE_ComplAdresseLivraison", e.target.value)} />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                {styleObligatoire(order.CDE_CodePostalLivraison, 'Code postal :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="10" value={order.CDE_CodePostalLivraison} onChange={e => modifyOrder("CDE_CodePostalLivraison", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                {styleObligatoire(order.CDE_VilleLivraison, 'Ville :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="40" value={order.CDE_VilleLivraison} onChange={e => modifyOrder("CDE_VilleLivraison", e.target.value)} />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                {styleObligatoire(order.CDE_CodePaysLivraison, 'Pays :')}
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.LibelleFr}
                                    getOptionValue={option => option.CodeIso2}
                                    options={localisations}
                                    value={localisations.find(element => element.CodeIso2 === order.CDE_CodePaysLivraison)}
                                    onChange={e => modifyOrder("localisation", e.CodeIso2)}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                {styleObligatoire(order.CDE_Telephone, 'Téléphone :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="20" value={order.CDE_Telephone} onChange={e => modifyOrder("CDE_Telephone", e.target.value)} />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                {styleObligatoire(order.CDE_Email, 'Adresse mail :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="50" value={order.CDE_Email} onChange={e => modifyOrder("CDE_Email", e.target.value)} />
                            </Col>
                        </Row>

                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                {styleObligatoire(order.CDE_Transporteur, 'Transporteur :')}
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="50" value={order.CDE_Transporteur} onChange={e => modifyOrder("CDE_Transporteur", e.target.value)} />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }}>
                                <Form.Label>
                                    Date limite d'envoi :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" placeholder="JJ/MM/AAAA" maxLength="10" value={order.DateMaxiEnvoi} onChange={e => modifyOrder("DateMaxiEnvoi", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label >
                                    Option de transport :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="50" value={order.CDE_OptionTransport} onChange={e => modifyOrder("CDE_OptionTransport", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label >
                                    TAG :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.TAG}
                                    getOptionValue={option => option.IDTAG_COMMANDE}
                                    options={tagsOrder}
                                    value={tagsOrder.find(element => element.IDTAG_COMMANDE === order.IDTAG_COMMANDE)}
                                    onChange={e => modifyOrder("IDTAG_COMMANDE", e.IDTAG_COMMANDE)}
                                    isSearchable={false}
                                />
                            </Col>
                            <Col sm={2} style={{ textAlign: 'right' }} >
                                <Form.Label >
                                    Traiter en urgence :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value}
                                    options={ouiNonOptions}
                                    isDisabled={(!order.TraiterEnUrgencePossible && !order.TraiterEnUrgence) || orderId === 0}
                                    value={ouiNonOptions.find(element => element.value === order.TraiterEnUrgence)}
                                    onChange={e => modifyOrder("TraiterEnUrgence", e.value)}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label>
                                    Commentaire :
                                </Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control as="textarea" rows="3" maxLength="4000" value={order.CDE_Commentaire} onChange={e => modifyOrder("CDE_Commentaire", e.target.value)} />
                            </Col>
                        </Row>
                    </div>

                    <ToolkitProvider
                        keyField="IdLigneCommande"
                        data={orderLines}
                        columns={editLineColumns(order.IDCOMMANDE)}
                        bootstrap4
                        search
                        columnToggle
                        exportCSV
                    >
                        {props => (
                            <div className="relative btn-export-wrapper">

                                <button type="button" title='Ajouter une ligne de commande' className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { openEditLineModal(null) }}>
                                    <i className="ion ion-md-add d-block"></i>
                                </button>
                                <button type="button" title='Exporter la liste des lignes de commande' className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { downloadCSV(orderLines, editLineColumns(true), 'Lignes de Commande') }}>
                                    <i className="ion ion-md-archive d-block"></i>
                                </button>

                                <BootstrapTable
                                    {...props.baseProps}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                                    filter={filterFactory()}

                                />
                            </div>
                        )}
                    </ToolkitProvider>
                    {(orderLines.length > 0 || order.IDCOMMANDE) &&
                        <div className="section-form">
                            <Row style={{ alignItems: 'flex-start' }}>
                                {orderLines.length > 0 &&
                                    <Col sm={6}>
                                        <Row>
                                            <Col>
                                                <label><strong>Légende</strong></label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div style={{ alignItems: 'center', height: '20px' }}>
                                                    <label><Icon icon="ion:star" style={{ width: '10px', height: '10px', marginRight: '10px' }} /></label><span>Kit</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div style={{ alignItems: 'center', height: '20px', marginLeft: '5px' }}>
                                                    <label><Icon icon="ion:ellipse-outline" style={{ width: '10px', height: '10px', marginRight: '10px' }} /></label><span>Composant du kit</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div style={{ alignItems: 'center', height: '20px' }}>
                                                    <label><Icon icon="ion:ellipse" style={{ width: '10px', height: '10px', marginRight: '10px' }} /></label><span>Article simple</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {order.IDCOMMANDE &&
                                    <Col sm={6}>
                                        <Row>
                                            <Col>
                                                <sup>1</sup> Les SKU <strong style={{ color: 'orange' }} >en orange</strong> indiquent les articles illégaux ou inactifs.
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <sup>2</sup> Les SKU <strong style={{ color: '#d03333' }} >en rouge</strong> indiquent les articles en rupture de stock.
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <sup>3</sup> Total des quantités des commandes en attente et en cours de préparation.
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </div>
                    }
                </Modal.Body >
                <Modal.Footer>
                    {actionWsEnCours &&
                        <div style={{ textAlign: 'center' }}>
                            <Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    }
                    {orderId > 0 && <Button variant="default" className="blue" onClick={() => { setSavVisible(true) }}>SAV</Button>}
                    {orderId > 0 && <Button variant="default" className="blue" onClick={() => { setDiscussionVisible(true) }}>Discussions</Button>}
                    {orderId > 0 && <Button variant="default" className="blue" onClick={() => { handleDocumentList(order.IDCOMMANDE, order.NumCommandeClient, order.DateCommande) }}>Documents</Button>}
                    <Button variant="default" disabled={actionWsEnCours} className="blue" onClick={() => { valider() }}>Valider</Button>
                    <Button variant="default" disabled={actionWsEnCours} className="blue" onClick={() => { _fermerSansEnregistrer() }}>Fermer</Button>
                </Modal.Footer>
            </Modal >

            <CommandeLineEdit
                isModalVisible={selectedLine}
                fermer={() => setSelectedLine(null)}
                personnalisations={personnalisations}
                selectedLine={selectedLine}
                client={client}
                valider={((tabOrderLines, composants) => {
                    let _lines = JSON.parse(JSON.stringify(orderLines));
                    tabOrderLines.forEach(orderLine => {
                        if (orderLine.modification) {
                            let index = _lines.findIndex((obj => obj.IdLigneCommande === orderLine.IdLigneCommande));
                            _lines[index] = orderLine;
                            if (orderLine.TypeArticle === 'Kit') {
                                _lines.forEach(_line => {
                                    if (_line.IdKIT === orderLine.IDARTICLE) {
                                        var qte = composants.find(cmp => cmp.IdArticleFils === _line.IDARTICLE).Quantite
                                        _line.Quantite = orderLine.Quantite * qte;
                                    }
                                });
                            }
                        } else {
                            _lines.push(orderLine);
                        }
                    });
                    setOrderLines(_lines);
                    setSelectedLine(null);
                    setEtatCommande(typeEtatModificationEnum.Modifie);
                })}
            />

            <AdressesListModal
                visible={adresseVisible}
                fermer={() => setAdresseVisible(false)}
                client={client}
                natureAdresse={1}
                idBoutique={idBoutique}
                setAdresse={(adresse) => {
                    setAdresse(adresse);
                    setAdresseModifiee(false);
                }}
            />
            <DiscussionModal
                isModalVisible={discussionVisible}
                elementRattache='Order'
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                fermerDiscussion={() => setDiscussionVisible(false)}
            />
            <SavModal
                isModalVisible={savVisible}
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                fermerSav={() => setSavVisible(false)}
            />
            <DocumentList
                isModalVisible={documentList}
                elementRattache='Order'
                idElementRattache={idElementRattache}
                client={client}
                titre={titreElement}
                handleDocumentList={() => setDocumentList(!documentList)}
            />

            <YesNo
                show={yesNoDeleteLine.activated}
                hide={() => { setYesNoDeleteLine({ object: {}, activated: false }); }}
                callbackfunction={deleteLine}
                object={yesNoDeleteLine.object}
                title="Suppression"
                body={yesNoDeleteLine.object.TypeArticle === 'Kit' ? 'Confirmez-vous la suppression de ce kit et de ses composants ?' : 'Confirmez-vous la suppression de cet article ?'}
            />
            <YesNo
                show={yesNoMajAdresse.activated}
                hide={() => { setYesNoMajAdresse({ object: {}, activated: false }); setAdresseModifiee(false); }}
                callbackfunction={majAdresse}
                object={yesNoMajAdresse.object}
                title="Adresse"
                body={yesNoMajAdresse.activated && yesNoMajAdresse.object.IDCARNET_ADRESSE > 0 ? "Voulez-vous mettre à jour l'entrée du carnet d'adresse ?" : "Voulez-vous créer une nouvelle entrée dans le carnet d'adresses ?"}
            />

            <YesNo
                show={etatCommande === typeEtatModificationEnum.Demander}
                hide={() => { setEtatCommande(typeEtatModificationEnum.Modifie) }}
                callbackfunction={() => { fermer(false); }}
                title="Commande modifiée"
                body="Êtes-vous certain de quitter sans enregistrer ?"
            />

            <Alerte
                isModalVisible={alerte}
                handleAlerte={() => setAlerte(null)}
                message={alerte}
            />

        </>
    )
}


export default CommandeEdit; 