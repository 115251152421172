import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { Type } from 'react-bootstrap-table2-editor'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import DiscussionModal from '../Discussion/DiscussionModal';
import DocumentList from '../Document/DocumentList';
import { downloadCSV, patchFilterFactory } from "../../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const PrereceiptWatch = ({ isModalVisible, fermer, prereceiptid }) => {
    const [receipt, setReceipt] = useState({});
    const [lineData, setLineData] = useState([]);
    const [discussionVisible, setDiscussionVisible] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [ouvrirDocuments, setOuvrirDocuments] = useState(false);



    const isIE10Mode = document['documentMode'] === 10;

    const headerWidth = (width) => () => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    };


    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })
    const ouvrirDiscussion = () => {
        setDiscussionVisible(true);
    };
    useEffect(() => {
        if (prereceiptid) {
            logecomApi.get(apiRoot + 'pre_receipts/' + prereceiptid).then((res) => {
                setReceipt(res.data[0])
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })

            logecomApi.get(apiRoot + 'pre_receipts/' + prereceiptid + '/lines').then((res) => {
                setLineData(res.data)
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        }

    }, [prereceiptid]);
    // La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  	
    const lineColumns =
        [["ID", "Id_LignePreReception", 0, false], ["SKU", "SKU", 150, true], ["Libellé", "Lib_Article1", 150, true], ["Libellé complémentaire", "Lib_Article2", 150, true],
        ["Famille", "Lib_Famille", 120, true], ["N° Ligne", "NumLigne", 90, true], ["Qté", "QuantiteReception", 60, true], ["Statut", "Statut", 180, true]]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), filter: textFilter({ placeholder: elt[0] }), sort: true }
                if (result.text === "ID") {
                    result.hidden = true
                }
                if (result.text === "Statut") {
                    result.filter = selectFilter({
                        options: {
                            'Reçue': 'Reçue',
                            'Non reçue': 'Non reçue',
                        },
                        className: 'custom-select',
                        placeholder: 'Tous les statuts'
                    })
                    result.editor = {
                        type: Type.SELECT,
                        options: [
                            { value: 'Reçue', label: 'Reçue' },
                            { value: 'Non reçue', label: 'Non reçue' },
                        ]
                    }
                }
                return result
            })

    return (
        <>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Fiche Pré-réception <br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Statut :&nbsp; </strong>
                                {receipt.Statut}
                            </Col>
                            <Col sm={6}>
                                <strong>Origine :&nbsp;</strong>
                                {receipt.Origine}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Créé le :&nbsp;</strong>
                                {receipt.CreeLe}
                            </Col>
                            <Col sm={6}>
                                <strong>Modifié le :&nbsp;</strong>
                                {receipt.ModifieLe}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Bordereau :&nbsp;</strong>
                                {receipt.Lib_BordereauLivraison}
                            </Col>
                            <Col sm={6}>
                                <strong>Transporteur :&nbsp;</strong>
                                {receipt.Lib_Transporteur}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Réception prévue le :&nbsp;</strong>
                                {receipt.DatePrevisionnelle}
                            </Col>
                            <Col sm={6}>
                                <strong>Clôturée :&nbsp;</strong>
                                {receipt.Cloturee}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Fournisseur :&nbsp;</strong>
                                {receipt.LibelleFournisseur}
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label>
                                    Commentaire :
                                </Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control as="textarea" readOnly rows="3" maxLength="4000" defaultValue={receipt.Commentaire} />
                            </Col>
                        </Row>
                    </div>

                    {isIE10Mode && <div className="alert alert-danger">
                        <strong>react-bootstrap-table2</strong> doesn't support Internet Explorer 10
                    </div>}
                    {!isIE10Mode && <React.Fragment>
                        <ToolkitProvider
                            keyField="Id_LignePreReception"
                            data={lineData}
                            columns={lineColumns}
                            bootstrap4
                            search
                            columnToggle
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                        >
                            {props => (
                                <div>
                                    <Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, lineColumns, 'Pré-Réception') }}>Export CSV</Button>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        wrapperClasses="table-responsive"
                                        pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 20 })}
                                        filter={myTableFilterFactory()}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </React.Fragment>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={ouvrirDiscussion}>Discussions</Button>
                    <Button variant="default" className="blue" onClick={() => { setOuvrirDocuments(true) }}>Documents</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <DiscussionModal
                isModalVisible={discussionVisible}
                elementRattache='Pre-Receipt'
                idElementRattache={receipt.id_PreReception}
                client={receipt.Id_Client}
                titre={'Pré-réception du ' + receipt.CreeLe}
                fermerDiscussion={() => setDiscussionVisible(false)}
            />
            <DocumentList
                isModalVisible={ouvrirDocuments}
                elementRattache='Pre-Receipt'
                idElementRattache={receipt.id_PreReception}
                client={receipt.Id_Client}
                titre={'Pré-réception du ' + receipt.CreeLe}
                handleDocumentList={() => setOuvrirDocuments(false)}
            />
        </>
    )
}

export default PrereceiptWatch; 