import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import Select from 'react-select'
import logecomApi from "../../utils/axiosInstance";
import DiscussionFiche from '../Discussion/DiscussionFiche';
import DiscussionAdd from '../Discussion/DiscussionAdd'
import SavLinesListTable from './SavLinesListTable';
import LitigeTransporteur from './LitigeTransporteur';
import YesNo from '../../utils/YesNo';
import Alerte from '../Alerte';
import DocumentList from '../Document/DocumentList';
const apiRoot = process.env.REACT_APP_API_ROOT;

const SavEdit = ({ editId, setEditId, fermer, idCommande, client, statutSAVOptions, motifSAVOptions, decisionSAVOptions, titre }) => {
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [sav, setSav] = useState({});
    const [messageErreur, setMessageErreur] = useState(null);
    const [discussionAdd, setDiscussionAdd] = useState(false);
    const [yesNoSauver, setYesNoSauver] = useState(false);
    const [decisionPrecedente, setDecisionPrecedente] = useState(null);
    const [libelleDecisionPrecedente, setLibelleDecisionPrecedente] = useState(null);
    const [documentList, setDocumentList] = useState(false);
    const decisionVoirDiscussion = 3;
    const statutCloture = 4;
    const statutClotureNonRecu = 6;
    const statutAnnule = 5;
    const statutADefinir = 11;
    const statutsLectureSeule = [statutCloture, statutAnnule, statutClotureNonRecu];
    useEffect(() => {
        if (!Number.isInteger(editId) || !client || parseInt(client) === 0) {
            return;
        }
        if (editId === 0) {
            setSav({
                IDCLIENT: client,
                IDCOMMANDE: idCommande,
                CommentaireClient: null,
                Motif: 0,
                Decision: 0,
                LitigeTransporteurDdeParClient: false,
            });
        } else {
            loadSav();
        }
    }, [editId, client]);

    const loadSav = () => {
        var url = apiRoot + 'clients/' + client;
        url = url + '/sav?duree=3650&IdCommande=0&IdSav=' + editId;
        logecomApi.get(url).then((res) => {
            let data = res.data[0];
            if (data.Decision) {
                setDecisionPrecedente(data.Decision);
            }
            setSav(data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }

    const handleDocumentList = (idsav, libelle, dateSav) => {
        setDocumentList(true);
    };

    const sauver = (resterOuvertApresSauver) => {
        let tmpsav = sav;
        if (actionWsEnCours) {
            return false;
        }
        if (!tmpsav.Motif) {
            setMessageErreur("Le motif doit être renseigné");
            return false;
        }
        if (parseInt(tmpsav.Decision) === decisionVoirDiscussion && parseInt(tmpsav.IDDISCUSSION) === 0) {
            setMessageErreur('Vous avez choisi "Voir discussion" mais aucune discussion n\'a été créée');
            editRow("Decision", null);
            tmpsav.Decision = null;
            if (tmpsav.IDSAV > 0) {
                resterOuvertApresSauver = true;
            } else {
                return false;
            }
        }
        setActionWsEnCoursEnCours(true);

        var row = {
            IDCLIENT: client,
            IDCOMMANDE: idCommande,
            CommentaireClient: tmpsav.CommentaireClient,
            Motif: tmpsav.Motif,
            Decision: tmpsav.Decision,
            LitigeTransporteurDdeParClient: tmpsav.LitigeTransporteurDdeParClient,
        }
        if (editId === 0) {
            logecomApi.post(apiRoot + 'sav', row).then((res) => {
                setActionWsEnCoursEnCours(false);
                editId = parseInt(res.data.ressource_Created.split(/[/ ]+/).pop());
                setEditId(editId);
                if (!resterOuvertApresSauver) {
                    fermer();
                } else {
                    loadSav();
                }
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })
        } else {
            logecomApi.put(apiRoot + 'sav/' + tmpsav.IDSAV, row).then(() => {
                setActionWsEnCoursEnCours(false);
                if (!resterOuvertApresSauver) {
                    fermer();
                } else {
                    loadSav();
                }
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })

        }
        return true;
    }

    const editRow = (key, value) => {
        if (key === 'Decision' && parseInt(value) === decisionVoirDiscussion && (!sav.IDDISCUSSION || parseInt(sav.IDDISCUSSION) === 0)) {
            if (!sav.IDSAV || parseInt(sav.IDSAV) === 0) {
                setYesNoSauver(true);
            } else {
                if (sauver(true)) {
                    setLibelleDecisionPrecedente(decisionPrecedente && value && parseInt(decisionPrecedente) !== parseInt(value)
                        ? 'Décision précédente : ' + decisionSAVOptions.find(type => type.value === decisionPrecedente.toString()).label
                        : null);
                    setDiscussionAdd(true);
                } else {
                    editRow('Decision', null);
                }
            }
        } else if (key === 'Motif' && parseInt(value) === statutADefinir) {
            setMessageErreur("Le motif 'A définir' n'est pas disponible depuis le site WEB");
            return;
        }
        let temp = JSON.parse(JSON.stringify(sav))
        temp[key] = value
        setSav(temp)
    };

    return (
        <>
            <Modal show={Number.isInteger(editId)} backdrop="static" onHide={fermer} centered size={sav.IDDISCUSSION > 0 ? 'xl2' : 'xl'}>
                <Modal.Header closeButton>
                    <Modal.Title>Fiche SAV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Concernant :&nbsp;
                                </strong>
                                {titre}
                            </Col>
                        </Row>
                    </div>

                    <Row style={{ alignItems: 'baseline' }}>
                        <Col sm={sav.IDDISCUSSION > 0 ? 6 : 12}>
                            <div className="section-form">
                                {sav.IDSAV &&
                                    <Row className="mb-2">
                                        <Col sm={2}>
                                            <strong>Créé le :&nbsp; </strong>
                                        </Col>
                                        <Col sm={4}>
                                            {sav.CreeLe}
                                        </Col>
                                        <Col sm={2}>
                                            <strong>Colis reçu le :&nbsp;</strong>
                                        </Col>
                                        <Col sm={4}>
                                            {sav.DateReception}
                                        </Col>
                                    </Row>}
                                <Row className="mb-2">
                                    <Col sm={2}>
                                        <strong>Statut :&nbsp;</strong>
                                    </Col>
                                    <Col sm={4}>
                                        {sav.Statut ? statutSAVOptions.find(statut => statut.value === sav.Statut.toString()).label : "Déclaré par le client"}
                                    </Col>
                                    <Col sm={2}>
                                        <strong>Origine :&nbsp;</strong>
                                    </Col>
                                    <Col sm={4}>
                                        {sav.Origine === 0 ? "GD Logistique" : "Client"}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={2}>
                                        <strong>Transporteur :&nbsp;</strong>
                                    </Col>
                                    <Col sm={4}>
                                        {sav.NomTransporteur}
                                    </Col>
                                    <Col sm={2}>
                                        <strong>Numéro suivi :&nbsp;</strong>
                                    </Col>
                                    <Col sm={4}>
                                        {sav.TrackingURL && sav.TrackingURL.startsWith('http') ?
                                            <a target="_blank" rel="noreferrer" href={sav.TrackingURL}>{sav.NumTrackingRetour}</a> :
                                            sav.NumTrackingRetour
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={2}>
                                        <strong>Motif :&nbsp;</strong>
                                    </Col>
                                    <Col sm={4}>
                                        {((sav.DateReception && sav.DateReception !== '') || (sav.Statut && statutsLectureSeule.includes(sav.Statut))) && sav.Motif ?
                                            motifSAVOptions.find(type => type.value === sav.Motif.toString()).label
                                            : <Select
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                options={motifSAVOptions}
                                                value={sav.Motif ? motifSAVOptions.find(type => type.value === sav.Motif.toString()) : null}
                                                onChange={e => editRow('Motif', e.value)}
                                                disabled={false}
                                                isSearchable={false}
                                            />
                                        }
                                    </Col>
                                    <Col sm={2}>
                                        <strong>Décision :&nbsp;</strong>
                                    </Col>
                                    <Col sm={4}>
                                        {parseInt(sav.Decision) === decisionVoirDiscussion || statutsLectureSeule.includes(sav.Statut) ?
                                            parseInt(sav.Decision) > 0 ? decisionSAVOptions.find(type => type.value === sav.Decision.toString()).label : ''
                                            :
                                            <Select
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                options={decisionSAVOptions}
                                                value={sav.Decision ? decisionSAVOptions.find(type => type.value === sav.Decision.toString()) : null}
                                                onChange={e => editRow('Decision', e.value)}
                                                isDisabled={false}
                                                isSearchable={false}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={2}>
                                        <Form.Label>
                                            <strong>Commentaire :</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col sm={4}>
                                        <span data-toggle="tooltip" title="Le nombre de caractère maximum autorisé est 50. Si vous avez besoin de plus, merci d'ouvrir une discussion.">
                                            <Form.Control as="textarea" rows="1" maxLength="50" disabled={statutsLectureSeule.includes(sav.Statut)} value={sav.CommentaireClient} onChange={e => editRow("CommentaireClient", e.target.value)} />
                                        </span>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            label="Demander la création d'un litige auprès du transporteur"
                                            disabled={statutsLectureSeule.includes(sav.Statut)}
                                            defaultChecked={sav.LitigeTransporteurDdeParClient}
                                            onChange={(event) => { editRow("LitigeTransporteurDdeParClient", event.target.checked) }}
                                        >
                                        </Form.Check>
                                    </Col>
                                </Row>
                            </div>
                            {idCommande > 0 && <div>
                                <Row>
                                    <Col sm={12}>
                                        <SavLinesListTable
                                            idSav={editId}
                                            idCommande={idCommande}
                                        >
                                        </SavLinesListTable>
                                    </Col>
                                </Row>
                            </div>}
                            {sav.IDLITIGE_TRANSPORTEUR > 0 && sav.LitigeTransporteurDdeParClient && <LitigeTransporteur sav={sav} />}
                        </Col>
                        {sav.IDDISCUSSION > 0 ?
                            <Col sm={6}>
                                <DiscussionFiche
                                    elementRattache='SAV'
                                    idElementRattache={sav.IDSAV}
                                    idDiscussion={sav.IDDISCUSSION}
                                    client={client}
                                    titre={sav.LibelleCommande !== '' ? 'SAV de la commande ' + sav.LibelleCommande : 'SAV commande inconnue'}
                                >
                                </DiscussionFiche>
                            </Col> : null}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {sav.IDSAV > 0 && <Button variant="default" className="blue" onClick={() => { handleDocumentList(sav.IDSAV, sav.LibelleCommande !== '' ? 'SAV de la commande ' + sav.LibelleCommande : 'SAV commande inconnue', sav.CreeLe) }}>Documents</Button>}
                    <Button disabled={(sav.Statut && statutsLectureSeule.includes(sav.Statut))} variant="default" className="blue" onClick={() => { sauver(false) }}>Sauver</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal >

            <DiscussionAdd
                isModalVisible={discussionAdd && parseInt(sav.IDSAV) > 0}
                elementRattache='SAV'
                idElementRattache={sav.IDSAV}
                client={client}
                titre={'SAV de la commande ' + sav.LibelleCommande}
                handleDiscussionAdd={(annuler) => {
                    setDiscussionAdd(false);
                    if (annuler) {
                        editRow("Decision", null);
                        sauver(true);
                    } else {
                        loadSav();
                    }
                }}
                messageDebut={libelleDecisionPrecedente}
            />

            <YesNo
                show={yesNoSauver}
                hide={setYesNoSauver}
                callbackfunction={() => {
                    if (sauver(true)) {
                        setDiscussionAdd(true);
                    } else {
                        editRow('Decision', null);
                    }
                }}
                cancelfunction={() => { editRow('Decision', null); setDiscussionAdd(false); }}
                title="Sauvegarder"
                body="Pour créer une discussion, ce SAV doit être enregistré. Voulez-vous l'enregistrer ?"
            />
            <Alerte
                isModalVisible={messageErreur}
                handleAlerte={() => setMessageErreur(null)}
                message={messageErreur}
            />

            <DocumentList
                isModalVisible={documentList}
                elementRattache='Sav'
                idElementRattache={sav.IDSAV}
                client={client}
                titre={sav.LibelleCommande !== '' ? 'SAV de la commande ' + sav.LibelleCommande : 'SAV du ' + sav.CreeLe}
                handleDocumentList={() => setDocumentList(!documentList)}
            />
        </>
    );
}

export default SavEdit;
