import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import Select from 'react-select';
import logecomApi from "../../utils/axiosInstance";
import Alerte from '../Alerte';

const apiRoot = process.env.REACT_APP_API_ROOT;

const PreReceptionLineEdit = ({ isModalVisible, idpre_reception, fermer, valider, selectedLine, newRow, client }) => {
    const [linePreReception, setLinePreReception] = useState({});
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [messageErreur, setMessageErreur] = useState(null);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (!client || parseInt(client) === 0) {
            return;
        }
        setArticles([]);

        if (client !== 0) {
            logecomApi.get(apiRoot + 'clients/' + client + '/items/filter_status?dateDebutQteVendue=\'2500-01-01\'&dateFinQteVendue=\'2500-01-01\'').then((res) => {
                setArticles(checkValidity(res.data))
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        }

        setLinePreReception({
            Id_LignePreReception: 0, SKU: "", Lib_Article1: "", Lib_Article2: "",
            Lib_Famille: "", NumLigne: 0, QuantiteReception: 0,
            Statut: "Non reçue"
        })
    }, [client]
    );

    useEffect(() => {
        if (selectedLine) {
            if (selectedLine.Id_LignePreReception === 0) {
                setLinePreReception(selectedLine)
            } else if (selectedLine.Id_LignePreReception > 0) {
                logecomApi.get(apiRoot + 'pre_receiptsline/' + selectedLine.Id_LignePreReception).then((res) => {
                    let checkedData = checkValidity(res.data)
                    if (checkedData.length > 0) {
                        setLinePreReception(checkedData[0])
                    } else {
                        return;
                    }
                }).catch((error) => {
                    console.log(JSON.stringify(error))
                })
            }
        }
    }, [selectedLine]);

    const checkValidity = res => {
        return res === undefined || res === "" ? [] : res
    };

    const editLine = (key, value) => {
        const intValues = ["QuantiteReception"]
        const intReg = /^\d*$/

        if (intValues.includes(key)) {
            value = value === "" ? 0 : value
            if (!intReg.test(value)) {
                value = linePreReception[key]
            }
            else {
                value = parseInt(value).toString()
            }
        }
        console.log(value)
        let tempLine = JSON.parse(JSON.stringify(linePreReception)) // Important, sans copie profonde, React ne déclenchera pas de rafraîchissement
        tempLine[key] = value
        setLinePreReception(tempLine)
    };

    // Version de la modification de la ligne sans contrôle, et sans copie profonde, attention lors de plusieurs appels simultanés, il ne faut pas utiliser l'autre méthode
    const safeEditLine = (key, value) => {
        let tempLine = linePreReception; //JSON.parse(JSON.stringify(linePreReception))
        tempLine[key] = value
        setLinePreReception(tempLine)
        //console.log("SELECT ", tempLine);
    };

    const filteredArticles = () => {
        if (articles === undefined || articles === "") {
            return [];
        } else {
            return articles.filter(function (article) {
                return article.TypeArticle !== 'Kit' && (['Actif', 'Illégal'].includes(article.Statut));
            })
        }
    };

    const sauver = () => {
        if (actionWsEnCours) {
            return;
        }
        setActionWsEnCoursEnCours(true);

        if (parseInt(linePreReception.QuantiteReception) === 0 || linePreReception.idarticle === -1) {
            setMessageErreur("Veuillez SVP renseigner une quantité supérieure à 0 et l'article.")
            setActionWsEnCoursEnCours(false);
            return;
        };


        if (idpre_reception) {
            if (selectedLine && selectedLine.Id_LignePreReception > 0) {
                const lineUpd = { QuantiteReception: linePreReception.QuantiteReception }
                logecomApi.put(apiRoot + 'pre_receiptsline/' + selectedLine.Id_LignePreReception, lineUpd).then((res) => {
                    setActionWsEnCoursEnCours(false);
                    valider(null);
                }).catch((error) => {
                    setActionWsEnCoursEnCours(false);
                    setMessageErreur(error.response.data.fault.detail)
                })
            } else {
                const lineToSend = {
                    idpre_reception: idpre_reception,
                    idarticle: linePreReception.idarticle,
                    QuantiteReception: linePreReception.QuantiteReception
                }
                logecomApi.post(apiRoot + 'pre_receiptsline', lineToSend).then((res) => {
                    setActionWsEnCoursEnCours(false);
                    valider(null)
                }).catch((error) => {
                    setActionWsEnCoursEnCours(false);
                    setMessageErreur(error.response.data.fault.detail)
                })
            }
        } else {
            let paramLine = { ...linePreReception };
            valider(paramLine);
            setActionWsEnCoursEnCours(false);

        }

    }

    return (
        <>
            <Modal centered show={isModalVisible} backdrop="static" className={newRow ? "" : "nestedDialog"} dialogClassName="modal2" onHide={fermer} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Fiche ligne Pré-réception <br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        {newRow &&
                            <Row className="mb-2">
                                <Col sm={6}>
                                    <strong>Article :&nbsp; </strong>
                                </Col>
                                <Col sm={6}>
                                    <Select
                                        options={filteredArticles().map(elt => { return { label: elt.SKU + " " + elt.Lib_Article1 + " " + elt.Lib_Article2, value: elt.Id_Article } })}
                                        onChange={e => {
                                            const article = filteredArticles().find(elt => elt.Id_Article === e.value);
                                            Object.keys(article).forEach(elt => safeEditLine(elt, article[elt]));
                                            safeEditLine("idarticle", e.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Quantité :&nbsp; </strong>
                            </Col>
                            <Col sm={6}>
                                <Form.Control type="text" value={linePreReception.QuantiteReception} onChange={e => editLine("QuantiteReception", e.target.value)} />
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={sauver}>Valider</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <Alerte
                isModalVisible={messageErreur}
                handleAlerte={() => setMessageErreur(null)}
                message={messageErreur}
            />
        </>


    )

}

export default PreReceptionLineEdit; 