import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap"
import LotsListTable from './LotsListTable'

const LotsByItem = ({ isModalVisible, fermer, client, articleId, setLot, modeSelection, titre, qteCommandee}) => {
    const [lotSelect, setLotSelect] = useState({});

    const valider = () => {
        setLot(lotSelect);
        setLotSelect({});
        _fermer()
    }
    const _fermer = () => {
        setLotSelect({});
        fermer()
    }

    const qteEnCommandeSuperieurQteEnStock = (lotSelect) => {
        if (qteCommandee  > lotSelect.QuantiteEnStock) {
            return true;
        }else{
            return false;
        }
    }

    useEffect(() => {
        setLotSelect({});
    }, []);

    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Liste des lots {titre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LotsListTable client={client} articleId={articleId}  modeSelection={modeSelection} setLot={setLotSelect} />
                </Modal.Body>
                <Modal.Footer>
                    {qteEnCommandeSuperieurQteEnStock(lotSelect) && <strong style={{ color: 'red' }}>Attention : La quantité commandée est supérieure à la quantité en stock</strong>}
                    <Button hidden={!modeSelection} disabled={!lotSelect.IDLOT} variant="default" className="blue" onClick={() => { valider() }}>Valider</Button>
                    <Button variant="default" className="blue" onClick={() => { _fermer() }}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default LotsByItem;