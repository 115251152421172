import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import Select from 'react-select'
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT;

const AdresseEdit = ({ client, visible, fermer, adresseModif }) => {
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const natures = [{ label: 'Client', value: 1 }, { label: 'Fournisseur', value: 2 }]
    const types = [{ label: 'B2B', value: 1 }, { label: 'B2C', value: 2 }]
    const [boutiques, setBoutiques] = useState([]);
    const [familleContact, setFamilleContact] = useState([]);
    const [localisations, setLocalisations] = useState([])
    const [adresse, setAdresse] = useState({});
    useEffect(() => {
        if (!adresseModif) {
            setAdresse(
                {
                    IDCARNET_ADRESSE: 0,
                    IDCLIENT: client,
                    Adresse: '',
                    CodePays: 'FR',
                    Pays: 'France',
                    CodePostal: '',
                    ComplementAdresse: '',
                    Email: '',
                    FamilleContact: '',
                    IDBOUTIQUE: 0,
                    NatureAdresse: 1,
                    NomPrenom: '',
                    Societe: '',
                    Telephone: '',
                    TypeGestion: 1,
                    Ville: '',
                }
            );
        } else {
            setAdresse(adresseModif);
        }
    }, [adresseModif, client]);

    useEffect(() => {
        if (client < 1) {
            return;
        }
        logecomApi.get(apiRoot + 'clients/' + client + '/shops').then((res) => {
            res.data.unshift({ IDBOUTIQUE: 0, Nom: "Toutes les boutiques" });
            setBoutiques(res.data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

        logecomApi.get(apiRoot + 'clients/' + client + '/adresse_famille_contact').then((res) => {
            setFamilleContact(res.data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

        logecomApi.get(apiRoot + 'status/localisations').then((res) => {
            setLocalisations(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }, [client]
    );

    const formattedBoutiques = boutiques.map(
        boutique => ({ value: boutique.IDBOUTIQUE, label: boutique.Nom })
    )

    const sauver = () => {
        if (actionWsEnCours) {
            return;
        }
        setActionWsEnCoursEnCours(true);
        var row = {
            IDCARNET_ADRESSE: adresse.IDCARNET_ADRESSE,
            IDCLIENT: adresse.IDCLIENT,
            IDBOUTIQUE: adresse.IDBOUTIQUE,
            Adresse: adresse.Adresse,
            CodePays: adresse.CodePays,
            CodePostal: adresse.CodePostal,
            ComplementAdresse: adresse.ComplementAdresse,
            Email: adresse.Email,
            FamilleContact: adresse.FamilleContact,
            NatureAdresse: adresse.NatureAdresse,
            NomPrenom: adresse.NomPrenom,
            Pays: adresse.Pays,
            Societe: adresse.Societe,
            Telephone: adresse.Telephone,
            TypeGestion: adresse.TypeGestion,
            Ville: adresse.Ville,
        }

        if (adresse.IDCARNET_ADRESSE === 0) {
            logecomApi.post(apiRoot + 'addresses', row).then(() => {
                setActionWsEnCoursEnCours(false);
                fermer();
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })
        } else {
            logecomApi.put(apiRoot + 'addresses/' + adresse.IDCARNET_ADRESSE, row).then(() => {
                setActionWsEnCoursEnCours(false);
                fermer();
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })
        }
    }

    const modifieAdresse = (key, value) => {
        let temp = JSON.parse(JSON.stringify(adresse))
        if (key === 'localisation') {
            const localisation = localisations.find(element => element.CodeIso2 === value);
            temp['CodePays'] = localisation.CodeIso2
            temp['Pays'] = localisation.LibelleFr
        } else {

        }
        temp[key] = value
        setAdresse(temp)
    }
    return (
        <div>
            <Modal show={visible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Fiche Adresse</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        {formattedBoutiques.length > 2 && <Row className="mb-2">
                            <Col sm={2}>
                                <strong>&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                            </Col>
                            <Col sm={2}>
                                <strong>Boutique :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    options={formattedBoutiques}
                                    onChange={e => modifieAdresse('IDBOUTIQUE', e.value)}
                                    placeholder={formattedBoutiques.find(option => option.value === adresse.IDBOUTIQUE) && formattedBoutiques.find(option => option.value === adresse.IDBOUTIQUE).label}
                                    value={formattedBoutiques.find(option => option.value === adresse.IDBOUTIQUE)}
                                >
                                </Select>
                            </Col>
                        </Row>}

                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Société :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="40" value={adresse.Societe} onChange={e => modifieAdresse("Societe", e.target.value)} />
                            </Col>
                            <Col sm={2}>
                                <strong>Nature :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value}
                                    options={natures}
                                    value={natures.find(nature => nature.value === adresse.NatureAdresse)}
                                    onChange={e => modifieAdresse('NatureAdresse', e.value)}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Nom prénom :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="40" value={adresse.NomPrenom} onChange={e => modifieAdresse("NomPrenom", e.target.value)} />
                            </Col>
                            <Col sm={2}>
                                {adresse.NatureAdresse === 1 ? <strong>Type :&nbsp; </strong> : null}
                            </Col>
                            <Col sm={4}>
                                {adresse.NatureAdresse === 1 ?
                                    <Select
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                        options={types}
                                        value={types.find(type => type.value === adresse.TypeGestion)}
                                        onChange={e => modifieAdresse('TypeGestion', e.value)}
                                        disabled={true}
                                        isSearchable={false}
                                    /> : null}
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Adresse :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="80" value={adresse.Adresse} onChange={e => modifieAdresse("Adresse", e.target.value)} />
                            </Col>
                            <Col sm={2}>
                                <strong>Famille de contact<sup>1</sup> :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="40" list="data" value={adresse.FamilleContact} onChange={e => modifieAdresse("FamilleContact", e.target.value)} />
                                <datalist id="data">
                                    {familleContact.map((famille) =>
                                        <option key={famille.FamilleContact} value={famille.FamilleContact} />
                                    )}
                                </datalist>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Complément Adresse :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="80" value={adresse.ComplementAdresse} onChange={e => modifieAdresse("ComplementAdresse", e.target.value)} />
                            </Col>
                            <Col sm={2}>
                                <strong>Téléphone :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="20" value={adresse.Telephone} onChange={e => modifieAdresse("Telephone", e.target.value)} />
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Code postal :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="7" value={adresse.CodePostal} onChange={e => modifieAdresse("CodePostal", e.target.value)} />
                            </Col>
                            <Col sm={2}>
                                <strong>Ville :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="40" value={adresse.Ville} onChange={e => modifieAdresse("Ville", e.target.value)} />
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Email :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Form.Control type="text" maxLength="50" value={adresse.Email} onChange={e => modifieAdresse("Email", e.target.value)} />
                            </Col>
                            <Col sm={2}>
                                <strong>Pays :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.LibelleFr}
                                    getOptionValue={option => option.CodeIso2}
                                    options={localisations}
                                    value={localisations.find(element => element.CodeIso2 === adresse.CodePays)}
                                    onChange={e => modifieAdresse("localisation", e.CodeIso2)}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>

                    </div>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <sup>1</sup> Contenu libre vous permettant de classer les adresses dans des groupes.
                            </Col>
                        </Row>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={false} variant="default" className="blue" onClick={sauver}>Sauver</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default AdresseEdit;
