import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap"
import AdressesListTable from './AdressesListTable'

const AdressesListModal = ({ visible, fermer, client, idBoutique, setAdresse, natureAdresse }) => {
    const [adresseSelect, setAdresseSelect] = useState({});
    const valider = () => {
        setAdresse(adresseSelect);
        setAdresseSelect({});
        _fermer()
    }
    const _fermer = () => {
        setAdresseSelect({});
        fermer()
    }
    useEffect(() => {
        setAdresseSelect({});
    }, []);

    return (
        <div>
            <Modal show={visible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Adresses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdressesListTable client={client} idBoutique={idBoutique} natureAdresse={natureAdresse} modeSelection={true} setAdresse={setAdresseSelect} />
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={!adresseSelect.IDCARNET_ADRESSE} variant="default" className="blue" onClick={() => { valider() }}>Valider</Button>
                    <Button variant="default" className="blue" onClick={() => { _fermer() }}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default AdressesListModal;