import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button, ToggleButton, ButtonGroup } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import Select from 'react-select'
import { getComposantsArticle } from "../../utils/utils";
import LotsByItem from '../Lots/LotsByItem';
import Alerte from '../Alerte';

const apiRoot = process.env.REACT_APP_API_ROOT

const CommandeLineEdit = ({ isModalVisible, fermer, valider, personnalisations, selectedLine, client }) => {
    const typeAjoutEnum = { Simple: 'Simple', Kit: 'Kit' };
    const [typeAjout, setTypeAjout] = useState(typeAjoutEnum.Simple);
    const [orderLine, setOrderLine] = useState({});
    const [lotVisible, setLotVisible] = useState(false);
    const [lot, setLot] = useState({});
    const [modeModification, setModification] = useState(false);
    const [kitExiste, setKitExiste] = useState(false);
    const [personnalisationsPossible, setPersonnalisationsPossible] = useState([]);
    const [detailArticle, setDetailArticle] = useState({});
    const [articles, setArticles] = useState([]);
    const [composants, setComposants] = useState([]);
    const [gestionDesLots, setGestionDesLots] = useState(false);
    const [alerte, setAlerte] = useState(null);

    useEffect(() => {
        if (!client || parseInt(client) === 0) {
            return;
        }
        setArticles([]);
        setComposants([]);
        setPersonnalisationsPossible([]);
        setGestionDesLots(false);


        if (typeAjout === typeAjoutEnum.Simple) {
            logecomApi.get(apiRoot + 'clients/' + client + '/items/actif_non_kit').then((res) => {
                res.data.forEach(function (v) { v.IDARTICLE = v.Id_Article; delete v.Id_Article; });
                setArticles(checkValidity(res.data));

            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
            logecomApi.get(apiRoot + 'clients/' + client + '/items/kit').then((res) => {
                if (res.data.length > 0) {
                    setKitExiste(true);
                } else {
                    setKitExiste(false);
                }
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        } else if (typeAjout === typeAjoutEnum.Kit) {
            logecomApi.get(apiRoot + 'clients/' + client + '/items/kit').then((res) => {
                res.data.forEach(function (v) { v.IDARTICLE = v.Id_Article; delete v.Id_Article });
                setKitExiste(true);
                setArticles(checkValidity(res.data))
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        }
        setOrderLine({
            Quantite: "0", IDARTICLE: -1, IdCommande: orderLine.IDCOMMANDE,
            IDTYPE_PERSONNALISATION1: 0, CodePersonnalisation1: "", NomTypePersonnalisation1: "",
            IDTYPE_PERSONNALISATION2: 0, CodePersonnalisation2: "", NomTypePersonnalisation2: "",
            IDTYPE_PERSONNALISATION3: 0, CodePersonnalisation3: "", NomTypePersonnalisation3: "",
            InfoPersonnalisation1_1: "", InfoPersonnalisation1_2: "", InfoPersonnalisation1_3: "",
            InfoPersonnalisation2_1: "", InfoPersonnalisation2_2: "", InfoPersonnalisation2_3: "",
            InfoPersonnalisation3_1: "", InfoPersonnalisation3_2: "", InfoPersonnalisation3_3: "",
            TypeArticle: typeAjout, IdLotSouhaite: 0, NumeroDeLot: ""
        })

    }, [client, typeAjout]);

    useEffect(() => {
        if (selectedLine) {
            if (selectedLine.IDARTICLE > 0) {
                selectedLine.modification = true;
                personnalisation_possible(selectedLine.IDARTICLE);
                getDetailArticle(selectedLine);
                setModification(true);
                setGestionDesLots(selectedLine.GestionDesLots);
                getComposantsArticle(selectedLine.IDARTICLE).then((composants) => {
                    setComposants(composants);
                }).catch((error) => {
                    console.log(JSON.stringify(error))
                })

            } else {
                selectedLine.modification = false;
                selectedLine.IdLigneCommande = Math.round(Date.now() / 1000) + Math.floor(Math.random() * 999999);
                setModification(false);
                setTypeAjout(typeAjoutEnum.Simple);
                setPersonnalisationsPossible([]);
                setDetailArticle({});
                setComposants([]);
                setGestionDesLots(selectedLine.GestionDesLots);
            }
            setOrderLine(selectedLine);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLine]);
    const valueIfDefined = (value) => {
        if (value) {
            return value;
        } else {
            return '';
        }
    }

    const getDetailArticle = (article) => {
        if (article.TypeArticle === 'Simple') {
            logecomApi.get(apiRoot + 'items/' + article.IDARTICLE).then((res) => {
                setDetailArticle(checkValidity(res.data[0]))
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        } else {
            setDetailArticle({});
        }

    }

    const _valider = (line) => {
        if (line.IDARTICLE < 1 || line.Quantite < 1) {
            setAlerte("Veuillez choisir un article et renseigner une quantité");
            return;
        }
        let pbPerso = [1, 2, 3].some(num => {
            if (line['IDTYPE_PERSONNALISATION' + num] === 0) {
                line['InfoPersonnalisation' + num + '_1'] = '';
                line['InfoPersonnalisation' + num + '_2'] = '';
                line['InfoPersonnalisation' + num + '_3'] = '';
            }
            line.InfoPersonnalisation1 = infoPerso(line, 1)
            if (line['IDTYPE_PERSONNALISATION' + num] > 0 && line['InfoPersonnalisation' + num + '_1'] + line['InfoPersonnalisation' + num + '_2'] + line['InfoPersonnalisation' + num + '_3'] === '') {
                return true
            }
            else {
                return false
            }
        });
        if (pbPerso) {
            setAlerte("Veuillez SVP renseigner le texte de personnalisation.");
            return;
        } else {
            let tabOrderLines = [];
            line.InfoPersonnalisation1 = valueIfDefined(line.InfoPersonnalisation1_1) + " " + valueIfDefined(line.InfoPersonnalisation1_2) + " " + valueIfDefined(line.InfoPersonnalisation1_3);
            line.InfoPersonnalisation2 = valueIfDefined(line.InfoPersonnalisation2_1) + " " + valueIfDefined(line.InfoPersonnalisation2_2) + " " + valueIfDefined(line.InfoPersonnalisation2_3);
            line.InfoPersonnalisation3 = valueIfDefined(line.InfoPersonnalisation3_1) + " " + valueIfDefined(line.InfoPersonnalisation3_2) + " " + valueIfDefined(line.InfoPersonnalisation3_3);
            line.NomTypePersonnalisation1 = line.IDTYPE_PERSONNALISATION1 > 0 ? personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === line.IDTYPE_PERSONNALISATION1).NomTypePersonnalisation : '';
            line.NomTypePersonnalisation2 = line.IDTYPE_PERSONNALISATION2 > 0 ? personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === line.IDTYPE_PERSONNALISATION2).NomTypePersonnalisation : '';
            line.NomTypePersonnalisation3 = line.IDTYPE_PERSONNALISATION3 > 0 ? personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === line.IDTYPE_PERSONNALISATION3).NomTypePersonnalisation : '';
            tabOrderLines.push(line);
            if (typeAjout === typeAjoutEnum.Kit && !line.modification) {
                composants.forEach(composant => {
                    let lineComp = {
                        Quantite: composant.Quantite * line.Quantite,
                        IDARTICLE: composant.IdArticleFils,
                        IdLigneCommande: Math.round(Date.now() / 1000) + Math.floor(Math.random() * 999999),
                        LibelleArticle1: composant.LibelleArticle1,
                        LibelleArticle2: composant.LibelleArticle2,
                        SKU: composant.CodeSKU,
                        GestionDesLots: composant.GestionDesLots,
                        IdLotSouhaite: 0,
                        NumeroDeLot: '',
                        TypeArticle: typeAjoutEnum.Simple,
                        IdKIT: line.IDARTICLE,
                        IDTYPE_PERSONNALISATION1: 0,
                        IDTYPE_PERSONNALISATION2: 0,
                        IDTYPE_PERSONNALISATION3: 0,
                        PersonnalisationPossible: composant.PersonnalisationPossible,
                        InfoPersonnalisation1: '',
                    };
                    tabOrderLines.push(lineComp);
                });
            }
            valider(tabOrderLines, composants);
            setGestionDesLots(false);
        }
    }

    const infoPerso = (line, indice) => {
        let libelle = '';
        let cle = 'InfoPersonnalisation' + indice;
        ['1', '2', '3'].forEach(x => {
            if (!line[cle + '_' + x] || !line[cle + '_' + x].startsWith('http')) {
                libelle += line[cle + '_' + x] + ' ';
            }
        });
        return libelle.trim().replace(/ /g, '\u00a0');
    }

    const chercherLot = () => {
        setLot({});
        setLotVisible(true);
    }

    const supprimerLot = () => {
        setLot({});
        orderLine.IdLotSouhaite = 0
        orderLine.NumeroDeLot = ""
    }

    const qteEnCommandeSuperieurQteEnStock = (lotSelect) => {
        if (orderLine.Quantite > lotSelect.QuantiteEnStock) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (lot && lot.IDLOT) {
            setOrderLine({
                ...orderLine,
                IdLotSouhaite: lot.IDLOT,
                NumeroDeLot: lot.NumeroDeLot,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lot]);


    const safeEditLine = (key, value) => {
        let tempLine = selectedLine
        tempLine[key] = value
        setOrderLine(tempLine)
        if (key === 'IDARTICLE') {
            orderLine.IdLotSouhaite = 0
            personnalisation_possible(value)
            getComposantsArticle(value).then((composants) => {
                setComposants(composants);
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })

        }
    }

    const checkValidity = res => {
        return res === undefined || res === "" ? [] : res
    }

    const personnalisation_possible = (IDARTICLE) => {
        logecomApi.get(apiRoot + 'items/' + IDARTICLE + '/customizations').then((res) => {
            if (res.data.length > 0) {
                var premier = { IDTYPE_PERSONNALISATION: 0, NomTypePersonnalisation: 'Sans', Statut: 'Actif' };
                res.data = [premier].concat(res.data)
            }
            setPersonnalisationsPossible(checkValidity(res.data))
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }

    const persoDoublons = (line) => {
        let array = [];
        let doublon = false;
        [line.IDTYPE_PERSONNALISATION1, line.IDTYPE_PERSONNALISATION2, line.IDTYPE_PERSONNALISATION3].forEach(id => {
            if (id && array.includes(id)) {
                doublon = true;
            }
            array.push(id);
        });
        return doublon;
    }

    const libelleClientInfo = (idtypePerso, indice) => {
        var perso = personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === idtypePerso);
        if (perso) {
            return perso['LibelleClientInfo' + indice];
        }
        return null;
    }

    const editLine = (key, value) => {
        const intValues = ["Quantite"]
        const intReg = /^\d*$/
        if (intValues.includes(key)) {
            value = value === "" ? 0 : value
            if (!intReg.test(value)) {
                value = orderLine[key]
            }
            else {
                value = parseInt(value).toString()
            }
        }
        let tempLine = JSON.parse(JSON.stringify(orderLine)) // Important, sans copie profonde, React ne déclenchera pas de rafraîchissement
        tempLine[key] = value
        if (key === 'IDTYPE_PERSONNALISATION1') {
            tempLine['LibelleClientInfo1'] = personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === value).LibelleClientInfo1;
            tempLine['LibelleClientInfo2'] = personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === value).LibelleClientInfo2;
            tempLine['LibelleClientInfo3'] = personnalisations.find(elt => elt.IDTYPE_PERSONNALISATION === value).LibelleClientInfo3;
        }
        setOrderLine(tempLine)
    }

    const articlesOptions = articles.map(
        article => ({ value: article.IDARTICLE, label: article.SKU + " " + article.LibelleArticle1 + " " + article.LibelleArticle2 })
    )

    return (
        <>
            <Modal show={isModalVisible} backdrop="static" dialogClassName="modal2" onHide={() => { fermer() }} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        {modeModification ? 'Modification ligne Commande' : 'Ajout ligne Commande'}<br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        {modeModification ?
                            <Row className="mb-2">
                                <Col sm={5}>
                                    <strong>SKU :&nbsp; </strong>
                                    {orderLine.SKU}
                                </Col>
                                <Col sm={7}>
                                    <strong>Libellé :&nbsp; </strong>
                                    {orderLine.LibelleArticle1}
                                </Col>
                            </Row>
                            :
                            <>
                                {kitExiste &&
                                    <Row className="mb-2">
                                        <Col sm={12} style={{ textAlign: 'right' }}>
                                            <ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">
                                                <ToggleButton
                                                    type="checkbox"
                                                    variant="secondary"
                                                    checked={typeAjout === typeAjoutEnum.Simple}
                                                    value="1"
                                                    onChange={(e) => { setTypeAjout(typeAjoutEnum.Simple) }}
                                                >
                                                    Article
                                                </ToggleButton>
                                                <ToggleButton
                                                    type="checkbox"
                                                    variant="secondary"
                                                    checked={typeAjout === typeAjoutEnum.Kit}
                                                    value="1"
                                                    onChange={(e) => { setTypeAjout(typeAjoutEnum.Kit) }}
                                                >
                                                    Kit
                                                </ToggleButton>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                }
                                <Row className="mb-2">
                                    <Col sm={5}>
                                        <strong>{typeAjout === typeAjoutEnum.Simple ? 'Article :' : 'Kit :'}&nbsp; </strong>
                                    </Col>
                                    <Col sm={7}>
                                        <Select
                                            options={articlesOptions}
                                            value={orderLine.IDARTICLE > 0 ? articlesOptions.find(elt => elt.IDARTICLE === orderLine.IDARTICLE) : null}
                                            onChange={
                                                e => {
                                                    const article = articles.find(elt => elt.IDARTICLE === e.value);
                                                    Object.keys(article).forEach(elt => safeEditLine(elt, article[elt]));
                                                    safeEditLine("IDARTICLE", e.value)
                                                    setGestionDesLots(article.GestionDesLots)
                                                    getDetailArticle(orderLine)
                                                }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </>

                        }
                        {(detailArticle && detailArticle.Id_Article > 0) &&
                            <div>
                                <Row className="mb-2">
                                    <Col sm={5}>
                                        <strong>Quantité en stock :&nbsp;</strong>
                                        {detailArticle.QuantiteEnStock}
                                    </Col>
                                    <Col sm={4}>
                                        <strong>Quantité à expédier :&nbsp;</strong>
                                        {detailArticle.QteAEnvoyer}
                                    </Col>
                                    <Col sm={3}>
                                        <strong>Quantité par palette :&nbsp;</strong>
                                        {detailArticle.QuantiteParPalette}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={5}>
                                        <strong>Quantité à recevoir :&nbsp;</strong>
                                        {detailArticle.QuantiteARecevoir}
                                    </Col>
                                    <Col sm={7}>
                                        <strong>Quantité prévisionnelle disponible :&nbsp;</strong>
                                        {detailArticle.QuantitePrevisionnelleDisponible}
                                    </Col>
                                </Row>
                            </div>
                        }
                        {composants.map(composant => {
                            return (
                                <Row className="mb-2">
                                    <Col sm={9}>
                                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;{"Composant " + composant.NumComposant + " : "}&nbsp;</strong>
                                        {composant.CodeSKU + ' ' + composant.LibelleArticle1 + ' ' + composant.LibelleArticle2}
                                    </Col>
                                    <Col sm={3}>
                                        <strong>Qté :&nbsp;</strong>
                                        {composant.Quantite}
                                    </Col>
                                </Row>
                            )
                        }
                        )}
                        {(gestionDesLots && ((!modeModification && composants.length === 0) || modeModification)) &&
                            <Row className="mb-2">
                                <Col sm={5}>
                                    <strong>Lot souhaité (facultatif) :&nbsp; </strong>
                                </Col>
                                <Col sm={7} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ marginRight: '5px' }}>
                                        {orderLine.IdLotSouhaite === 0 ? "Pas de préférence" : orderLine.NumeroDeLot}
                                    </div>
                                    <div className="btn-group">
                                        <button type="button" title="Modifier" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={chercherLot}>
                                            <i className="ion ion-md-create d-block"></i>
                                        </button>
                                        <button type="button" title="Supprimer" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={supprimerLot}>
                                            <i className="ion ion-md-trash d-block"></i>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        }

                    </div>
                    {(!orderLine.IdKIT || orderLine.IdKIT === 0) &&
                        <div className="section-form">
                            <Row className="mb-2">
                                <Col sm={5}>
                                    <strong>Quantité :&nbsp; </strong>
                                </Col>
                                <Col sm={7}>
                                    <Form.Control type="text" onChange={e => editLine("Quantite", e.target.value)} value={orderLine.Quantite} />
                                </Col>
                            </Row>
                        </div>
                    }

                    {[1, 2, 3].map(num => (
                        personnalisationsPossible.length && (num === 1 || orderLine['IDTYPE_PERSONNALISATION' + num] || orderLine['IDTYPE_PERSONNALISATION' + (num - 1)]) ?
                            <div className="section-form">
                                <Row className="mb-2">
                                    <Col sm={5}>
                                        <strong>{'Personnalisation ' + num}</strong>
                                    </Col>
                                    <Col sm={7}>
                                        <Select
                                            defaultValue={
                                                modeModification ?
                                                    personnalisationsPossible.filter(elt => elt.IDTYPE_PERSONNALISATION === orderLine["IDTYPE_PERSONNALISATION" + num]).map(res => { return { value: res.IDTYPE_PERSONNALISATION, label: res.NomTypePersonnalisation } })
                                                    : { value: 0, label: 'Sans' }
                                            }
                                            options={personnalisationsPossible.map(elt => ({ label: elt.NomTypePersonnalisation, value: elt.IDTYPE_PERSONNALISATION }))}
                                            onChange={e => { editLine("IDTYPE_PERSONNALISATION" + num, e.value); }}
                                        />
                                    </Col>
                                </Row>
                                {orderLine['IDTYPE_PERSONNALISATION' + num] > 0 &&
                                    <>
                                        {libelleClientInfo(orderLine['IDTYPE_PERSONNALISATION' + num], 1) &&
                                            <Row className="mb-2">
                                                <Col sm={5}>
                                                    <strong>{libelleClientInfo(orderLine['IDTYPE_PERSONNALISATION' + num], 1)}</strong>
                                                </Col>
                                                <Col sm={7}>
                                                    <Form.Control maxLength="250" type="text" defaultValue={orderLine["InfoPersonnalisation" + num + "_1"]} onChange={e => editLine("InfoPersonnalisation" + num + "_1", e.target.value)} />
                                                </Col>
                                            </Row>
                                        }
                                        {libelleClientInfo(orderLine['IDTYPE_PERSONNALISATION' + num], 2) &&
                                            <Row className="mb-2">
                                                <Col sm={5}>
                                                    <strong>{libelleClientInfo(orderLine['IDTYPE_PERSONNALISATION' + num], 2)}</strong>
                                                </Col>
                                                <Col sm={7}>
                                                    <Form.Control maxLength="250" type="text" defaultValue={orderLine["InfoPersonnalisation" + num + "_2"]} onChange={e => editLine("InfoPersonnalisation" + num + "_2", e.target.value)} />
                                                </Col>
                                            </Row>
                                        }
                                        {libelleClientInfo(orderLine['IDTYPE_PERSONNALISATION' + num], 3) &&
                                            <Row className="mb-2">
                                                <Col sm={5}>
                                                    <strong>{libelleClientInfo(orderLine['IDTYPE_PERSONNALISATION' + num], 3)}</strong>
                                                </Col>
                                                <Col sm={7}>
                                                    <Form.Control maxLength="250" type="text" defaultValue={orderLine["InfoPersonnalisation" + num + "_3"]} onChange={e => editLine("InfoPersonnalisation" + num + "_3", e.target.value)} />
                                                </Col>
                                            </Row>
                                        }
                                    </>}
                            </div> : null
                    ))}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div>
                            {qteEnCommandeSuperieurQteEnStock(lot) && <strong style={{ color: 'red' }}>Attention : La quantité commandée est supérieure à la quantité en stock du lot souhaité</strong>}
                        </div>
                        <div>
                            {persoDoublons(orderLine) && <strong style={{ color: 'red' }}>Attention : La même personnalisation est présente plusieurs fois</strong>}
                        </div>
                    </div>
                    <div>
                        <Button variant="default" className="blue" style={{ marginRight: '5px' }} onClick={() => { _valider(orderLine) }}>Valider</Button>
                        <Button variant="default" className="blue" onClick={() => { fermer() }}>Fermer</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <LotsByItem
                isModalVisible={lotVisible}
                fermer={() => setLotVisible(false)}
                client={client}
                articleId={orderLine.IDARTICLE}
                modeSelection={true}
                titre={' de l\'article : ' + orderLine.SKU + " - " + orderLine.LibelleArticle1}
                setLot={(lot) => {
                    setLot(lot);
                }}
                qteCommandee={orderLine.Quantite}
            />

            <Alerte
                isModalVisible={alerte}
                handleAlerte={() => setAlerte(null)}
                message={alerte}
            />
        </>
    )
}

export default CommandeLineEdit; 