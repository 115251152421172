import React from 'react';
import { Modal, Button } from "react-bootstrap"
import ReceptionsListTable from './ReceptionsListTable';

const ReceptionByLot = ({ isModalVisible, fermer, client, lotId, titre }) => {
   
    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Liste des réceptions {titre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReceptionsListTable client={client} lotId={lotId} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={() => { fermer() }}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ReceptionByLot;