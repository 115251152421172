import Header from './Header';
import Footer from './Footer'

function Layout(props) {
    if (window.location.pathname === '/login' || window.location.pathname.includes('/resetpassword')) {
        return null;
    }
    return (
        <div>
            <Header />
            <div className="content">
                {props.children}
            </div>
            <Footer />
        </div>
    );
}
export default Layout;
