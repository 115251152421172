import React, { useEffect, useState } from 'react';
import { Col, Row, ButtonGroup, Button, ToggleButton } from "react-bootstrap"
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import Alerte from '../Alerte';
import SavEdit from './SavEdit';
import DiscussionFiche from '../Discussion/DiscussionFiche';
import DocumentList from '../Document/DocumentList';
import logecomApi from "../../utils/axiosInstance";
import { labelDureeJMA, downloadCSV, patchFilterFactory } from "../../utils/utils";
import { useLocation } from 'react-router-dom';
const apiRoot = process.env.REACT_APP_API_ROOT

const SavListTable = ({ client, idElementRattache, titre, idSav }) => {
    const showButtonAdd = (idElementRattache > 0);
    const [titreCommande, setTitreCommande] = useState(titre);
    const [exportData, setExportData] = useState([]);
    const [duration, setDuration] = useState(30);
    const [rowSelected, setRowSelected] = useState({});
    const [messageAlerte, setMessageAlerte] = useState(null);
    const [editId, setEditId] = useState(null);
    const [idCommande, setIdCommande] = useState(null);
    const [documentSav, setDocumentSav] = useState({});
    const [listeSav, setListeSav] = useState([]);
    //TODO lien externe
    //const [selectedRows, setSelectedRows] = useState([]);
    //const tableRef = useRef(null);

    const statutsDiscussionSAV = {
        TOUTES: "0",
        NONLUES: "2"
    }
    let paramsLink = useLocation();
    let filtreParams = paramsLink.state === undefined ? undefined : paramsLink.state.filtre;
    const [statutDiscussionSAV, setStatutDiscussionSAV] = useState(filtreParams ? filtreParams : statutsDiscussionSAV.TOUTES);
    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })
    const filteredData = () => {
        if (listeSav === undefined || listeSav === "" || JSON.stringify(listeSav) === "{}") {
            return [];
        } else {
            return listeSav.filter(function (row) {
                if (statutDiscussionSAV === statutsDiscussionSAV.NONLUES) {
                    return row.nbMessagesNonLu > 0;
                }
                //TODO lien externe
                /*if(idSav > 0) {
                    return row.IDSAV === parseInt(idSav);
                }*/
                return true;
            })
        }
    }
    const headerWidth = (width) => (colum, colIndex) => {
        if (!idElementRattache && rowSelected.idDiscussion > 0) {
            return null;
        } else {
            return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
        }
    }
    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    const afficherMessageAlerte = (afficher, titre, message) => {
        if (afficher === true) {
            setMessageAlerte({ 'titre': titre, 'message': message });
        } else {
            setMessageAlerte(null);
        }

    }

    const handleDocumentList = (idsav, libelle, dateSav) => {
        setDocumentSav({ IDSAV: idsav, libelle: libelle, dateSav: dateSav });
    };

    useEffect(() => {
        localStorage.setItem("page", "sav")
        getListeSav();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, idElementRattache, duration, editId]);

    function LibPaginator() {
        return <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
            {[14, 30, 60, 90, 180, 365].map((n) => { return duration === n | duration === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)} </label> }
            )}
        </div>

    }

    const selectRow = () => {
        return {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            hideSelectAll: true,
            //TODO lien externe
            //selected: selectedRows,
            bgColor: idElementRattache ? '' : '#1901A0',
            style: idElementRattache ? { color: '' } : { color: 'white' },
            onSelect: (row, isSelect, rowIndex, e) => {
                if (!idElementRattache) {
                    //TODO lien externe
                    //setSelectedRows([row.IDSAV]);
                    setRowSelected(
                        {
                            idDiscussion: row.IDDISCUSSION,
                            elementRattache: 'SAV',
                            idElementRattache: row.IDSAV,
                            titre: 'Sav du ' + row.CreeLe
                        }
                    );
                }
            }
        }
    };

    function getListeSav() {
        if (!client || parseInt(client) === 0) {
            return;
        }
        var url = apiRoot + 'clients/' + client;
        var idCmd = (idElementRattache ? idElementRattache : 0);
        var duree = 0;
        if (idCmd > 0 || idSav) {
            duree = 3650;
        } else {
            duree = duration;
        }
        url = url + '/sav?duree=' + duree + '&IdCommande=' + idCmd + '&IdSav=' + idSav;
        logecomApi.get(url).then((res) => {
            setListeSav(res.data);
            //TODO lien externe
            /*
           if (idSav > 0) {
                const firstRow = res.data.find((sav) => sav.IDSAV === parseInt(idSav));
                setSelectedRows([firstRow.IDSAV]); // Sélectionne la première ligne
                const rowIndex = 0;
                const rowSelected = tableRef.current.props.data[rowIndex];
                setRowSelected(
                    {
                        idDiscussion: rowSelected.IDDISCUSSION,
                        elementRattache: 'SAV',
                        idElementRattache: rowSelected.IDSAV,
                        titre: 'Sav du ' + rowSelected.CreeLe
                    }
                );
            }*/
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }
    const statutSAVOptions = [
        //{ value: '0', label: "" },
        { value: '1', label: "Ouvert" },
        { value: '2', label: "Attente d'instruction" },
        { value: '3', label: "Litige transporteur en cours" },
        { value: '4', label: "Clôturé" },
        { value: '5', label: "Annulé" },
        { value: '6', label: "Clôturé non reçu" },
    ];
    const motifSAVOptions = [
        { value: '1', label: "N'habite pas à l'adresse indiquée" },
        { value: '2', label: "Non réclamé" },
        { value: '3', label: "Détérioré" },
        { value: '4', label: "Refus colis" },
        { value: '5', label: "Retour client" },
        { value: '6', label: "Erreur adresse expédition" },
        { value: '7', label: "Erreur GD Logistique" },
        { value: '8', label: "Colis perdu" },
        { value: '9', label: "Produit défectueux" },
        { value: '10', label: "Erreur Transporteur" },
        { value: '11', label: "A définir" },
    ];
    const decisionSAVOptions = [
        { value: '1', label: "Remettre en stock" },
        { value: '2', label: "A réexpédier" },
        { value: '3', label: "Voir discussion" },
    ];
    const columns =
        [
            ["", "ICONS", 42, false],
            ["", "IDSAV", 0, false],
            ["Créé le", "CreeLe", 140, true],
            ["Créé par", "CreePar", 140, true],
            ["Commande", "LibelleCommande", 150, true],
            ["Colis reçu le", "DateReception", 80, true],
            ["Commentaire", "CommentaireSAV", 0, true],
            ["", "IDCLIENT", 0, false],
            ["", "NePasFacturer", 0, false],
            ["", "PrixGestion", 0, false],
            ["Statut", "Statut", 100, true],
            ["Date traitement", "DateTraitementClient", 0, true],
            ["Décision client", "Decision", 90, true],
            // ["Société", "Societe", 0, true],
            // ["Nom", "Nom", 120, true],
            //["Adresse", "Adresse", 140, true],
            //["Complément adresse", "ComplAdresse", 0, true],
            ["Code postal", "CodePostal", 60, true],
            ["Ville", "Ville", 100, true],
            ["Commentaire client", "CommentaireClient", 120, true],
            ["", "CodePays", 0, false],
            ["Pays", "Pays", 0, true],
            ["", "IDCOMMANDE", 0, false],
            ["", "ErreurGD", 0, false],
            ["", "IdFactureSAV", 0, false],
            ["", "IDTRANSPORTEUR", 0, false],
            ["Motif", "Motif", 0, true],
            ["Modifié le", "ModifieLe", 0, true],
            ["Modifié par", "ModifiePar", 0, true],
            ["Numéro suivi", "NumTrackingRetour", 0, true],
            ["Date expédition", "DateExpedition", 0, true]
        ]
            .map(elt => {
                var width = elt[2];
                let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(width), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;
                if (width === 0) {
                    result.hidden = true;
                }
                if (result.dataField === 'ICONS') {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = (_, row) => {
                        let coulBtnDiscussion = '#fff';
                        let titleDiscussion = 'Discussion fermée';
                        if (row.nbMessagesNonLu > 0) {
                            coulBtnDiscussion = 'red';
                            titleDiscussion = 'Discussion avec des messages non lus';
                        } else if (row.nbDiscussionOuverte > 0) {
                            coulBtnDiscussion = 'green';
                            titleDiscussion = 'Discussion ouverte';
                        }
                        return (
                            <div className="btn-group">
                                <button type="button" title="Modifier" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { setEditId(row.IDSAV); setIdCommande(row.IDCOMMANDE); setTitreCommande(row.LibelleCommande !== '' ? 'Commande ' + row.LibelleCommande : 'Commande inconnue') }}>
                                    <i className="ion ion-md-create d-block"></i>
                                </button>
                                {row.docExiste === 1 ?
                                    <button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} title='Documents rattachés' onClick={() => handleDocumentList(row.IDSAV, row.LibelleCommande !== '' ? 'SAV de la commande ' + row.LibelleCommande : 'SAV commande inconnue', row.CreeLe)}>
                                        <i className="ion ion-md-document d-block"></i>
                                    </button> : null}
                                {row.IDDISCUSSION > 0 ?
                                    <button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px', color: coulBtnDiscussion }} title={titleDiscussion} onClick={() => { }}>
                                        <i className="ion ion-md-chatbubbles d-block"></i>
                                    </button> : null}
                            </div>
                        )
                    }
                    result.sort = false
                    result.filter = undefined

                } else if (result.dataField === 'Statut') {
                    result.formatter = cell => statutSAVOptions.find(statut => statut.value === cell.toString()).label;
                    result.filter = selectFilter({
                        options: statutSAVOptions,
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })

                } else if (result.dataField === 'Decision') {
                    result.formatter = (cell) => {
                        if (cell === 0) {
                            return ('')
                        } else {
                            return (decisionSAVOptions.find(decision => decision.value === cell.toString()).label)
                        }
                    };
                    result.filter = selectFilter({
                        options: decisionSAVOptions,
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })
                } else if (result.dataField === 'CommentaireClient') {
                    if (!idElementRattache && rowSelected.idDiscussion > 0) {
                        result.hidden = true;
                    }
                }

                return result;
            })

    return (
        <>

            <ToolkitProvider
                keyField="IDSAV"
                data={filteredData()}
                columns={columns}
                bootstrap4
                search
                columnToggle
            >
                {props => (
                    <div>
                        <Row className="align-items-center" style={{ marginBottom: '20px' }}>
                            <Col>{!idElementRattache && <span className="titre-section font-weight-bold">SAV</span>}
                                <div className="header-controles">
                                    {showButtonAdd && <button type="button" className="btn btn-md btn-primary" style={{ marginLeft: '5px', height: '38px' }} onClick={() => { setEditId(0); setIdCommande(idElementRattache) }}>
                                        <i className="ion ion-md-add-circle d-block"></i>
                                    </button>}
                                    {!idElementRattache && <LibPaginator />}
                                    <Button variant="default" className="blue" style={{ marginLeft: '5px' }} onClick={() => { downloadCSV(exportData, columns, 'SAV') }}>Export CSV</Button>

                                    {!idElementRattache && <ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={statutDiscussionSAV === statutsDiscussionSAV.TOUTES}
                                            value="1"
                                            onChange={(e) => setStatutDiscussionSAV(statutsDiscussionSAV.TOUTES)}
                                        >
                                            Toutes
                                        </ToggleButton>
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={statutDiscussionSAV === statutsDiscussionSAV.NONLUES}
                                            value="1"
                                            onChange={(e) => setStatutDiscussionSAV(statutsDiscussionSAV.NONLUES)}
                                        >
                                            Non lues
                                        </ToggleButton>
                                    </ButtonGroup>}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={!idElementRattache && rowSelected.idDiscussion > 0 ? 8 : 12}>
                                <BootstrapTable
                                    {...props.baseProps}
                                    rowStyle={rowStyle}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 20 })}
                                    filter={myTableFilterFactory()}
                                    selectRow={selectRow()}
                                    //TODO lien externe
                                    //ref={tableRef}
                                    id='SavListTable'
                                />
                            </Col>
                            {!idElementRattache && rowSelected.idDiscussion > 0 ?
                                <Col sm={!idElementRattache && rowSelected.idDiscussion > 0 ? 4 : 0}>
                                    <DiscussionFiche
                                        elementRattache={rowSelected.elementRattache}
                                        idElementRattache={rowSelected.idElementRattache}
                                        idDiscussion={rowSelected.idDiscussion}
                                        client={client}
                                        titre={rowSelected.titre}
                                    >
                                    </DiscussionFiche>
                                </Col> : null}
                        </Row>
                    </div>
                )}
            </ToolkitProvider>
            <Alerte
                isModalVisible={messageAlerte !== null}
                handleAlerte={afficherMessageAlerte}
                titre={messageAlerte ? messageAlerte.titre : null}
                message={messageAlerte ? messageAlerte.message : null}
            >
            </Alerte>
            <SavEdit
                editId={editId}
                setEditId={(editId) => setEditId(editId)}
                idCommande={idCommande}
                client={client}
                statutSAVOptions={statutSAVOptions}
                motifSAVOptions={motifSAVOptions}
                decisionSAVOptions={decisionSAVOptions}
                titre={titreCommande}
                fermer={() => setEditId(null)}
            >
            </SavEdit>
            <DocumentList
                isModalVisible={documentSav.IDSAV}
                elementRattache='Sav'
                idElementRattache={documentSav.IDSAV}
                client={client}
                titre={documentSav.libelle !== '' ? 'SAV de la commande ' + documentSav.libelle : 'SAV du ' + documentSav.CreeLe}
                handleDocumentList={() => setDocumentSav({})}
            />
        </>
    )
}

export default SavListTable;