import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import logecomApi from "../../utils/axiosInstance";
import { patchFilterFactory } from "../../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const SavLinesListTable = ({ idSav, idCommande }) => {
    const [savLines, setSavLines] = useState([]);

    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {

    })
    const filteredData = () => {
        return savLines;
    }
    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };

    useEffect(() => {
        if (!Number.isInteger(idSav)) {
            return;
        }
        localStorage.setItem("page", "sav")
        getListeSavLine();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSav]);

    function getListeSavLine() {
        var url = apiRoot + 'sav/' + idSav + '/lines?idcommande=' + idCommande;
        logecomApi.get(url).then((res) => {
            res.data.forEach(sav => {
                sav.LibelleArticle = sav.LibelleArticle1 + (sav.LibelleArticle2 !== '' ? " - " + sav.LibelleArticle2 : '');
                sav.LibelleLigneAvecPerso = sav.LigneAvecPerso ? "Oui" : "";
            });

            setSavLines(res.data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }
    const columns =
        [
            ["", "GUID", 0, false],
            ["", "IDARTICLE", 0, false],
            ["SKU", "CodeSKU", 60, true],
            ["Libellé", "LibelleArticle", 100, true],
            ["Avec Perso.", "LibelleLigneAvecPerso", 30, true],
            ["Qte expédiée", "QuantiteExpediee", 30, true],
            ["Qte reçue", "SAVQteRecue", 30, true],
            ["Qte remise en stock", "SAVQteTotalRemisStock", 30, true],
            ["Qte mise au rebut", "SAVQteMiseRebut", 30, true],
            ["Commentaire", "SAVCommentaire", 100, true],
            ["", "TypePerso1", 0, true],
            ["", "InfoPerso1_1", 0, true],
            ["", "InfoPerso1_2", 0, true],
            ["", "InfoPerso1_3", 0, true],
            ["", "TypePerso2", 0, true],
            ["", "InfoPerso2_1", 0, true],
            ["", "InfoPerso2_2", 0, true],
            ["", "InfoPerso2_3", 0, true],
            ["", "TypePerso3", 0, true],
            ["", "InfoPerso3_1", 0, true],
            ["", "InfoPerso3_2", 0, true],
            ["", "InfoPerso3_3", 0, true],
            ["", "TypeArticle", 0, true],
            ["", "IDLIGNE_SAV", 0, true],
            ["", "CodeBarrePicking", 0, true],
        ]
            .map(elt => {
                var width = elt[2];
                let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(width), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;
                if (width === 0) {
                    result.hidden = true;
                }
                return result;
            })

    return (
        <>

            <ToolkitProvider
                keyField="GUID"
                data={filteredData()}
                columns={columns}
                bootstrap4
                search
                columnToggle
            >
                {props => (
                    <div>
                        <Row>
                            <Col>
                                <BootstrapTable
                                    {...props.baseProps}
                                    rowStyle={rowStyle}
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 20 })}
                                    filter={myTableFilterFactory()}
                                    id='SavLineListTable'
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </ToolkitProvider>
        </>
    )
}

export default SavLinesListTable;