import React from 'react';
import { Helmet } from 'react-helmet';
import SavListTable from './SavListTable';
import { useLocation } from "react-router-dom";

const SavPage = (props) => {
    let paramsLink = useLocation();
    const savSearch = new URLSearchParams(paramsLink.search).get('id');
    const idSav = savSearch ? savSearch : 0;
    return (
        <React.Fragment>
            <Helmet><title>LOGeCOM - SAV</title>

            </Helmet>
            <div className="layout-wrapper layout-1 layout-without-sidenav">
                <div className="layout-inner">

                    <div className="layout-container">
                        <div className="layout-content">

                            <div className="container flex-grow-1 mycontainer-p-y unbox">
                                <div style={{ marginBottom: '40px' }}>
                                    <SavListTable client={props.client} idSav={parseInt(idSav)}/>
                                </div>
                                <span>Pour créer un nouveau SAV, ouvrir la commande concernée puis bouton "SAV" et bouton "+"</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default SavPage;