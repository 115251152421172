import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT;

const DiscussionAdd = ({ isModalVisible, handleDiscussionAdd, elementRattache, idElementRattache, client, titre, messageDebut }) => {
    const [sujet, setSujet] = useState("");
    const [message, setMessage] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [textError, setTextError] = useState(null);
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const close = (annuler) => {
        setTextError(null);
        setSujet("");
        setMessage("");
        handleDiscussionAdd(annuler);
    }

    useEffect(() => {
        let valid = sujet !== '' && message !== '' && textError === null;
        setFormValid(valid);
    }, [message, sujet, textError]);

    function ajouterDiscussion() {
        if (actionWsEnCours) {
            return;
        }
        setActionWsEnCoursEnCours(true);
        const data = {
            Entete: {
                Sujet: sujet, elementRattache: elementRattache, Statut: 1, idElementRattache: idElementRattache, idclient: client
            },
            Lignes: [
                { corps: message }
            ]
        }
        if (messageDebut) {
            data.Lignes.unshift({ corps: messageDebut })
        }
        logecomApi.post(apiRoot + 'discussions', data).then(() => {
            setActionWsEnCoursEnCours(false);
            close(false);
        }).catch((error) => {
            setActionWsEnCoursEnCours(false);
            alert(error.response.data.fault.detail);
        })
    }

    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={close} idElementRattache={idElementRattache} centered size="xm">
                <Modal.Header>
                    <Modal.Title>Ajouter une discussion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Concerne :&nbsp;
                                </strong>
                                {titre}
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row>
                            <Col sm={3}>
                                <Form.Label >
                                    <strong>Sujet :&nbsp; </strong>
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Form.Control type="text" maxLength="80" id="libelle" value={sujet} onChange={e => setSujet(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Form.Label >
                                    <strong>Message :&nbsp; </strong>
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Form.Control as="textarea" rows="3" maxLength="4000" defaultValue={message} onChange={e => setMessage(e.target.value)} />
                            </Col>
                        </Row>
                        {textError && <Row className="mb-2">
                            <Col sm={12}>
                                <strong style={{ color: 'red' }}>{textError}</strong>
                            </Col>

                        </Row>}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={!formValid} variant="default" className="blue" onClick={ajouterDiscussion}>Ajouter</Button>
                    <Button variant="default" className="blue" onClick={e => close(true)}>Annuler</Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default DiscussionAdd;
