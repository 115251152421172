import React, { } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"
import DiscussionListTable from './DiscussionListTable';

const DiscussionModal = ({ isModalVisible, fermerDiscussion, elementRattache, idElementRattache, client, titre }) => {
    return (
        <div>
            <Modal show={isModalVisible} backdrop="static" onHide={fermerDiscussion} centered size="xl2">
                <Modal.Header closeButton>
                    <Modal.Title>Discussions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Concernant :&nbsp;
                                </strong>
                                {titre}
                            </Col>
                        </Row>
                    </div>

                    <DiscussionListTable
                        client={client}
                        showButtonAdd={true}
                        elementRattache={elementRattache}
                        idElementRattache={idElementRattache}
                        titre={titre}
                    ></DiscussionListTable>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={fermerDiscussion}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default DiscussionModal;