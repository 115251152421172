import logecomApi from "./axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT

export const getCommande = async (id) => {
    try {
        let result = await logecomApi.get(apiRoot + 'orders/' + id).then((res) => {
            return res.data;
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
        return result[0];
    } catch (e) {
        console.log('error', e);
    }
}

export const getElement = async (element, id) => {
    try {
        switch (element) {
            case 'Order': return await getCommande(id);
            default: break;
        }
    } catch (e) {
        console.log('error', e);
    }
}

export const getLibelleElement = async (element, id) => {
    try {
        let result = await getElement(element, id);
        let libelle = '';
        switch (element) {
            case 'Order': libelle = 'Commande n°' + result.NumCommandeClient + ' du ' + result.DateCommande; break;
            default: break;
        }
        return libelle;
    } catch (e) {
        console.log('error', e);
    }
}

const parseDate = sdate => {
    if (sdate === "" || sdate === undefined || sdate === null)
        return [0, 0, 0, 0, 0]
    var regExDate = /^\d{2}\/\d{2}\/\d{4}$/
    var regExDateHeure = /^\d{2}\/\d{2}\/\d{4} à \d{2}:\d{2}$/
    const dateArray = sdate.split(/ à |:|\//)
    var day = 0;
    var month = 0;
    var year = 0;
    var hours = 0;
    var minutes = 0;
    if (sdate.match(regExDate)) {
        day = dateArray[0];
        month = dateArray[1];
        year = dateArray[2];
    } else if (sdate.match(regExDateHeure)) {
        day = dateArray[0];
        month = dateArray[1];
        year = dateArray[2];
        hours = dateArray[3];
        minutes = dateArray[4];
    }
    return [year, month, day, hours, minutes]
}

export const dateSort = (a, b, order) => {
    a = parseDate(a)
    b = parseDate(b)
    if (order === 'asc') {
        for (let i = 0; i < 5; i++) {
            if (a[i] > b[i])
                return 1
            if (a[i] < b[i])
                return -1
        }
        return 0;
    }
    for (let i = 0; i < 5; i++) {
        if (a[i] > b[i])
            return -1
        if (a[i] < b[i])
            return 1
    }
    return 0;
}

export const dateVersChaine = (pdate, sep) => {
    var dd = String(pdate.getDate()).padStart(2, '0');
    var mm = String(pdate.getMonth() + 1).padStart(2, '0');
    var yyyy = pdate.getFullYear();

    return dd + sep + mm + sep + yyyy;
}

export const dateIso = (pdate) => {
    var dd = String(pdate.getDate()).padStart(2, '0');
    var mm = String(pdate.getMonth() + 1).padStart(2, '0');
    var yyyy = pdate.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
}

const preparerCSV = (data, columns, titre, idclient) => {
    let _data = data.map(function (obj) {
        switch (titre) {
            case 'Adresses':
                obj['NatureAdresse'] = obj['NatureAdresse'] === 1 ? 'Client' : 'Fournisseur'
                obj['TypeGestion'] = obj['TypeGestion'] === 1 ? 'B2B' : 'B2C'
                break;
            case 'Articles':
                let codebarre = obj['CodeBarrePicking']
                if (codebarre.startsWith(idclient.toString().padStart(4, '0') + '-')) {
                    codebarre = '';
                }
                obj['CodeBarrePicking'] = codebarre;
                obj['Code_Gestion_Article'] = obj['Gestion_Article'];
                break;
            case 'Discussions':
                obj['Statut'] = obj['LibelleStatut'];
                break;
            case 'Commandes':
                break;
            case 'TarifConsommables':
                obj['PrixVente'] = toCommaEuro(obj['PrixVente']);
                obj['PrixPicking'] = toCommaEuro(obj['PrixPicking']);
                obj['PrixTotal'] = toCommaEuro(obj['PrixTotal']);
                break;
            case 'TarifsTransporteurs':
                let modif = false;
                Object.keys(obj).forEach(key => {
                    if (modif) {
                        obj[key] = toCommaEuro(obj[key]);
                    } else if (key === 'PrixAchat') {
                        modif = true;
                    }
                });
                break;
            case 'Lots':
                var statuts = [
                    { value: 1, label: 'Bloqué' },
                    { value: 2, label: 'Expiré' },
                    { value: 4, label: 'Épuisé' },
                    { value: 3, label: 'A détruire' },
                    { value: 10, label: 'A expédier sans priorité' },
                    { value: 20, label: 'A expédier priorité 1' },
                    { value: 19, label: 'A expédier priorité 2' },
                    { value: 18, label: 'A expédier priorité 3' },
                    { value: 17, label: 'A expédier priorité 4' },
                    { value: 16, label: 'A expédier priorité 5' },
                    { value: 15, label: 'A expédier priorité 6' },
                    { value: 14, label: 'A expédier priorité 7' },
                    { value: 13, label: 'A expédier priorité 8' },
                    { value: 12, label: 'A expédier priorité 9' },
                    { value: 11, label: 'A expédier priorité 10' }
                ];
                obj['Statut'] = statuts.find(statut => statut.value === obj['Statut']).label;

                break;
            default:
                break;
        }
        const newObj = {};
        columns.forEach(function (col) {
            if (col.exporter) {
                newObj[col.text] = obj[col.dataField];
            }
        });
        return newObj;
    });
    return _data;
}
const toCommaEuro = (val) => {
    val = val.toString();
    val = val.replace(".", ",") + '€';
    return val;
}

export const downloadCSV = (p_data, columns, titre, idclient) => {
    if (p_data.length === 0) {
        return;
    }
    var data = JSON.parse(JSON.stringify(p_data));
    var items = preparerCSV(data, columns, titre, idclient);
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(header.join(';'));
    csv = csv.join('\r\n');

    //Download the file as CSV
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = titre + '-' + dateVersChaine(new Date(), '-') + ".csv";  //Name the file here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

}

export const getPdfCoutCommande = (idcommande, numCommandeClient, dateCommande) => {
    logecomApi({
        url: apiRoot + 'orders/' + idcommande + '/cout',
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let _date = dateCommande.substring(0, 10).replace(/\//g, '-');
        link.setAttribute('download', 'Coût-commande-' + numCommandeClient + '-' + _date + ".pdf");
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        console.log(JSON.stringify(error))
    });
};

export const getPdfCoutReception = (idreceipt, dateReceipt) => {
    logecomApi({
        url: apiRoot + 'receipts/' + idreceipt + '/cout',
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let _date = dateReceipt.substring(0, 10).replace(/\//g, '-');
        link.setAttribute('download', 'Coût-réception-' + _date + ".pdf");
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        console.log(JSON.stringify(error))
    });
};

export const patchFilterFactory = (filterFactory, onFilteredData) => {
    return (...args) => {
        const { createContext, options } = filterFactory(...args)
        return {
            createContext: (...args) => {
                const { Provider: BaseProvider, Consumer } = createContext(...args)
                const Provider = class FilterProvider extends BaseProvider {
                    componentDidUpdate() {
                        onFilteredData(this.data)
                    }
                }
                return { Provider, Consumer }
            },
            options
        }
    }
}

const checkValidity = res => {
    return res === undefined || res === "" || res === {} ? [] : res
}

export const nullOrEmpty = item => {
    if (item == null) {
        return true;
    } else if (Array.isArray(item)) {
        return item.length === 0;
    } else if (typeof item === 'object') {
        return Object.keys(item).length === 0
    } else {
        return item === 0
    }
}

export const getComposantsArticle = async (idarticle) => {
    return await logecomApi.get(apiRoot + 'items/' + idarticle + '/components').then((res) => {
        let checkedData = checkValidity(res.data)
        return checkedData;
    }).catch((error) => {
        console.log(JSON.stringify(error));
        throw new Error(error);
    })
}

export const labelDureeJMA = (duree) => {
    var label = '';
    switch (duree) {
        case 7:
        case 14:
        case 60:
        case 90:
            label = duree + ' j';
            break;
        case 30:
            label = '1 mois';
            break;
        case 180:
            label = '6 mois';
            break;
        case 365:
            label = '1 an';
            break;
        default:
            label = duree + ' j';
            break;
    }
    return label;
}

export const generateGuid = () => { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}