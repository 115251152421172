import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"
import DocumentAdd from './DocumentAdd';
import DocumentListTable from './DocumentListTable';
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT

const DocumentList = ({ isModalVisible, handleDocumentList, elementRattache, idElementRattache, client, titre }) => {
    const [documents, setDocuments] = useState([]);
    const [natureOptions, setNatureOptions] = useState([]);
    const [natureOptionsFiltered, setNatureOptionsFiltered] = useState([]);
    const [natureOptionsAdd, setNatureOptionsAdd] = useState([]);
    const [documentAdd, setDocumentAdd] = useState(false);
    const [titreAddDocument, setTitreAddDocument] = useState("");
    const handleDocumentAdd = () => {
        if (documentAdd) {
            // La fenêtre d'ajout vient d'être fermée, on relit la liste des docs
            getDocuments();
        } else {
            // On ouvre le fenêtre d'ajout
            setTitreAddDocument(titre);
        }
        setDocumentAdd(!documentAdd);
    }

    useEffect(() => {
        logecomApi.get(apiRoot + 'status/naturedocument').then((res) => {
            var options = [];
            res.data.forEach(element => options.push({ value: element.Code_Statut.toString(), label: element.Lib_Statut }));
            setNatureOptions(options)
            setNatureOptionsAdd(res.data.filter(nature => nature["Visibilite" + elementRattache.replace('-', '')] === 2))
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }, [elementRattache]);

    useEffect(() => {
        getDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idElementRattache, client]);

    function getDocuments() {
        if (typeof idElementRattache != 'number' || parseInt(idElementRattache) <= 1 || !client || parseInt(client) <= 1) {
            return;
        }
        logecomApi.get(apiRoot + 'clients/' + client + '/documents?ElementRattache=\'' + elementRattache + '\'&IdElementRattache=' + idElementRattache).then((res) => {
            if (res.data && res.data.length > 0) {
                let options = natureOptions.filter(nature => res.data.some(document => parseInt(document.IDNATURE_DOCUMENT) === parseInt(nature.value)));;
                setNatureOptionsFiltered(options);
            } else {
                setNatureOptionsFiltered([]);
            }
            setDocuments(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }

    return (
        <div>
            <DocumentAdd
                isModalVisible={documentAdd}
                elementRattache={elementRattache}
                idElementRattache={idElementRattache}
                titre={titreAddDocument}
                natureOptionsAdd={natureOptionsAdd}
                handleDocumentAdd={handleDocumentAdd}
            />
            <Modal show={isModalVisible} backdrop="static" onHide={handleDocumentList} idElementRattache={idElementRattache} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row>
                            <Col sm={12}>
                                <strong>
                                    Élément :&nbsp;
                                </strong>
                                {titre}
                            </Col>
                        </Row>
                    </div>

                    <DocumentListTable
                        documents={documents}
                        handleDocumentAdd={handleDocumentAdd}
                        client={client}
                        showButtonAdd={natureOptionsAdd.length > 0}
                        natureOptions={natureOptionsFiltered}
                        refresh={getDocuments}
                    ></DocumentListTable>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={() => { handleDocumentList() }}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default DocumentList;