import React, { useState } from 'react';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import fileDownload from 'js-file-download'
import Alerte from '../Alerte';
import logecomApi from "../../utils/axiosInstance";
import { dateSort } from "../../utils/utils";
const apiSrvDoc = process.env.REACT_APP_SRVDOC

const FactureListTable = ({ documents, client }) => {
    const [messageAlerte, setMessageAlerte] = useState(null);
    const optionsTypeFacture = {
        'Consommables': 'Consommables',
        'Éléments Facturables': 'Éléments Facturables',
        'Frais Annexes Picking': 'Frais Annexes Picking',
        'Frais Annexes Transporteur': 'Frais Annexes Transporteur',
        'Personnalisations': 'Personnalisations',
        'Picking': 'Picking',
        'Transporteur': 'Transporteur',
    }
    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const afficherMessageAlerte = (afficher, titre, message) => {
        if (afficher === true) {
            setMessageAlerte({ 'titre': titre, 'message': message });
        } else {
            setMessageAlerte(null);
        }

    }
    // Définit les contrôles présents sur les lignes
    const controlsFormatter = (_, row) => <div class="btn-group">
        <button type="button" className="btn btn-md btn-primary" style={{ marginRight: '5px' }} onClick={() => download(row)}>
            <i className="ion ion-md-download d-block"></i>
        </button>
    </div>;

    const download = (doc) => {
        if (doc.IDDOCUMENT > 0) {
            logecomApi({
                method: 'get',
                url: apiSrvDoc + '?clientid=' + client + '&id=' + doc.IDDOCUMENT + '&crc=' + doc.CRC32,
                responseType: 'blob'
            })
                .then(function (response) {
                    fileDownload(response.data, doc.NomFichier);
                }).catch((error) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 409:
                                afficherMessageAlerte(true, 'Fichier', 'Le fichier sera disponible dans quelques instants.')
                                break;
                            case 502:
                                afficherMessageAlerte(true, 'Fichier', 'Une erreur est survenue lors du traitement du fichier. Merci de retenter ultérieurement.');
                                break;
                            default:
                                console.log(JSON.stringify(error));
                                break;
                        }
                    } else {
                        afficherMessageAlerte(true, 'Fichier', 'Une erreur est survenue lors du traitement du fichier. Merci de retenter ultérieurement.');
                    }
                });
        }
    }

    // La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
    const columns =
        [
            ["", "download", 14], ["ID", "IDDOCUMENT", 0], ["Titre", "Titre", 180], ["Type de facture", "TypeFacture", 100], ["Nom de fichier", "NomFichier", 80], ["Date facture", "DateFacture", 80]]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                if (['IDDOCUMENT', 'CRC32'].includes(result.dataField)) {
                    result.hidden = true;
                } else if (['AjouteLe'].includes(result.dataField)) {
                    result.sortFunc = dateSort;
                } else if (result.dataField === 'download') {
                    result.formatter = controlsFormatter;
                    result.sort = false;
                    result.filter = undefined;
                } else if (result.dataField === "TypeFacture") {
                    result.filter = selectFilter({
                        options: optionsTypeFacture,
                        className: 'custom-select',
                        defaultValue: 'Tous les types',
                        placeholder: 'Tous les types'
                    })
                }

                return result
            })


    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    return (
        <>
            <ToolkitProvider
                keyField="IDDOCUMENT"
                data={documents}
                columns={columns}
                bootstrap4
                search
                columnToggle
            >
                {props => (
                    <div>
                        <BootstrapTable
                            {...props.baseProps}
                            rowStyle={rowStyle}
                            wrapperClasses="table-responsive"
                            pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                            filter={filterFactory()}

                        />
                    </div>
                )}
            </ToolkitProvider>
            <Alerte
                isModalVisible={messageAlerte !== null}
                handleAlerte={afficherMessageAlerte}
                titre={messageAlerte ? messageAlerte.titre : null}
                message={messageAlerte ? messageAlerte.message : null}
            >
            </Alerte>
        </>
    )
}

export default FactureListTable;