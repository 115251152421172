import React, { } from 'react';
import { InputGroup, FormControl } from "react-bootstrap"

const ArticlePeriode = (props) => {

    return (

        <InputGroup
            style={{ width: '518px' }}
        >
            <InputGroup.Text
                id="a1"
            >Qté vendue du</InputGroup.Text>
            <FormControl
                type="date"
                placeholder="date de début"
                style={{ marginLeft: '-3px', height: '38px' }}
                defaultValue={props.dateDebutQteVendue}
                onChange={e => props.setDateDebutQteVendue(e.target.value)}
                aria-describedby="a1"
            />
            <InputGroup.Text
                style={{ marginLeft: '-3px', height: '38px' }}
                id="a2"
            >au</InputGroup.Text>
            <FormControl
                type="date"
                placeholder="date de fin"
                defaultValue={props.dateFinQteVendue}
                style={{ marginLeft: '-3px', height: '38px' }}
                onChange={e => props.setDateFinQteVendue(e.target.value)}
                aria-describedby="a2"
            />
            <button
                type="button"
                className="btn btn-md btn-primary"
                style={{ marginLeft: '1px', height: '38px' }}
                onClick={props.refresh}>
                <i className="ion ion-md-refresh d-block"></i>
            </button>
        </InputGroup>

    );
}

export default ArticlePeriode;