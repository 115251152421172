import React, { useEffect, useState } from 'react';
import logecomApi from "../utils/axiosInstance";
import { Helmet } from 'react-helmet';
import { Col, Row } from "react-bootstrap"
import {Link} from 'react-router-dom';
const apiRoot = process.env.REACT_APP_API_ROOT

const Dashboard = (props) => {
	const client = props.client;
	const [clients, setClients] = useState([]);
	const [orderStatus, setOrderStatus] = useState([]);
	const [shipmentCarrier, setShipmentCarrier] = useState([]);
	const [shipmentCountry, setShipmentCountry] = useState([]);
	const [orderNumber, setOrderNumber] = useState([]);
	const [shipmentsNumber, setShipmentsNumber] = useState([]);
	const [notif, setNotif] = useState([]);
	const [currentInformation, setCurrentInformation] = useState([]);

	const [pie1Labels, setPie1Labels] = useState([])
	const [pie1Values, setPie1Values] = useState([]);
	const [pie1Colors, setPie1Colors] = useState([]);
	const [pie2Colors, setPie2Colors] = useState([]);
	const [pie2Labels, setPie2Labels] = useState([]);
	const [pie2Values, setPie2Values] = useState([]);
	const [line1Values, setLine1Values] = useState([]);
	const [line2Values, setLine2Values] = useState([]);
	const [mergedLabels, setMergedLabels] = useState([]);
	const [histLabels, setHistLabels] = useState([]);
	const [histValues, setHistValues] = useState([]);
	const [duration, setDuration] = useState(30);

	useEffect(() => {
		localStorage.setItem("page", "")
		logecomApi.get(apiRoot + 'clients').then((res) => {
			setClients(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, []
	);


	const checkValidity = res => {
		return res === undefined || res === "" || res === {} ? [] : res
	}

	useEffect(() => {
		logecomApi.get(apiRoot + 'clients/' + client + '/orders/groupby_status').then((res) => {
			setOrderStatus(checkValidity(res.data))
		}).catch((error) => {
			console.log('erreur groupby_status', JSON.stringify(error), error)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/notifications').then((res) => {
			setNotif(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/currentinformation').then((res) => {
			setCurrentInformation(checkValidity(res.data[0]))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/shipments/groupby_carrier?duree=' + duration).then((res) => {
			setShipmentCarrier(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/orders/number_per_day?duree=' + duration).then((res) => {
			setOrderNumber(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/shipments/number_per_day?duree=' + duration).then((res) => {
			setShipmentsNumber(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/shipments/groupby_country?duree=' + duration).then((res) => {
			setShipmentCountry(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, [clients, client, duration]

	);

	const createColors = (len) => {
		const colors = ['rgb(13, 29, 236)', 'rgb(236, 13, 140)', 'rgb(236, 220, 13)', 'rgb(13,236,108)', 'rgb(13,226,236)', 'rgb(134,13,236)', 'rgb(236,22,13)'];
		const result = []
		for (let i = 0; i < len; i++) {
			result[i] = colors[i % colors.length]
		}
		return result;
	}

	useEffect(() => {
		let labels = orderStatus.map((elt) => { return elt.LibelleStatut })
		setPie1Labels(JSON.stringify(labels))
		let values = orderStatus.map((elt) => { return elt.Quantite })
		setPie1Values(JSON.stringify(values))
		setPie1Colors(JSON.stringify(createColors(values.length)))
	}, [orderStatus])

	useEffect(() => {
		let labels = shipmentCarrier.map((elt) => { return elt.Nom_Transporteur })
		setPie2Labels(JSON.stringify(labels))
		let values = shipmentCarrier.map((elt) => { return elt.Quantite })
		setPie2Values(JSON.stringify(values))
		setPie2Colors(JSON.stringify(createColors(values.length)))
	}, [shipmentCarrier])

	useEffect(() => {
		let labels = shipmentCountry.map((elt) => { return elt.Nom_Localisation })
		setHistLabels(JSON.stringify(labels))
		let values = shipmentCountry.map((elt) => { return elt.Quantite })
		setHistValues(JSON.stringify(values))
	}, [shipmentCountry])

	useEffect(() => {
		const compareEntries = (e1, e2) => {

			let d1 = e1[0];
			let d2 = e2[0];
			if (d1 === d2)
				return 0
			d1 = d1.split("-");
			d2 = d2.split("-");

			for (let i = 0; i < 3; i++) {
				if (parseInt(d1[i], 10) > parseInt(d2[i], 10)) return 1;
				if (parseInt(d1[i], 10) < parseInt(d2[i], 10)) return -1;
			}
		}
		let mergedArray = shipmentsNumber.concat(orderNumber)



		let tempDict = {}
		mergedArray.forEach(elt => tempDict[elt.Date_Commande !== undefined ? elt.Date_Commande : elt.Date_Expedition] = {})
		mergedArray.forEach(elt => tempDict[elt.Date_Commande !== undefined ? elt.Date_Commande : elt.Date_Expedition][elt.Date_Commande !== undefined] = elt.Quantite)
		mergedArray = Object.entries(tempDict)

		mergedArray.forEach((elt, idx) => { if (elt[1][false] !== undefined && elt[1][true] === undefined) { mergedArray[idx][1][true] = 0; } if (elt[1][false] === undefined && elt[1][true] !== undefined) { mergedArray[idx][1][false] = 0; } })
		mergedArray.sort(compareEntries)
		const l1Val = mergedArray.map(elt => elt[1][true])
		const l2Val = mergedArray.map(elt => elt[1][false])
		const mLabels = mergedArray.map(elt => elt[0])
		setMergedLabels(mLabels);
		setLine1Values(l1Val);
		setLine2Values(l2Val);

	}, [orderNumber, shipmentsNumber])

	if (clients.length > 0) {
		// Mécanisme qui pose les graphiques en utilisant des librairies js non React. Il serait préférable d'utiliser des composants React. 
		return (
			<React.Fragment>
				<Helmet><title>LOGeCOM - Tableau de bord</title>
					<script>
						{`
			document.getElementById("chart8").innerHTML="";
			var canvas8 = document.createElement('canvas');
			document.getElementById("chart8").appendChild(canvas8);
			var chart8 = new Chart(canvas8.getContext("2d"), {
				type: 'pie',
				data: {
					labels:`+ pie1Labels + ` ,
					datasets: [{
						data: `+ pie1Values + `,
						backgroundColor: `+ pie1Colors + `,
						borderColor: ['#fff'],
						borderWidth: 2
					}]
				},
				options: {
					scales: {
						xAxes: [{
							display: false,
						}],
						yAxes: [{
							display: false
						}]
					},
					legend: {
						position: 'right',
						labels: {
							boxWidth: 12
						}
					},
					responsive: true,
					maintainAspectRatio: true
				}
			});
			document.getElementById("chart9").innerHTML="";
			var canvas9 = document.createElement('canvas');
			document.getElementById("chart9").appendChild(canvas9);
			var chart9 = new Chart(canvas9.getContext("2d"), {
				type: 'pie',
				data: {
					labels: `+ pie2Labels + `,
					datasets: [{
						data: `+ pie2Values + `,
						backgroundColor: `+ pie2Colors + `,
						borderColor: ['#fff'],
						borderWidth: 2
					}]
				},
				options: {
					scales: {
						xAxes: [{
							display: false,
						}],
						yAxes: [{
							display: false
						}]
					},
					legend: {
						position: 'right',
						labels: {
							boxWidth: 12
						}
					},
					responsive: true,
					maintainAspectRatio: true
				}
			});
			document.getElementById("chart10").innerHTML="";
			var canvas10 = document.createElement('canvas');
			document.getElementById("chart10").appendChild(canvas10);
			var graphChart = new Chart(canvas10.getContext("2d"), {
				type: 'line',
				options: {
					responsive: true,
					maintainAspectRatio: true,
					elements:{
						line:{
							tension :0.3,
						}
					},
					scales: {
						xAxes: [{
							gridLines: {
								color: "rgba(189,189,189,.3)"
							}
						}],
						yAxes: [{
							gridLines: {
								color: "rgba(189,189,189,.3)"
							}
						}]
					}	
				},	  
				data: {
					labels: `+ JSON.stringify(mergedLabels) + `,
					datasets: [{
						label: 'Commandes Reçues',
						data: `+ JSON.stringify(line1Values) + `,
						borderWidth: 2,
						backgroundColor: '#ecdc0d',
						borderColor: '#ecdc0d',
						fill: false
					}, {
						label: 'Commandes expédiées',
						data: `+ JSON.stringify(line2Values) + `,
						borderWidth: 2,
						backgroundColor: '#0dec6c',
						borderColor: '#0dec6c',
						fill: false
					}],
				},
			
			});
			document.getElementById("chart11").innerHTML="";
			var canvas11 = document.createElement('canvas');
			document.getElementById("chart11").appendChild(canvas11);
			var graphpHorizontal = new Chart(canvas11.getContext("2d"), {
				type: 'horizontalBar', 
				data: {
					labels: `+ histLabels + `,
					datasets: [{
						label: 'Expéditions',
						backgroundColor: '#0d1dec',
						borderColor: '#0d1dec',
						borderWidth: 1,
						barThickness: 30,
						data: `+ histValues + `
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio: true,
					legend: {
						display: false,
					},
					scales: {
						xAxes: [{
							ticks: {
								min : 0,
								callback: function(value, index, values) {
									return value.toLocaleString();
								}
							},
							gridLines: {
								color: "rgba(189,189,189,.3)"
							},
							type:"logarithmic"
						}],
						yAxes: [{
							gridLines: {
								color: "rgba(189,189,189,.3)"
							}
						}]
					}
				}
			});`}
					</script>

				</Helmet>
				<div className="layout-wrapper layout-1 layout-without-sidenav">
					<div className="layout-inner">

						<div className="layout-container">
							<div className="layout-content">
								<div className="container flex-grow-1 mycontainer-p-y">
									<div className="bloc-selection-duree--wrapper">

										<h1 className="titre-section font-weight-bold py-3 mb-4">Tableau de bord</h1>

										<div className="bloc-selection-duree">
											<div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
												{[7, 14, 30, 60, 90].map((n) => { return duration === n ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {n === 30 ? "1 mois" : n.toString() + " j"}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {n === 30 ? "1 mois" : n.toString() + " j"} </label> }
												)}
											</div>
										</div>

									</div>
									{notif.length > 0 &&
										<div className="row">
											<div className="col-sm-12">
												<div className="card mb-4">
													<h6 className="card-header with-elements border-0 pr-0 pb-0">
														<div className="card-header-title"> <span className="iconeAttention">&#x26A0;</span> Liste des notifications</div>
													</h6>
													<div className="card-body">
														<ul className="notifications">
															{notif.map((elt, idx) => {
																if (elt.importance === "Haute") {
																	return (
																		<li key={idx}>
																			<span className="notifications__titre haute">{elt.subject}</span>
																			<span className="notifications__message">{elt.body}</span>
																		</li>
																	)
																} else if (elt.importance === "Normale") {
																	return (
																		<li key={idx}>
																			<span className="notifications__titre normale">{elt.subject}</span>
																			<span className="notifications__message">{elt.body}</span>
																		</li>
																	)
																} else {
																	//"Normale"
																	return (
																		<li key={idx}>
																			<span className="notifications__titre basse">{elt.subject}</span>
																			<span className="notifications__message">{elt.body}</span>
																		</li>
																	)
																}
															})}

														</ul>
													</div>
												</div>

											</div>
										</div>
									}

									<div className="row">
										<div className="col-sm-12">
											<div className="card mb-4">
												<h6 className="card-header with-elements border-0 pr-0 pb-0">
													<div className="card-header-title">Informations courante</div>
												</h6>
												<div className="card-body">
													<Row className="mb-2">
														<Col sm={1}>
														</Col>
														<Col sm={4}>
															<strong>Discussions non lues (hors SAV) : </strong>
														</Col>
														<Col sm={1}>
															{currentInformation.DiscussionsHorsSAV > 0 ? 
															<Link to={{
																pathname: '/discussions',
																state: {filtre: '2'}
															  }} >{currentInformation.DiscussionsHorsSAV} </Link>
															:
															currentInformation.DiscussionsHorsSAV
															}
														</Col>
														<Col sm={4}>
															<strong>Discussions SAV non lues : </strong>
														</Col>
														<Col sm={1}>
															{currentInformation.DiscussionsSAV > 0 ? 
															<Link to={{
																pathname: '/sav',
																state: {filtre: '2'}
															  }} >{currentInformation.DiscussionsSAV} </Link>
															:
															currentInformation.DiscussionsSAV
															}
														</Col>
													</Row>
													<Row className="mb-2">
														<Col sm={1}>
														</Col>
														<Col sm={4}>
															<strong>Article en rupture bloquante : </strong>
														</Col>
														<Col sm={1}>
															{currentInformation.ArticleEnRuptureBloquante > 0 ? 
															<Link to={{
																pathname: '/articles',
																state: {filtre: 2}
															  }} >{currentInformation.ArticleEnRuptureBloquante} </Link>
															:
															currentInformation.ArticleEnRuptureBloquante
															}	
														</Col>
														<Col sm={4}>
															<strong>Articles ayant franchi leur seuil d'alerte : </strong>
														</Col>
														<Col sm={1}>
															{currentInformation.ArticleSeuilAlerte > 0 ? 
															<Link to={{
																pathname: '/articles',
																state: {filtre: 1}
															  }} >{currentInformation.ArticleSeuilAlerte} </Link>
															:
															currentInformation.ArticleSeuilAlerte
															}
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</div>

									<div className="row">

										<div className="col-sm-12 col-xl-6">

											<div className="card mb-4">
												<h6 className="card-header with-elements border-0 pr-0 pb-0">
													<div className="card-header-title">État des commandes sans notion de durée</div>
												</h6>
												<div className="py-4 px-3">
													<div id="chart8">
													</div>
												</div>
											</div>

										</div>


										<div className="col-sm-12 col-xl-6">

											<div className="card mb-4">
												<h6 className="card-header with-elements border-0 pr-0 pb-0">
													<div className="card-header-title">Répartition des expéditions par transporteur</div>
												</h6>
												<div className="py-4 px-3">
													<div id="chart9">
													</div>
												</div>
											</div>

										</div>

									</div>


									<div className="row">

										<div className="col-sm-12">

											<div className="card mb-4">
												<h6 className="card-header with-elements border-0 pr-0 pb-0">
													<div className="card-header-title">Évolution du nombre de commandes reçues et expédiées</div>
												</h6>
												<div className="py-4 px-3">
													<div id="chart10">
													</div>
												</div>
											</div>

										</div>

									</div>

									<div className="row d-none d-md-block">

										<div className="col-sm-12">

											<div className="card mb-4">
												<h6 className="card-header with-elements border-0 pr-0 pb-0">
													<div className="card-header-title">Répartitions des expéditions par destination</div>
												</h6>
												<div className="py-4 px-3">
													<div id="chart11">
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
	else {
		return null
	}

}

export default Dashboard;