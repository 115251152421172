import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';
import { Spinner } from 'react-bootstrap';
import { dateSort, labelDureeJMA } from "../../utils/utils";
import DiscussionModal from '../Discussion/DiscussionModal';
import DocumentList from '../Document/DocumentList';
import PrereceiptWatch from './PreReceiptWatch';
import PrereceptionEdit from './PreReceptionEdit';
import { Col, Row, ToggleButton, ButtonGroup } from "react-bootstrap";
import YesNo from '../../utils/YesNo';


const apiRoot = process.env.REACT_APP_API_ROOT

const PreReceptionsListTable = ({ client }) => {
	const filtreEnum = { Tous: 0, NonRecue: 1, RecuePartiellement: 2 };
	const [loading, setLoading] = useState(false);
	const [clients, setClients] = useState([]);
	const [data, setData] = useState([]);
	const [idElementRattache, setIdElementRattache] = useState(0);
	const [titreElement, setTitreElement] = useState("");
	const [discussionVisible, setDiscussionVisible] = useState(false);
	const [preReceptionVisible, setPreReceptionVisible] = useState(false);
	const [documentList, setDocumentList] = useState(false);
	const [selectedID, setSelectedID] = useState(0);
	const [watchModalShow, setWatchModalShow] = useState(false);
	const [duration, setDuration] = useState(30);
	const [filtre, setFiltre] = useState(filtreEnum.Tous);
	const [yesNoDeleteRow, setYesNoDeleteRow] = useState(false);


	const checkValidity = res => {
		return res === undefined || res === "" ? [] : res
	}

	useEffect(() => {
		localStorage.setItem("page", "pre-reception")
		logecomApi.get(apiRoot + 'clients').then((res) => {
			setClients(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, []);

	const handleDocumentList = (idPreReception, dateCreation) => {
		if (typeof idPreReception === 'number') {
			setIdElementRattache(idPreReception);
			setTitreElement('Pré-réception du ' + dateCreation)
		}
		setDocumentList(true);
	};

	const ouvrirDiscussion = (idPreReception, dateCreation) => {
		setIdElementRattache(idPreReception);
		setTitreElement('Pré-réception du ' + dateCreation)
		setDiscussionVisible(true);
	};

	const deleteRow = (idPreReception) => {
		logecomApi.delete(apiRoot + 'pre_receipts/' + idPreReception).then(res => {
			refresh();
		}).catch((error) => {
			console.log(JSON.stringify(error.response.data.fault.detail));
		})
	};




	useEffect(() => {
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, duration, filtre, filtreEnum.NonRecue]
	);

	const refresh = () => {
		if (!client || parseInt(client) === 0) {
			return;
		}
		setLoading(true)
		var duree = duration;
		if (filtre === filtreEnum.NonRecue) {
			duree = 5000;
		}
		logecomApi.get(apiRoot + 'clients/' + client + '/pre_receipts?duree=' + duree + '&statut=0').then((res) => {
			setData(checkValidity(res.data))
			setLoading(false)
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)

		})
	};

	const openWatchModal = (row) => {
		setSelectedID(row.id_PreReception)
		setWatchModalShow(true)
	};

	const openEditModal = (row) => {
		setSelectedID(row.id_PreReception)
		setPreReceptionVisible(true)
	};

	const openCreateModal = () => {
		setSelectedID(0)
		setPreReceptionVisible(true)
	};

	const filteredData = () => {
		if (data === undefined || data === "") {
			return [];
		} else {
			return data.filter(function (row) {
				let affiche = true;
				switch (filtre) {
					case filtreEnum.NonRecue:
						affiche = (row.Statut === 'Non reçue');
						break;
					case filtreEnum.Recue:
						affiche = (row.Statut === 'Reçue');
						break;
					case filtreEnum.RecuePartiellement:
						affiche = (row.Statut === 'Reçue partiellement');
						break;
					default:
						break;
				}
				return affiche;
			})
		}
	}

	const isIE10Mode = document['documentMode'] === 10;

	// Définit les contrôles présents sur les lignes
	const controlsFormatter = (_, row) => {
		let coulBtnDiscussion = '#fff';
		let titleDiscussion = 'Discussion fermée';
		if (row.nbMessagesNonLu > 0) {
			coulBtnDiscussion = 'red';
			titleDiscussion = 'Discussion avec des messages non lus';
		} else if (row.nbDiscussionOuverte > 0) {
			coulBtnDiscussion = 'green';
			titleDiscussion = 'Discussion ouverte';
		}
		return (
			<div className="btn-group">
				<button type="button" title="Ouvrir" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openWatchModal(row)}>
					<i className="ion ion-md-eye d-block"></i>
				</button>
				{row.Modifiable === 1 ?
					<React.Fragment>
						<button type="button" title="Modifier" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openEditModal(row)}>
							<i className="ion ion-md-create d-block"></i>
						</button>
						<button type="button" title="Supprimer" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { setSelectedID(row.id_PreReception); setYesNoDeleteRow(true) }}>
							<i className="ion ion-md-trash"></i>
						</button>
					</React.Fragment> : null}
				{row.docExiste === 1 ?
					<button type="button" title="Documents" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => handleDocumentList(row.id_PreReception, row.CreeLe)}>
						<i className="ion ion-md-document d-block"></i>
					</button> : null}
				{row.nbMessages > 0 ?
					<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px', color: coulBtnDiscussion }} title={titleDiscussion} onClick={() => ouvrirDiscussion(row.id_PreReception, row.CreeLe)}>
						<i className="ion ion-md-chatbubbles d-block"></i>
					</button> : null}
			</div>
		)
	};



	const headerWidth = (width) => () => {
		return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
	};

	// La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
	const columns =
		[["", "", 125],
		["ID", "id_PreReception", 0], ["Modifiable", "Modifiable", 0], ["Créé le", "CreeLe", 140], ["Modifié le", "ModifieLe", 140], ["Réception prévue le", "DatePrevisionnelle", 150],
		["Statut", "Statut", 120], ["Clôturée", "Cloturee", 90], ["Fournisseur", "LibelleFournisseur", 120],
		["Bordereau", "Lib_BordereauLivraison", 180], ["Transporteur", "Lib_Transporteur", 120], ["Origine", "Origine", 160], ["Commentaire", "Commentaire", 180]]
			.map(elt => {
				let result = { text: elt[0], dataField: elt[1], headerStyle: headerWidth(elt[2]), filter: textFilter({ placeholder: elt[0] }), sort: true }
				if (result.text === "") {
					result.isDummyField = true
					result.CSVExport = false
					result.formatter = controlsFormatter
					result.sort = false
					result.filter = undefined
				}
				else if (result.text === "ID" || result.text === "Modifiable") {
					result.hidden = true
				}
				else if (result.text === "Créé le" || result.text === "Modifié le" || result.text === "Réception prévue le") {
					result.sortFunc = dateSort
				}
				else if (result.text === "Statut") {
					var options = {};
					data.forEach(row => options[row.Statut] = row.Statut);
					result.formatter = cell => options[cell];
					result.filter = selectFilter({
						options: options,
						className: 'custom-select',
						placeholder: 'Tous'
					})
				}
				else if (result.text === "Clôturée") {
					result.filter = selectFilter({
						options: {
							'Oui': 'Oui',
							'Non': 'Non',
						},
						className: 'custom-select',
						defaultValue: 'Non',
						placeholder: 'Tous'
					})
					result.editor = {
						type: Type.SELECT,
						options: [
							{ value: 'Oui', label: 'Oui' },
							{ value: 'Non', label: 'Non' },
						]
					}
				}
				return result
			});


	// Change le CSS d'une ligne en fonction de l'index
	const rowStyle = (row, rowIndex) => {
		if (data[rowIndex].Qte_EnStock < data[rowIndex].Seuil_Alerte) {
			if (rowIndex % 2 === 0) {
				return { backgroundColor: '#f44336' };
			}
			else {
				return { backgroundColor: '#d42316' };
			}
		}
		if (rowIndex % 2 === 1) {
			return { backgroundColor: '#dddddd' };
		}
	};


	if (clients.length > 0) {
		return (
			<>
				{loading ?
					<div style={{ textAlign: 'center' }}>
						<Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</div>
					:
					<div>

						<div>
							{isIE10Mode && <div className="alert alert-danger">
								<strong>react-bootstrap-table2</strong> doesn't support Internet Explorer 10
							</div>}

							{!isIE10Mode &&

								<ToolkitProvider
									keyField="id_PreReception"
									data={filteredData()}
									columns={columns}
									bootstrap4
									search
									columnToggle
								>
									{props => (
										<div>
											<Row style={{ marginBottom: '20px' }}>
												<Col>
													<span className="titre-section font-weight-bold">Pré-réceptions</span>
													<div className="header-controles">
														<button type="button" className="btn btn-md btn-primary" style={{ marginRight: '5px', height: '38px' }} onClick={openCreateModal}>
															<i className="ion ion-md-add-circle d-block"></i>
														</button>
														<ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">
															<ToggleButton
																type="checkbox"
																variant="secondary"
																checked={filtre === filtreEnum.Tous}
																value="1"
																onChange={(e) => { setFiltre(filtreEnum.Tous) }}
															>
																Toutes
															</ToggleButton>
															<ToggleButton
																type="checkbox"
																variant="secondary"
																checked={filtre === filtreEnum.NonRecue}
																value="1"
																onChange={(e) => { setFiltre(filtreEnum.NonRecue) }}
															>
																Non reçues
															</ToggleButton>
															<ToggleButton
																type="checkbox"
																variant="secondary"
																checked={filtre === filtreEnum.RecuePartiellement}
																value="1"
																onChange={(e) => { setFiltre(filtreEnum.RecuePartiellement) }}
															>
																Reçues partiellement
															</ToggleButton>
														</ButtonGroup>
														{filtre === filtreEnum.Tous && <span style={{ marginLeft: '5px' }} className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
															{[7, 14, 30, 60, 90, 365].map((n) => { return duration === n || duration === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)} </label> }
															)}
														</span>}
													</div>
												</Col>
											</Row>
											<BootstrapTable
												{...props.baseProps}
												rowStyle={rowStyle}
												wrapperClasses="table-responsive"
												pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
												filter={filterFactory()}
											/>
										</div>
									)}
								</ToolkitProvider>
							}
						</div></div>
				}
				<PrereceiptWatch
					isModalVisible={watchModalShow}
					fermer={() => { setWatchModalShow(false) }}
					prereceiptid={selectedID}
				/>
				<DocumentList
					isModalVisible={documentList}
					elementRattache='Pre-Receipt'
					idElementRattache={idElementRattache}
					client={client}
					titre={titreElement}
					handleDocumentList={() => setDocumentList(!documentList)}
				/>
				<DiscussionModal
					isModalVisible={discussionVisible}
					elementRattache='Pre-Receipt'
					idElementRattache={idElementRattache}
					client={client}
					titre={titreElement}
					fermerDiscussion={() => setDiscussionVisible(false)}
				/>
				<YesNo show={yesNoDeleteRow} hide={setYesNoDeleteRow} callbackfunction={deleteRow} object={selectedID} title="Suppression" body="Confirmez-vous la suppression de cette pré-réception ?"></YesNo>
				<PrereceptionEdit
					isModalVisible={preReceptionVisible}
					editId={selectedID}
					client={client}
					fermer={() => {
						setSelectedID(null);
						setPreReceptionVisible(false);
						refresh();
					}}
				/>
			</>
		);
	} else {
		return null
	}

}

export default PreReceptionsListTable;