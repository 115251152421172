import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, ButtonGroup, ToggleButton } from "react-bootstrap"
import FactureListTable from './FactureListTable';
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT

const FacturesPage = (props) => {
    const client = props.client;
    const [duration, setDuration] = useState(30);
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
        if (!client || parseInt(client) === 0) {
            return;
        }
        logecomApi.get(apiRoot + 'clients/' + client + '/documentsduree?ElementRattache=\'Invoice\'&duree=' + duration).then((res) => {
            setDocuments(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }, [client, duration]
    );

    return (
        <React.Fragment>
            <Helmet><title>LOGeCOM - Factures</title>

            </Helmet>
            <div className="layout-wrapper layout-1 layout-without-sidenav">
                <div className="layout-inner">

                    <div className="layout-container">
                        <div className="layout-content">

                            <div className="container flex-grow-1 mycontainer-p-y unbox">
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col><span className="titre-section font-weight-bold">Factures</span>
                                        <div className="header-controles">
                                            <ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">

                                                {[{ value: 30, title: '1 mois' }, { value: 180, title: '6 mois' }, { value: 365, title: '1 an' }].map((n) => {
                                                    return <ToggleButton
                                                        type="checkbox"
                                                        variant="secondary"
                                                        checked={duration === n.value}
                                                        value="1"
                                                        onChange={(e) => setDuration(n.value)}
                                                    >
                                                        {n.title}
                                                    </ToggleButton>
                                                }
                                                )}
                                            </ButtonGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ marginBottom: '40px' }}>

                                    <FactureListTable
                                        documents={documents}
                                        client={client}
                                    ></FactureListTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default FacturesPage;