import React, { useEffect, useState } from 'react';
import logecomApi from "../utils/axiosInstance";
import { Helmet } from 'react-helmet';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Col, Row, Button } from "react-bootstrap"
import Select from 'react-select'

import { downloadCSV, patchFilterFactory } from "../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const TarifsTransporteurs = (props) => {
	const client = props.client;
	const [clients, setClients] = useState([]);
	const [carriers, setCarriers] = useState([]);
	const [zones, setZones] = useState([]);
	const [carrierID, setCarrierID] = useState(-1)
	const [methods, setMethods] = useState([])
	const [methodID, setMethodID] = useState(-1)
	const [method, setMethod] = useState({})
	const [data, setData] = useState([])
	const [areas, setAreas] = useState([])
	const [exportData, setExportData] = useState([]);
	const checkValidity = res => {
		return res === undefined || res === "" || res === {} ? [] : res
	}

	useEffect(() => {
		localStorage.setItem("page", "tarif-transporteur")
		logecomApi.get(apiRoot + 'clients').then((res) => {
			setClients(res.data)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, []
	);

	const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
		setExportData(prevData => {
			if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
				return filteredData
			}
			return prevData
		})
	})

	useEffect(() => {
		if (!client || parseInt(client) === 0) {
			return;
		}
		logecomApi.get(apiRoot + 'carriers?idclient=' + client).then((res) => {
			setCarriers(res.data)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, [client]
	);

	useEffect(() => {
		logecomApi.get(apiRoot + 'carriers/' + carrierID + '/' + methodID + '/areas').then((res) => {
			setAreas(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, [carrierID, methodID]
	);


	useEffect(() => {
		if (carrierID !== -1) {
			setData([])
			setMethodID(-1)
			logecomApi.get(apiRoot + 'carriers/' + client + '/' + carrierID + '/family_shipping_method').then((res) => {
				setMethods(res.data)
			}).catch((error) => {
				console.log(JSON.stringify(error))
			})
		}
	}, [client, carrierID]
	);

	useEffect(() => {
		if (carrierID !== -1 && methodID !== -1) {
			logecomApi.get(apiRoot + 'shipping?idclient=' + client + '&idtransporteur=' + carrierID + '&idfamille_mode_envoi=' + methodID).then((res) => {
				//console.table(res.data)
				let zones = [];
				let addCol = false;
				let data = res.data
				if (data) {
					Object.keys(data[0]).forEach(key => {
						if (addCol) {
							zones.push(key);
						} else if (key === 'PrixAchat') {
							addCol = true;
						}
					});
					setZones(zones);
				}
				setData(data)
			}).catch((error) => {
				console.log(JSON.stringify(error))
			})
		}
	}, [carrierID, client, methodID]
	);

	useEffect(() => {
		const newMethod = methods.find(elt => elt.IDFAMILLE_MODE_ENVOI === parseInt(methodID))
		setMethod(newMethod)
	}, [methodID, methods])

	const headerWidth = (width) => (colum, colIndex) => {
		return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
	}

	// Change le CSS d'une ligne en fonction de l'index
	const rowStyle = (row, rowIndex) => {
		if (data[rowIndex].Qte_EnStock < data[rowIndex].Seuil_Alerte) {
			if (rowIndex % 2 === 0) {
				return { backgroundColor: '#f44336' };
			}
			else {
				return { backgroundColor: '#d42316' };
			}
		}
		if (rowIndex % 2 === 1) {
			return { backgroundColor: '#dddddd' };
		}
	};
	const isIE10Mode = document['documentMode'] === 10

	const controlsFormatter = (cell, row, rowIndex, nomColonne) => {
		if (cell) {
			let val = cell;
			val = val.toFixed(2);
			val = val.replace(".", ",") + ' €';
			return (
				val
			)
		}
	}

	// La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
	let columns =
		[
			["ID", "IDMODE_ENVOI", 0, false], ["Mode d'envoi", "LibelleEnvoi", 50, true]].concat(data.length > 0 ? zones.map(elt => [elt, elt, 50, true]) : [])
			.map(elt => {
				let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), sort: true }

				if (result.dataField === "IDMODE_ENVOI") {
					result.hidden = true
				} else if (zones.includes(result.dataField)) {
					result.style = { textAlign: 'right' };
					result.formatter = controlsFormatter;
				}

				return result
			})

	if (clients.length > 0) {
		return (
			<React.Fragment>
				<Helmet><title>LOGeCOM - Tarifs transporteurs</title>

				</Helmet>
				<div className="layout-wrapper layout-1 layout-without-sidenav">
					<div className="layout-inner">

						<div className="layout-container">
							<div className="layout-content">

								<div className="container flex-grow-1 mycontainer-p-y">
									<div style={{ marginBottom: '40px' }}>

										{!isIE10Mode && <React.Fragment>

											<ToolkitProvider
												keyField="IDMODE_ENVOI"
												data={data}
												columns={columns}
												bootstrap4
												search
												columnToggle
											>
												{props => (
													<div>
														<div>
															<Row style={{ marginBottom: '20px' }}>
																<Col>
																	<span className="titre-section font-weight-bold">Tarifs transporteurs</span>
																	<div className="header-controles">
																		<Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'TarifsTransporteurs') }}>Export CSV</Button>
																	</div>
																</Col>
															</Row>
															<Row>
																<Col md={4}>
																	<Select onChange={e => { setCarrierID(e.value) }} options={carriers.map(elt => ({ value: elt.IDTRANSPORTEUR, label: elt.NomTransporteur }))} defaultValue={{ label: "Transporteur", value: -1 }}></Select>
																</Col>
																<Col md={4}>
																	<Select onChange={e => { setMethodID(e.value) }} options={methods.map(elt => ({ value: elt.IDFAMILLE_MODE_ENVOI, label: elt.Libelle }))} value={{ value: methodID, label: methodID > 0 ? methods.find(elt => elt.IDFAMILLE_MODE_ENVOI === methodID).Libelle : "Méthode" }} placeholder={methodID > 0 ? methods.find(elt => elt.IDFAMILLE_MODE_ENVOI === methodID).Libelle : "Méthode"} defaultValue={{ label: "Méthode", value: -1 }}></Select>
																</Col>
															</Row>
															{method &&
																<div>

																	<Row className="mt-4 mb-4 commentaire">
																		<Col>
																			<strong>Commentaire : {method.CommentaireWEB}</strong>
																		</Col>
																	</Row>

																	<div className="section-form pb-4">
																		<Row>
																			<Col sm={4}>Longueur minimum : {method.LongueurMin + " cm"}</Col>
																			<Col sm={4}>Largeur minimum : {method.LargeurMin + " cm"}</Col>
																			<Col sm={4}>Epaisseur minimum : {method.EpaisseurMin + " cm"}</Col>
																		</Row>
																		<Row>
																			<Col sm={4}>Longueur maximum : {method.LongueurMax + " cm"}</Col>
																			<Col sm={4}>Largeur maximum : {method.LargeurMax + " cm"}</Col>
																			<Col sm={4}>Epaisseur maximum : {method.EpaisseurMax + " cm"}</Col>
																		</Row>
																	</div>
																</div>
															}
															<br /></div>

														<BootstrapTable
															{...props.baseProps}
															rowStyle={rowStyle}
															wrapperClasses="table-responsive"
															filter={myTableFilterFactory()}
															pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 250 })}

														/>
													</div>
												)}
											</ToolkitProvider>
											<p>Prix hors taxe carburant. Se référer aux sites officiels des transporteurs pour connaitre le taux en vigueur à date de facturation.</p>
											{areas.map(elt => (
												<div className="section-form">
													<Row key={elt.IDZONE_GEO}>
														<Col sm={4}>{elt.LibelleWEB}</Col>
														<Col sm={4}>{elt.Libelle}</Col>
														<Col sm={4}>{elt.LOCALISATION}</Col>
													</Row>
												</div>
											))}
										</React.Fragment>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>

		);
	}
	else {
		return null
	}


}

export default TarifsTransporteurs;