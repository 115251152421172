import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import DiscussionModal from '../Discussion/DiscussionModal';
import DocumentList from '../Document/DocumentList';
import { downloadCSV, getPdfCoutReception, patchFilterFactory } from "../../utils/utils";

const apiRoot = process.env.REACT_APP_API_ROOT

const ReceptionWatch = ({ isModalVisible, fermer, receiptId }) => {
	const [receipt, setReceipt] = useState({});
	const [lineData, setLineData] = useState([]);
	const [discussionVisible, setDiscussionVisible] = useState(false);
	const [exportData, setExportData] = useState([]);
	const [documentList, setDocumentList] = useState(false);

	const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
		setExportData(prevData => {
			if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
				return filteredData
			}
			return prevData
		})
	})

	const ouvrirDiscussion = () => {
		setDiscussionVisible(true);
	};

	const handleDocumentList = () => {
		setDocumentList(true);
	};

	useEffect(() => {
		if (receiptId) {
			logecomApi.get(apiRoot + 'receipts/' + receiptId).then((res) => {
				setReceipt(res.data[0])
			}).catch((error) => {
				console.log(JSON.stringify(error))
			})
			logecomApi.get(apiRoot + 'receipts/' + receiptId + '/lines').then((res) => {
				setLineData(res.data)
			}).catch((error) => {
				console.log(JSON.stringify(error))
			})
		}

	}, [receiptId]);

	const qteFormatter = (cell, row, rowIndex) => {
		return (
			((row.QteARecevoir > 0 && row.QteARecevoir !== row.Qte_Recue) || (row.QteARecevoir === 0 && row.Qte_Recue > 0)) ?
				<div style={{ color: '#f44336' }}><b>{cell}</b></div>
				:
				<div>{cell}</div>
		)
	}

	const headerWidth = (width) => (colum, colIndex) => {
		return { width: width + 'px' };
	}

	// La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  	
	const lineColumns = [

		{
			text: 'SKU',
			dataField: 'SKU',
			sort: true,
			filter: textFilter(),
			exporter: true
		}, {
			text: 'Libellé',
			dataField: 'Lib_Article1',
			sort: true,
			filter: textFilter(),
			exporter: true
		}, {
			text: 'Libellé compl.',
			dataField: 'Lib_Article2',
			sort: true,
			filter: textFilter(),
			exporter: true
		}, {
			text: 'Famille',
			dataField: 'Lib_Famille',
			sort: true,
			filter: textFilter(),
			exporter: true
		}, {
			text: 'Numéro de lot',
			dataField: 'NumeroDeLot',
			sort: true,
			filter: textFilter(),
			exporter: true,
			headerStyle: headerWidth(150)
		}, {
			text: 'Quantité attendue',
			dataField: 'QteARecevoir',
			sort: true,
			filter: textFilter(),
			formatter: qteFormatter,
			exporter: true,
			headerStyle: headerWidth(90)

		}, {
			text: 'Quantité reçue',
			dataField: 'Qte_Recue',
			sort: true,
			filter: textFilter(),
			formatter: qteFormatter,
			exporter: true,
			headerStyle: headerWidth(90)

		}, {
			text: 'Quantité conforme',
			dataField: 'Qte_Conforme',
			sort: true,
			filter: textFilter(),
			formatter: qteFormatter,
			exporter: true,
			headerStyle: headerWidth(90)

		}, {
			text: 'Quantité non conforme',
			dataField: 'Qte_NonConforme',
			sort: true,
			filter: textFilter(),
			exporter: true,
			headerStyle: headerWidth(110)
		}]

	return (
		<>
			<Modal show={isModalVisible} backdrop="static" onHide={fermer} size="xl">
				<Modal.Header closeButton>
					<Modal.Title as="h5">
						Fiche réception <br />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="section-form">
						<Row className="mb-2">
							<Col sm={12}>
								<strong>Statut :&nbsp;</strong>
								{receipt.Reception_Conforme ? "Réception conforme" : "Réception non conforme"}
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm={6}>
								<strong>Créé le :&nbsp;</strong>
								{receipt.Cree_Le}

							</Col>
							<Col sm={6}>
								<strong>Réception le :&nbsp;</strong>
								{receipt.Reception_Le}
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm={6}>
								<strong>Bordereau :&nbsp;</strong>
								{receipt.Bordereau}
							</Col>
							<Col sm={6}>
								<strong>Transporteur :&nbsp;</strong>
								{receipt.Nom_Transporteur}
							</Col>
						</Row>
					</div>
					<div className="section-form">
						<Row className="mb-2">
							<Col sm={12}>
								<strong>Commentaire:&nbsp;</strong>
								{receipt.Commentaire}
							</Col>
						</Row>
					</div>
					<ToolkitProvider
						keyField="Id_LigneReception"
						data={lineData}
						columns={lineColumns}
						bootstrap4
						search
						columnToggle
					>
						{props => (
							<div>
								<Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, lineColumns, 'Réception') }}>Export CSV</Button>

								<BootstrapTable
									{...props.baseProps}
									wrapperClasses="table-responsive"
									pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
									filter={myTableFilterFactory()}

								/>
							</div>
						)}
					</ToolkitProvider>
				</Modal.Body>
				<Modal.Footer>
					{receipt.IDFACTURE > 0 && receipt.facturable === 1 && <Button variant="default" className="blue" onClick={() => { getPdfCoutReception(receiptId, receipt.Reception_Le) }}>Coût</Button>}
					<Button variant="default" className="blue" onClick={ouvrirDiscussion}>Discussions</Button>
					<Button variant="default" className="blue" onClick={() => { handleDocumentList(receiptId, receipt.Bordereau, receipt.Reception_Le) }}>Documents</Button>
					<Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
				</Modal.Footer>
			</Modal>
			<DocumentList
				isModalVisible={documentList}
				elementRattache='Receipt'
				idElementRattache={receiptId}
				client={receipt.Id_Client}
				titre={'Réception ' + receipt.Bordereau + ' reçu le ' + receipt.Reception_Le}
				handleDocumentList={() => setDocumentList(!documentList)}
			/>
			<DiscussionModal
				isModalVisible={discussionVisible}
				elementRattache='Receipt'
				idElementRattache={receiptId}
				client={receipt.Id_Client}
				titre={'Réception ' + receipt.Bordereau + ' reçu le ' + receipt.Reception_Le}
				fermerDiscussion={() => setDiscussionVisible(false)}
			/>
		</>
	)
}

export default ReceptionWatch; 