import React, { useEffect, useState } from 'react';
import { Col, Row, Button, ButtonGroup, ToggleButton } from "react-bootstrap"
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import YesNo from '../../utils/YesNo';
import AdresseEdit from './AdresseEdit';
import OrdersForAddress from '../Commandes/OrdersForAddress';
import logecomApi from "../../utils/axiosInstance";
import { dateSort, downloadCSV, patchFilterFactory } from "../../utils/utils";
const apiRoot = process.env.REACT_APP_API_ROOT

const AdressesListTable = ({ client, idBoutique, natureAdresse, modeSelection, setAdresse }) => {
    const [adresses, setAdresses] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [yesNoDelete, setYesNoDelete] = useState(false);
    const [ordersForAddressVisible, setOrdersForAddressVisible] = useState(false);
    const [afficherDialogueAdresse, setAfficherDialogueAdresse] = useState(false);
    const [selectedRow, setSelectedRow] = useState({})
    const filtreEnum = { Tous: 0, Client: 1, Fournisseur: 2 };
    const [filtre, setFiltre] = useState(filtreEnum.Tous);
    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, idBoutique, natureAdresse]);

    const filteredData = () => {
        if (adresses === undefined || adresses === "" ) {
            return [];
        } else {
            return adresses.filter(function (row) {
                let affiche = true;
                switch (filtre) {
                    case filtreEnum.Client:
                        affiche = (row.NatureAdresse === filtreEnum.Client);
                        break;
                    case filtreEnum.Fournisseur:
                        affiche = (row.NatureAdresse === filtreEnum.Fournisseur);
                        break;
                    default:
                        break;
                }
                return affiche;
            })
        }
    }

    const openOrdersForAdress = (row) => {
        setSelectedRow(row);
        setOrdersForAddressVisible(true);
    }

    const refresh = () => {
        if (!client || parseInt(client) === 0) {
            return;
        }
        setSelectedRow({});
        logecomApi.get(apiRoot + 'clients/' + client + '/adresses?NatureAdresse=' + natureAdresse + '&Supprime=' + 0 + '&idBoutique=' + idBoutique).then((res) => {
            setAdresses(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }

    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })

    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    }
    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };
    const handleOnSelect = (row, isSelect) => {
        setAdresse(row);
        return true;
    };
    const selectRow = () => {
        if (modeSelection) {
            return {
                mode: 'radio',
                clickToSelect: true,
                hideSelectColumn: true,
                style: { backgroundColor: 'blue', color: 'white' },
                onSelect: handleOnSelect
            };
        }
    }
    const controlsFormatter = (cell, row, rowIndex, nomColonne) => {
        return (
            <div className="btn-group">
                <button type="button" title="Modifier" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => modifier(row)}>
                    <i className="ion ion-md-create d-block"></i>
                </button>
                <button type="button" title="Supprimer" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { setSelectedRow(row); setYesNoDelete(true) }}>
                    <i className="ion ion-md-trash d-block"></i>
                </button>
            </div>
        )
    }
    const modifier = (row) => {
        setSelectedRow(row);
        setAfficherDialogueAdresse(true);
    }
    const ajouter = (row) => {
        setSelectedRow();
        setAfficherDialogueAdresse(true);
    }
    const supprimer = (row) => {
        logecomApi.delete(apiRoot + 'addresses/' + row.IDCARNET_ADRESSE).then(() => {
            refresh();
        }).catch((error) => {
            alert(error.response.data.fault.detail)
        })
    }
    const titre = (row) => {
        if (!row) {
            return '';
        }
        var title = "Supprimer l'adresse : " + row.Societe;
        if (row.Societe && row.NomPrenom) {
            title += ' - ';
        }
        title += row.NomPrenom;
        return title;
    }
    const columns =
        [
            ["", "ICONS", 65, true, false],
            ["ID", "IDCARNET_ADRESSE", 180, false, false],
            ["Nature", "NatureAdresse", 85, true, true],
            ["Type", "TypeGestion", 65, true, true],
            ["Famille", "FamilleContact", 100, true, true],
            ["Société", "Societe", 160, true, true],
            ["Nom Prenom", "NomPrenom", 160, true, true],
            ["Adresse", "Adresse", 160, true, true],
            ["Complément Adresse", "ComplementAdresse", 180, false, true],
            ["Code Postal", "CodePostal", 60, false, true],
            ["Ville", "Ville", 130, true, true],
            ["Code Pays", "CodePays", 180, false, true],
            ["Pays", "Pays", 100, true, true],
            ["Téléphone", "Telephone", 130, false, true],
            ["Email", "Email", 140, true, true],
            ["Boutique", "NomBoutique", 120, true, true],
            ["Nbr Cde", "NbCommandes", 70, true, true],
            ["Créé Le", "CreeLe", 180, false, true],
            ["Créé Par", "CreePar", 180, false, true]
        ]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], visible: elt[3], exporter: elt[4], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;
                if (result.visible === false) {
                    result.hidden = true;
                } else if (['CreeLe'].includes(result.dataField)) {
                    result.sortFunc = dateSort;
                } else if (['Telephone', "Email", 'Pays'].includes(result.dataField)) {
                    result.hidden = modeSelection;
                } else if (result.dataField === 'NbCommandes') {
                    result.formatter = (cell, row) => {
                        if (cell === 0) {
                            return ('')
                        } else if (result.dataField === "NbCommandes") {
                            return (<Button variant="link" title='Liste des commandes pour cette adresse' onClick={() => openOrdersForAdress(row)}>{cell}</Button>)
                        } else {
                            return (cell)
                        }
                    };
                } else if (result.dataField === 'ICONS') {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = controlsFormatter;
                    result.sort = false;
                    result.filter = undefined;
                    result.text = '';
                    result.hidden = modeSelection;
                } else if (result.dataField === "NatureAdresse") {
                    var natures = [
                        { value: 1, label: 'Client' },
                        { value: 2, label: 'Fournisseur' },
                    ];
                    result.formatter = cell => natures.find(nature => nature.value === cell).label;
                    result.filter = selectFilter({
                        options: {
                            1: 'Client',
                            2: 'Fournisseur',
                        },
                        className: 'custom-select',
                        placeholder: 'Toutes'
                    })
                    result.hidden = modeSelection;
                } else if (result.dataField === "TypeGestion") {
                    var types = [
                        { value: 1, label: 'B2B' },
                        { value: 2, label: 'B2C' },
                    ];
                    result.formatter = (cell, row) => {
                        if (row.NatureAdresse === 2) {
                            return '';
                        } else {
                            return types.find(type => type.value === cell).label
                        }
                    };
                    result.filter = selectFilter({
                        options: {
                            1: 'B2B',
                            2: 'B2C',
                        },
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })
                }

                return result
            })

    return (
        <>
            <ToolkitProvider
                keyField="IDCARNET_ADRESSE"
                data={filteredData()}
                columns={columns}
                bootstrap4
                search
                columnToggle
            >
                {props => (
                    <div>
                        {<Row className="align-items-center" style={{ marginBottom: '20px' }}>
                            {!modeSelection && <Col><span className="titre-section font-weight-bold">Carnet d'adresses</span>
                            </Col>}
                            <Col md="auto">
                                {!modeSelection && <div className="header-controles">
                                    <button type="button" className="btn btn-md btn-primary" style={{ marginRight: '5px', height: '38px' }} onClick={ajouter}>
                                        <i className="ion ion-md-add-circle d-block"></i>
                                    </button>
                                    <Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'Adresses') }}>Export CSV</Button>
                                    <ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">

                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={filtre === filtreEnum.Tous}
                                            value="1"
                                            onChange={(e) => setFiltre(filtreEnum.Tous)}
                                        >
                                            Tous
                                        </ToggleButton>
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={filtre === filtreEnum.Client}
                                            value="1"
                                            onChange={(e) => setFiltre(filtreEnum.Client)}
                                        >
                                            Client
                                        </ToggleButton>
                                        <ToggleButton
                                            type="checkbox"
                                            variant="secondary"
                                            checked={filtre === filtreEnum.Fournisseur}
                                            value="1"
                                            onChange={(e) => setFiltre(filtreEnum.Fournisseur)}
                                        >
                                            Fournisseur
                                        </ToggleButton>
                                    </ButtonGroup>
                                </div>}
                            </Col>
                        </Row>}
                        <BootstrapTable
                            {...props.baseProps}
                            rowStyle={rowStyle}
                            wrapperClasses="table-responsive"
                            pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                            filter={myTableFilterFactory()}
                            selectRow={selectRow()}

                        />
                    </div>
                )}
            </ToolkitProvider>
            <YesNo show={yesNoDelete} hide={setYesNoDelete} callbackfunction={supprimer} object={selectedRow} title="Suppression" body={titre(selectedRow)}></YesNo>

            <AdresseEdit
                client={client}
                visible={afficherDialogueAdresse}
                fermer={() => { setAfficherDialogueAdresse(false); refresh(); }}
                adresseModif={selectedRow}
            />
            <OrdersForAddress
                isModalVisible={ordersForAddressVisible}
                client={client}
                adresse={selectedRow}
                fermer={() => setOrdersForAddressVisible(false)}
            />

        </>
    )
}
export default AdressesListTable;