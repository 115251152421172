import axios from "axios";
const apiRoot = process.env.REACT_APP_API_ROOT
let isRefreshing = false;

const logecomApi = axios.create();

const refreshAndRetryQueue = [];

//Pose un mécanisme qui ouvre toutes les requêtes vers l'API à l'exception du login et y ajoutent le header d'autorisation
logecomApi.interceptors.request.use(
    config => {
        var token = localStorage.getItem("token");
        if (config.url.endsWith('token/refresh') || config.url.endsWith('/logout')) {
            token = localStorage.getItem("refresh_token");
        }
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

const refresh = (config) => {
    return logecomApi.get(apiRoot + 'token/refresh')
        .then(res => {
            // Dans le cas où la requête retourne le code succès
            if (res.status === 200) {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("refresh_token", res.data.refresh_token);
                localStorage.setItem("last_refresh", Date.now())
                if (config) {
                    const page = localStorage.getItem("page")
                    // Rafraîchit la page en cours. Il serait préférable de faire une réémission.
                    window.location = "/" + page;
                }
                return res.data.token;
            }
        }).catch((error) => {
            //TODO lien externe
            /*if(!localStorage.getItem("Url_Redirection")){
                localStorage.setItem("Url_Redirection", window.location.href)
            }*/
            window.location = "/login";
        })
}

// Rejeu des requête après refresh : Source https://github.com/SinaMAlizadeh/axios-interceptors
logecomApi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    // Refresh the access token
                    const newAccessToken = await refresh();

                    // Update the request headers with the new access token
                    error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    // Retry all requests in the queue with the new token

                    refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                        logecomApi
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((err) => reject(err));
                    });

                    // Clear the queue
                    refreshAndRetryQueue.length = 0;

                    // Retry the original request
                    return logecomApi(originalRequest);
                } catch (refreshError) {
                    // Handle token refresh error
                    // You can clear all storage and redirect the user to the login page
                    throw refreshError;
                } finally {
                    isRefreshing = false;
                }
            } else if (error.config.url.endsWith('token/refresh') || error.config.url.endsWith('/logout')) {
                window.location = "/login";
            }

            // Add the original request to the queue
            return new Promise((resolve, reject) => {
                refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
            });
        }

        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error);
    }
);

export default logecomApi;