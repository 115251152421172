import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import Select from 'react-select'
import ReceptionsByLot from '../Reception/ReceptionsByLot';


const apiRoot = process.env.REACT_APP_API_ROOT


const LotEdit = ({ visible, fermer, lotId, afficherCoutAcquisition }) => {
    const [lot, setLot] = useState({});
    const statuts = [
        { value: 1, label: 'Bloqué' },
        { value: 2, label: 'Expiré', isDisabled: true },
        { value: 4, label: 'Épuisé', isDisabled: true },
        { value: 3, label: 'A détruire' },
        { value: 10, label: 'A expédier sans priorité' },
        { value: 20, label: 'A expédier priorité 1' },
        { value: 19, label: 'A expédier priorité 2' },
        { value: 18, label: 'A expédier priorité 3' },
        { value: 17, label: 'A expédier priorité 4' },
        { value: 16, label: 'A expédier priorité 5' },
        { value: 15, label: 'A expédier priorité 6' },
        { value: 14, label: 'A expédier priorité 7' },
        { value: 13, label: 'A expédier priorité 8' },
        { value: 12, label: 'A expédier priorité 9' },
        { value: 11, label: 'A expédier priorité 10' }
    ];
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [receptionsByLot, setReceptionsByLotVisible] = useState(null);
    const saisieAssuranceClient = (parseInt(localStorage.getItem("assurance_client")) === 1);


    const sauver = () => {
        if (actionWsEnCours) {
            return;
        }
        setActionWsEnCoursEnCours(true);

        var row = {
            Statut: lot.Statut,
            Commentaire: lot.Commentaire,
            CoutAcquisition: lot.CoutAcquisition
        }

        logecomApi.put(apiRoot + 'lots/' + lot.IDLOT, row).then(() => {
            setActionWsEnCoursEnCours(false);
            fermer();
        }).catch((error) => {
            setActionWsEnCoursEnCours(false);
            alert(error.response.data.fault.detail)
        })
    };

    useEffect(() => {
        if (!lotId || lotId < 1) {
            return;
        }
        logecomApi.get(apiRoot + 'lots/' + lotId).then((res) => {
            setLot(res.data[0])
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }, [lotId]
    );

    const editRow = (key, value) => {
        let temp = JSON.parse(JSON.stringify(lot))
        temp[key] = value
        setLot(temp)
    };


    return (
        <div>
            <Modal show={visible} backdrop="static" onHide={fermer} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Fiche Lot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Numéro de lot :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                {lot.NumeroDeLot}
                            </Col>
                            <Col sm={3}>
                                <strong>Statut :&nbsp; </strong>
                            </Col>
                            <Col sm={3}>
                                {[2, 4].includes(lot.Statut) ?
                                    <span>
                                        {lot.Statut === 2 ? 'Expiré' : 'Épuisé'}
                                    </span>

                                    :

                                    <Select
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                        options={statuts}
                                        value={statuts.find(statut => parseInt(statut.value) === parseInt(lot.Statut))}
                                        onChange={e => editRow('Statut', e.value)}
                                        isSearchable={false}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Date d'expiration :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                {lot.DateExpiration}
                            </Col>
                            <Col sm={3}>
                                <strong>Date de durabilité minimale :&nbsp; </strong>
                            </Col>
                            <Col sm={3}>
                                {lot.DateDurabiliteMinimale}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label>
                                    <strong>Quantité en stock :</strong>
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                {lot.QuantiteEnStock}
                            </Col>
                            {lot.Restriction === 0 && saisieAssuranceClient &&
                                <>
                                    <Col sm={2}>
                                        <Form.Label>
                                            <strong>Prix de valorisation :</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Control type="number" min="1" step="any" value={lot.CoutAcquisition} onChange={e => editRow("CoutAcquisition", e.target.value)} />
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row className="mb-2">
                            <Col sm={3}>
                                <Form.Label>
                                    <strong>Commentaire :</strong>
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Form.Control as="textarea" rows="3" maxLength="4000" value={lot.Commentaire} onChange={e => editRow("Commentaire", e.target.value)} />
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                <strong>Code SKU :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                {lot.CodeSKU}
                            </Col>
                            <Col sm={2}>
                                <strong>Libellé :&nbsp; </strong>
                            </Col>
                            <Col sm={4}>
                                {lot.LibelleArticle1}
                            </Col>
                        </Row>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="blue" onClick={() => { setReceptionsByLotVisible(true) }}>Réceptions</Button>
                    <Button disabled={false} variant="default" className="blue" onClick={sauver}>Sauver</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>

            <ReceptionsByLot
                isModalVisible={receptionsByLot}
                fermer={() => setReceptionsByLotVisible(false)}
                client={lot.IDCLIENT}
                lotId={lot.IDLOT}
                titre={' du lot numéro: ' + lot.NumeroDeLot}
            />
        </div >
    );
}
export default LotEdit;