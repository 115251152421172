import React from 'react';
import { Helmet } from 'react-helmet';
import TarifConsommablesTable from './TarifConsommablesTable';

const TarifConsommablesPage = (props) => {
    const client = props.client;
    return (
        <React.Fragment>
            <Helmet><title>LOGeCOM - Tarification consommables</title>

            </Helmet>
            <div className="layout-wrapper layout-1 layout-without-sidenav">
                <div className="layout-inner">

                    <div className="layout-container">
                        <div className="layout-content">

                            <div className="container flex-grow-1 mycontainer-p-y unbox">
                                <div style={{ marginBottom: '40px' }}>
                                    <TarifConsommablesTable client={client} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default TarifConsommablesPage;