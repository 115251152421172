import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import logecomApi from "../../utils/axiosInstance";
import DocumentList from '../Document/DocumentList';
import Select from 'react-select'
import Alerte from '../Alerte';
import DiscussionModal from '../Discussion/DiscussionModal';
import SavModal from '../Sav/SavModal';
import OrderWatchHeader from './OrderWatchHeader';
import { getPdfCoutCommande } from '../../utils/utils';
const apiRoot = process.env.REACT_APP_API_ROOT

const CommandeEditMini = ({ fermer, orderId, tagsOrder }) => {
    const ouiNonOptions = [{ label: "Oui", value: true }, { label: "Non", value: false }];
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [discussionVisible, setDiscussionVisible] = useState(false);
    const [ouvrirDocuments, setOuvrirDocuments] = useState(false);
    const [order, setOrder] = useState({});
    const [savVisible, setSavVisible] = useState(false);
    const [alerte, setAlerte] = useState(null);

    const ouvrirDiscussion = () => {
        setDiscussionVisible(true);
    };

    const modifyOrder = (key, value) => {
        let tempOrder = JSON.parse(JSON.stringify(order));
        tempOrder[key] = value;
        setOrder(tempOrder);
    }

    useEffect(() => {
        if (!orderId) {
            return;
        }
        logecomApi.get(apiRoot + 'orders/' + orderId).then((res) => {
            let data = res.data[0];
            setOrder(data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }, [orderId]);

    const valider = () => {
        if (actionWsEnCours) {
            return;
        }
        setActionWsEnCoursEnCours(true);
        const row = {
            Entete: {
                TraiterEnUrgence: order.TraiterEnUrgence,
                IDTAG_COMMANDE: order.IDTAG_COMMANDE
            }
        }

        logecomApi.put(apiRoot + 'orders/' + orderId + '/entete', row).then(() => {
            setActionWsEnCoursEnCours(false);
            fermer(true);
        }).catch((error) => {
            setActionWsEnCoursEnCours(false);
            setAlerte(error.response.data.fault.detail)
        })
    }

    return (
        <>
            <Modal show={orderId && orderId > 0} backdrop="static" onHide={fermer} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Fiche commande <br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrderWatchHeader
                        order={order}
                        orderId={orderId}
                        modeModif={true}
                    />
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label >
                                    TAG :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.TAG}
                                    getOptionValue={option => option.IDTAG_COMMANDE}
                                    options={tagsOrder}
                                    value={tagsOrder.find(element => element.IDTAG_COMMANDE === order.IDTAG_COMMANDE)}
                                    onChange={e => modifyOrder("IDTAG_COMMANDE", e.IDTAG_COMMANDE)}
                                    isSearchable={false}
                                />
                            </Col>
                            <Col sm={2}>
                                <Form.Label >
                                    Traiter en urgence :
                                </Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value}
                                    options={ouiNonOptions}
                                    isDisabled={(!order.TraiterEnUrgencePossible && !order.TraiterEnUrgence) || orderId === 0}
                                    value={ouiNonOptions.find(element => element.value === order.TraiterEnUrgence)}
                                    onChange={e => modifyOrder("TraiterEnUrgence", e.value)}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="mb-2">
                                <label style={{ fontStyle: 'italic' }}>
                                    Compte tenu du statut de cette commande, seuls les éléments ci-dessus sont modifiables&nbsp;
                                </label>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {order.StatutCommande === 'Soldée' && <Button variant="default" className="blue" onClick={() => { getPdfCoutCommande(order.IDCOMMANDE, order.NumCommandeClient, order.DateCommande) }}>Coût</Button>}
                    {order.StatutCommande === 'Soldée' && <Button variant="default" className="blue" onClick={() => { setSavVisible(true) }}>SAV</Button>}
                    <Button variant="default" className="blue" onClick={ouvrirDiscussion}>Discussions</Button>
                    <Button variant="default" className="blue" onClick={() => { setOuvrirDocuments(true) }}>Documents</Button>
                    <Button variant="default" disabled={actionWsEnCours} className="blue" onClick={() => { valider() }}>Valider</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal>

            <DiscussionModal
                isModalVisible={discussionVisible}
                elementRattache='Order'
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                fermerDiscussion={() => setDiscussionVisible(false)}
            />
            <DocumentList
                isModalVisible={ouvrirDocuments}
                elementRattache='Order'
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                handleDocumentList={() => setOuvrirDocuments(false)}
            />
            <SavModal
                isModalVisible={savVisible}
                idElementRattache={order.IDCOMMANDE}
                client={order.IDCLIENT}
                titre={'Commande ' + order.NumCommandeClient + ' du ' + order.DateCommande}
                fermerSav={() => setSavVisible(false)}
            />

            <Alerte
                isModalVisible={alerte}
                handleAlerte={() => setAlerte(null)}
                message={alerte}
            />

        </>
    )
}


export default CommandeEditMini; 